import React, { useState } from "react";
import './WatchListProfilingTabMenu.css'

const WatchListProfilingTabMenu = (props) => {
    const {
        menuItemsList=[],
        activeMenu="",
        handleMenuClick = () => {}
    } = props


    return (
        <div className="wl-profiling-menu-list-container">
            {menuItemsList?.map((item, index) => {
                return (
                    <button
                        className={`wlp-menu-button px-3 ${activeMenu === item.menuText ? 'wlp-active-menu' : ''}`}
                        key={`wlp_menu_${index}`}
                        onClick={() => handleMenuClick(item)}>{item.menuText}
                    </button>
                )
            })}
        </div>
    )
}

export default WatchListProfilingTabMenu