import {
    ADD_COMPANY_TO_WATCHLIST_FAILED, COMPANY_WATCHLIST_INFO_SUCCESS, CREATE_WATCHLIST_FAILED, DELETE_WATCHLIST_FAILED, UPLOAD_CSV_TO_WATCHLIST_SUCCESS, UPLOAD_CSV_TO_WATCHLIST_FAILED,
    GET_UPDATED_WATCHLIST_AFTER_SUCCESS, GET_WATCHLIST_FAILED, REMOVE_COMPANY_FROM_WATCHLIST_FAILED, REMOVE_COMPANY_FROM_WATCHLIST_SUCCESS, TOGGLE_ADD_EDIT_CP_WATCHLIST_MODAL, TOGGLE_ADD_TO_WATCHLIST_MODAL, TOGGLE_CREATE_WATCHLIST_MODAL, UPDATE_WATCHLIST_FAILED,
    TOGGLE_UPLOAD_CSV_TO_WATCHLIST_MODAL,
    TOGGLE_WATCHLIST_PROFILING_MODAL,
    GET_WATCHLIST_PROFILING_FAILED,
    GET_WATCHLIST_PROFILING_SUCCESS
} from "../actions/actionConstants"

const INITIAL_STATE = {
    watchListProfilingModalStatus: {status: false, watchListName: "", watchListId: "", watchListDescription: ""}
}

const watchListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_WATCHLIST_FAILED:
            return { ...state, errorMessage: action.payload.errorMessage }
        case UPDATE_WATCHLIST_FAILED:
            return { ...state, message: action.payload.errorMessage }
        case DELETE_WATCHLIST_FAILED:
            return { ...state, errorMessage: action.payload.errorMessage }
        case GET_UPDATED_WATCHLIST_AFTER_SUCCESS:
            return { ...state, companyWatchList: action.payload.watchList }
        case GET_WATCHLIST_FAILED:
            return { ...state, message: action.payload.message }
        case ADD_COMPANY_TO_WATCHLIST_FAILED:
            return { ...state, errorMessage: action.payload.errorMessage }
        case REMOVE_COMPANY_FROM_WATCHLIST_SUCCESS:
            return { ...state, message: action.payload.message }
        case REMOVE_COMPANY_FROM_WATCHLIST_FAILED:
            return {...state, errorMessage: action.payload.errorMessage}
        case TOGGLE_CREATE_WATCHLIST_MODAL:
            return { ...state, toggleCreateWatchListModal: action.payload.toggleStatus };
        case TOGGLE_ADD_TO_WATCHLIST_MODAL:
            return { ...state, toggleAddToWatchListModal: action.payload.toggleStatus }
        case COMPANY_WATCHLIST_INFO_SUCCESS:
            return { ...state, companyWatchListInfo: action.payload.watchlist }
        case TOGGLE_ADD_EDIT_CP_WATCHLIST_MODAL:
            return { ...state, toggleAddEditModal: action.payload.modalStatus }
        case UPLOAD_CSV_TO_WATCHLIST_SUCCESS:
            return {...state, message: action.payload.message}
        case UPLOAD_CSV_TO_WATCHLIST_FAILED:
            return {...state, errorMessage: action.payload.errorMessage}  
        case TOGGLE_UPLOAD_CSV_TO_WATCHLIST_MODAL: 
            return {...state, toggleUploadCsvToWatchlistModal: action.payload.toggleStatus}   
        case TOGGLE_WATCHLIST_PROFILING_MODAL: 
            return {...state, watchListProfilingModalStatus: action.payload.toggleStatus}  
        case GET_WATCHLIST_PROFILING_SUCCESS: 
            return {...state, watchListProfilingObj: action.payload.watchListProfilingObj}
        case GET_WATCHLIST_PROFILING_FAILED:
            return {...state, errorMessage: action.payload.errorMessage}
        default:
            return state;
    }
}

export default watchListReducer;