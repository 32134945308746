import React, { Component } from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { withRouter } from 'react-router';
import loadable from '@loadable/component'
import analyticsApi from '../../api/analyticsApi';
import { GA_PS_BTN_TAG } from "../../common/config";
import { EP_INDUSTRY_PROFILE, ET_SEEMORE_MODAL_CLOSE_BUTTON_CLICK } from '../../common/trackingConstants';
import { sendActivityTracking, setHeaderSearchText, setHeaderSingleSearchOptions, toggleSeemoreModal } from '../../redux/actions/commonAction';
import { getIndustryProfileSearchResult } from '../../redux/actions/industryProfileAction';
import {
    callSingleSearchApiAction, setActivityTrackingData,
    setChargesSearchAppliedFilter, resetAllSearchParams,
    setCompanySearchAppliedFilter, setDirectorSearchAppliedFilter,
    setIndustrySearchAppliedFilter, setPowerSearchType,
    setShareholderSearchAppliedFilter, setRegionSearchAppliedFilter, setCompanySearchParams
} from '../../redux/actions/searchAction';
import { getOnloadEventType } from '../common/PowerSearchFunctions';
const SingleSearchDropdown = loadable(() => import('../HomePage/HomepageComponents'));
import SingleSearchModalBar from '../SingleSearchModalBar/SingleSearchModalBar';
import './SingleSearchBar.css';
import { getDefaultFiltersForCompany, getDefaultFilterWithSearch, getSearchQueryDataObj } from '../../common/SearchCommonFunctions';

export const getSearchFilterByValue = (value) => {
    if (value) {
        return {
            company: {
                "Company Name": {
                    "name": "Company Name",
                    "valueType": "linkedFilter",
                    "fields": {
                        "companyName": {
                            "value": `${value}`,
                            "type": "text",
                            "fieldNames": [
                                "CompanyName"
                            ],
                            "labels": [],
                            "shareUrlKey": "d"
                        }
                    },
                    "type": "text",
                    "filterKey": "companyName",
                    "trackingFilterKey": "company_name"
                }
            },
            director: {
                'Director Name': {
                    "name": 'Director Name',
                    "valueType": "linkedFilter",
                    "fields": {
                        "directorName": {
                            "value": `${value}`,
                            "type": 'text',
                            "fieldNames": [
                                'LastName'
                            ],
                            "labels": [],
                            "shareUrlKey": '4'
                        }
                    },
                    "type": 'text',
                    "filterKey": 'directorName',
                    "trackingFilterKey": 'director_name'
                }
            },
            shareholder: {
                'Shareholder Name': {
                    name: 'Shareholder Name',
                    valueType: "linkedFilter",
                    fields: {
                        shareholderName: {
                            value: `${value}`,
                            type: 'text',
                            fieldNames: [
                                'FullName'
                            ],
                            labels: [],
                            shareUrlKey: '7'
                        }
                    },
                    type: 'text',
                    filterKey: 'shareholderName',
                    trackingFilterKey: 'shareholder_name'
                }
            },
            charges: {
                'Company Name': {
                    name: 'Company Name',
                    valueType: null,
                    fields: {
                        companyName: {
                            value: `${value}`,
                            type: 'text',
                            fieldNames: [
                                'CompanyName'
                            ],
                            labels: [],
                            shareUrlKey: '4'
                        }
                    },
                    type: 'text',
                    filterKey: 'companyName',
                    trackingFilterKey: 'company_name'
                }
            },
            industry: {
                'Key words': {
                    name: 'Key words',
                    valueType: null,
                    fields: {
                        industryKeywords: {
                            value: `${value}`,
                            type: 'text',
                            fieldNames: [
                                'DivisionDesc',
                                'AltDivisionDesc',
                                'DivisionLongDesc',
                                'Tag1',
                                'Tag2',
                                'Tag3',
                                'Tag4',
                                'Tag5',
                                'Tag6'
                            ],
                            labels: [],
                            shareUrlKey: '1'
                        }
                    },
                    type: 'text',
                    filterKey: 'industryKeywords',
                    trackingFilterKey: 'key_words'
                }
            },
            region: {
                'Key words': {
                    name: 'Key words',
                    valueType: null,
                    fields: {
                        regionKeywords: {
                            value: `${value}`,
                            type: 'text',
                            fieldNames: [
                                'Region',
                                'AltRegionDescription',
                                'Tag1',
                                'Tag2',
                                'Tag3',
                                'Tag4',
                                'Tag5',
                                'Tag6',
                                'Tag7',
                                'Tag8',
                                'Tag9',
                                'Tag10'
                            ],
                            labels: [],
                            shareUrlKey: '1'
                        }
                    },
                    type: 'text',
                    filterKey: 'regionKeywords',
                    trackingFilterKey: 'key_words'
                }
            }
        }
    } else {
        return {}
    }

}

class SingleSearchBar extends Component {
    constructor() {
        super();
        this.state = {
            isModalOpen: false,
            modalInputId: "",
            modalPlaceholder: "",
            modalEventCreated: "",
            modalEventType: "",
            modalOptionEventCreated: "",
            isIndustryModelOpen: false,
            industryModelInputId: "",
            renderedSearchtext: null,
            typingTimeout: 0,
            minWidth: 992,
            maxWidth: 991.98
        }
    }

    componentDidMount() {
        const { mediaEqualVersionType } = this.props
        if (mediaEqualVersionType === "desktop_tablet") {
            this.setState({ minWidth: 576, maxWidth: 575.98 })
        }
    }

    componentDidUpdate() {
        this.props &&
            this.state.renderedSearchtext !== this.props.searchTextboxValue &&
            this.setState({ renderedSearchtext: this.props.searchTextboxValue })
    }

    componentWillUnmount() {
        if(this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
            this.setState({typingTimeout: 0})
        }
    }

    onKeyPressed = (e, eventCreatedKeyDown, eventTypeKeyDown) => {
        let {
            powerSearchType = "",
            searchTextboxValue = ""
        } = this.props;
        powerSearchType = powerSearchType === "" ? "company" : powerSearchType;
        if (e.keyCode === 13) {
            searchTextboxValue !== "" && this.props.dispatchSetHeaderSearchText(searchTextboxValue);
            this.handleSearchIconClick(eventCreatedKeyDown, eventTypeKeyDown)
        }
    }

    goToPowerSearchPage = (searchType, eventCreated, eventType) => {
        this.props.setActivityTrackingData({ obj: { "eventCreated": eventCreated, "eventType": eventType }, type: "powerSearch" })
        this.props.dispatchSetPowerSearchType(searchType);
        this.props.history.push(`/powersearch/${searchType}`);
        analyticsApi.gtag_report_conversion(GA_PS_BTN_TAG)
    }

    handleChangeSearch = (event, eventType, eventCreated, eventPage) => {
        const {
            setSearchTextboxValue = () => { },
            inputId = "",
            formId = "",
            applyLiveDormantFilter = ""
        } = this.props;
        let rtBodyElement = document.getElementsByClassName("rt-tbody")[0]
        rtBodyElement && (rtBodyElement.scrollTop = 0)
        setSearchTextboxValue(event.target.value);
        let allAppliedFilters = getSearchFilterByValue(event.target.value)
        if (event.target.value) {
            allAppliedFilters["company"] = { ...allAppliedFilters["company"], ...getDefaultFiltersForCompany() }
        }
        applyLiveDormantFilter ? allAppliedFilters['company'] = getDefaultFilterWithSearch(event.target.value).company : "";
        this.setPowerSearchFilters(allAppliedFilters);

        this.callSearchApi(event.target, {
            eventPage,
            eventType,
            eventCreated,
            searchText: event.target.value
        }, allAppliedFilters);

        let menuElement = document.getElementById(`${inputId}_menu`);
        let inputElement = document.getElementById(inputId);
        let dropdownElement = document.getElementById(formId);
        menuElement && menuElement.classList.add("show");
        dropdownElement && dropdownElement.classList.add("show");
        inputElement.setAttribute("aria-expanded", "true");
    }

    resetSearchParams = (searchParam) => {
        this.props.dispatchResetAllSearchParams(searchParam);
    }

    setPowerSearchFilters = (filterObj) => {
        const {
            company = {},
            director = {},
            industry = {},
            shareholder = {},
            charges = {},
            region = {}
        } = filterObj;
        this.props.dispatchCompanySearchAppliedFilter(company);
        this.props.dispatchDirectorSearchAppliedFilter(director);
        this.props.dispatchIndustrySearchAppliedFilter(industry);
        this.props.dispatchShareholderSearchAppliedFilter(shareholder);
        this.props.dispatchChargesSearchAppliedFilter(charges);
        this.props.dispatchRegionSearchAppliedFilter(region);
    }

    callSearchApi = (target, trackingObj, allAppliedFilters, selectedSearchTypes = []) => {
        const {
            headerSingleSearchOptions = {},
            type = "",
            singleSearchTypes = [],
            companyQueryData = {},
            directorQueryData = {},
            industryQueryData = {},
            shareholderQueryData = {},
            chargesQueryData = {},
            regionQueryData = {},
            isEstimateOn = false
        } = this.props;
        const {
            filters = false
        } = headerSingleSearchOptions;
        const scoreBasedSort = "score";
        const windowHeight = window ? window.outerHeight > window.innerHeight ? window.outerHeight : window.innerHeight : 0;
        const tableRowCount = Math.round((windowHeight - 160) / 25)
        let size = tableRowCount < 25 ? 25 : tableRowCount;
        const renderedSearchTypes = selectedSearchTypes?.length > 0 ? selectedSearchTypes : singleSearchTypes
        let newCompanyQueryData = JSON.parse(JSON.stringify(companyQueryData));
        target.value !== "" ? (newCompanyQueryData.sortField = scoreBasedSort) : (newCompanyQueryData.sortField = "companyName");
        this.props.dispatchSetCompanySearchParams(newCompanyQueryData)
        switch (type) {
            case "headerSearchbar":
                this.props.dispatchSetHeaderSearchText(target.value);
                if (this.state.typingTimeout) {
                    clearTimeout(this.state.typingTimeout);
                }
                this.setState({
                    typingTimeout: setTimeout(() => {
                        this.props.dispatchCallSingleSearchApi({
                            searchText: target.value,
                            from: 0,
                            size,
                            company: getSearchQueryDataObj(newCompanyQueryData, allAppliedFilters, "company"),
                            director: getSearchQueryDataObj(directorQueryData, allAppliedFilters, "director"),
                            industry: getSearchQueryDataObj(industryQueryData, allAppliedFilters, "industry"),
                            shareholder: getSearchQueryDataObj(shareholderQueryData, allAppliedFilters, "shareholder"),
                            charges: getSearchQueryDataObj(chargesQueryData, allAppliedFilters, "charges"),
                            region: getSearchQueryDataObj(regionQueryData, allAppliedFilters, "region"),
                            isEstimateOn,
                            trackingObj,
                            isFilters: filters,
                            searchTypes: renderedSearchTypes
                        });

                    }, 1000)
                });

                this.resetSearchParams({
                    searchText: target.value,
                });
                let headerQueryData = {};
                headerQueryData.scrollPosition = { scroll: 0, scrollRetain: false };
                this.resetSearchParams(headerQueryData);
                break;
            default: {
                this.props.dispatchSetHeaderSearchText("");
                if (this.state.typingTimeout) {
                    clearTimeout(this.state.typingTimeout)
                }
                this.setState({
                    typingTimeout: setTimeout(() => {
                        this.props.dispatchCallSingleSearchApi({
                            searchText: target.value,
                            from: 0,
                            size,
                            company: getSearchQueryDataObj(companyQueryData, allAppliedFilters, "company"),
                            director: getSearchQueryDataObj(directorQueryData, allAppliedFilters, "director"),
                            industry: getSearchQueryDataObj(industryQueryData, allAppliedFilters, "industry"),
                            shareholder: getSearchQueryDataObj(shareholderQueryData, allAppliedFilters, "shareholder"),
                            charges: getSearchQueryDataObj(chargesQueryData, allAppliedFilters, "charges"),
                            region: getSearchQueryDataObj(regionQueryData, allAppliedFilters, "region"),
                            trackingObj,
                            searchTypes: renderedSearchTypes,
                            isFilters: false
                        });
                    }, 1000)
                })

                let searchQueryData = {};
                searchQueryData.scrollPosition = { scroll: 0, scrollRetain: false };
                searchQueryData.size = size;
                searchQueryData.searchText = target.value;
                this.resetSearchParams(searchQueryData);
                break;
            }
        }
    }

    toggleSearchModal = (status, inputId, placeholder, eventCreated, eventType, eventCreatedOption) => {
        document.getElementById(inputId) && document.getElementById(inputId).blur();
        this.setState({
            isModalOpen: status,
            modalInputId: `${inputId}_modal`,
            modalPlaceholder: placeholder,
            modalEventCreated: eventCreated,
            modalEventType: eventType,
            modalOptionEventCreated: eventCreatedOption
        }, () => {
            setTimeout(() => {
                document.getElementById(`${inputId}_modal`) && document.getElementById(`${inputId}_modal`).focus();
            }, 1);
        })
    }

    toggleIndustrySearchModal = (_status, inputId, eventCreated, eventType, eventCreatedOption) => {
        document.getElementById(inputId) && document.getElementById(inputId).blur();
        this.props.dispatchGetIndustryProfileSearchResult("");
        this.props.dispatchSeeMoreIndustryModel({
            toggleStatus: true,
            searchEntity: "industry",
            heading: "Search Industries",
            inputPlaceHolder: "Search for Industry",
            inputId: `${inputId}_modal`,
            eventPage: this.props.eventPage
        })
        this.setState({
            industryModelInputId: `${inputId}_modal`,
            modalEventCreated: eventCreated,
            modalEventType: eventType,
            modalOptionEventCreated: eventCreatedOption
        }, () => {
            setTimeout(() => {
                document.getElementById(`${inputId}_modal`) && document.getElementById(`${inputId}_modal`).focus();
            }, 1);
        })
    }

    handleCloseButtonClick = () => {
        this.props.dispatchSendActivityTracking({
            eventPage: EP_INDUSTRY_PROFILE,
            eventType: ET_SEEMORE_MODAL_CLOSE_BUTTON_CLICK,
        })
        this.setState({ isIndustryModelOpen: false })
    }

    handleToggleIndustryProfileModal = (trackingObj, url) => {
        this.props.dispatchGetIndustryProfileSearchResult("");
        url && this.props.history.push(url);
        this.props.dispatchSeeMoreIndustryModel(false);
        this.props.dispatchSendActivityTracking({
            eventPage: EP_INDUSTRY_PROFILE,
            eventType: trackingObj.eventType,
            attribute1: trackingObj.attribute1,
            attribute2: url ? url : ""
        });
    }

    getSearchQueryData = (searchType) => {
        const {
            powerSearchType = "",
            companyQueryData = {},
            directorQueryData = {},
            industryQueryData = {},
            shareholderQueryData = {},
            chargesQueryData = {},
            regionQueryData = {}
        } = this.props;
        const type = searchType && searchType !== "" ? searchType : powerSearchType;
        switch (type) {
            case "company":
                return JSON.parse(JSON.stringify(companyQueryData))
            case "director":
                return JSON.parse(JSON.stringify(directorQueryData))
            case "industry":
                return JSON.parse(JSON.stringify(industryQueryData))
            case "shareholder":
                return JSON.parse(JSON.stringify(shareholderQueryData))
            case "charges":
                return JSON.parse(JSON.stringify(chargesQueryData))
            case "region":
                return JSON.parse(JSON.stringify(regionQueryData))
            default:
                return {}
        }
    }

    handleSearchIconClick = (eventCreatedKeyDown, eventTypeKeyDown) => {
        let {
            powerSearchType = ""
        } = this.props;
        const {
            eventCreatedIconClick = "",
            searchTextboxValue = "",
            companyQueryData = {},
            directorQueryData = {},
            industryQueryData = {},
            shareholderQueryData = {},
            chargesQueryData = {},
            regionQueryData = {},
            headerSingleSearchOptions = {},
            companyAppliedFilterList = {}
        } = this.props;
        this.props.dispatchSetHeaderSingleSearchOptions({ ...headerSingleSearchOptions, filters: true })
        let {
            modalEventCreated, modalEventType
        } = this.state
        const {
            tryPowerSearch = true
        } = headerSingleSearchOptions;

        powerSearchType = powerSearchType === "" ? "company" : powerSearchType;
        const queryData = this.getSearchQueryData(powerSearchType);
        const eventType = getOnloadEventType(powerSearchType);
        const eventCreated = eventCreatedKeyDown ? eventCreatedKeyDown : modalEventCreated ? modalEventCreated : eventCreatedIconClick
        analyticsApi.gtag_report_conversion(GA_PS_BTN_TAG)
        this.goToPowerSearchPage(powerSearchType, eventCreated,
            eventTypeKeyDown ? eventTypeKeyDown : modalEventType ? modalEventType : eventType);
        const size = Math.round((window.outerHeight - 160) / 25) < 25 ? 25 : Math.round((window.outerHeight - 160) / 25);

        if (searchTextboxValue) {
            const allAppliedFilters = getSearchFilterByValue(searchTextboxValue)
            allAppliedFilters["company"] = { ...allAppliedFilters["company"], ...getDefaultFiltersForCompany() }

            this.props.dispatchSetHeaderSearchText(searchTextboxValue);
            this.resetSearchParams({
                searchText: searchTextboxValue,
            });
            this.setPowerSearchFilters(allAppliedFilters);
        } else {
            const allAppliedFilters = {}
            allAppliedFilters['company'] = getDefaultFiltersForCompany()
            const singleSearchTypes = []
            tryPowerSearch ?
                singleSearchTypes.push("tryPowerSearch", "company", "director", "industry", "shareholder", "charges", "region") :
                singleSearchTypes.push("company", "director", "industry", "shareholder", "charges", "region")
            setTimeout(() => {
                if ((this.state.renderedSearchtext !== searchTextboxValue) || (searchTextboxValue === "" && queryData.size <= size) ||
                    ((companyAppliedFilterList['company status'] === undefined || !queryData.size) && companyQueryData.searchText === "") ||
                    (companyQueryData.searchText === undefined && directorQueryData.searchText === undefined && industryQueryData.searchText === undefined
                        && shareholderQueryData.searchText === undefined && chargesQueryData.searchText === undefined && regionQueryData.searchText === undefined)) {
                    this.callSearchApi({ value: "" }, {}, allAppliedFilters, singleSearchTypes);
                    this.props.dispatchSetHeaderSearchText("");
                    this.setPowerSearchFilters(allAppliedFilters);
                }
            }, 1);
        }
        this.setState({
            isModalOpen: false,
            modalInputId: "",
            modalPlaceholder: "",
            modalEventCreated: "",
            modalEventType: "",
            modalOptionEventCreated: ""
        });

    }

    getAppliedFilterList = (searchType) => {
        const {
            companyAppliedFilterList = {},
            directorAppliedFilterList = {},
            industryAppliedFilterList = {},
            shareholderAppliedFilterList = {},
            chargesAppliedFilterList = {},
            regionAppliedFilterList = {},
            powerSearchType = "",
        } = this.props;
        const newSearchType = searchType ? searchType :
            powerSearchType !== "" ? powerSearchType : "company";
        switch (newSearchType) {
            case "company":
                return JSON.parse(JSON.stringify(companyAppliedFilterList))
            case "director":
                return JSON.parse(JSON.stringify(directorAppliedFilterList))
            case "industry":
                return JSON.parse(JSON.stringify(industryAppliedFilterList))
            case "shareholder":
                return JSON.parse(JSON.stringify(shareholderAppliedFilterList))
            case "charges":
                return JSON.parse(JSON.stringify(chargesAppliedFilterList))
            case "region":
                return JSON.parse(JSON.stringify(regionAppliedFilterList))
            case "all":
                return {
                    company: JSON.parse(JSON.stringify(companyAppliedFilterList)),
                    director: JSON.parse(JSON.stringify(directorAppliedFilterList)),
                    industry: JSON.parse(JSON.stringify(industryAppliedFilterList)),
                    shareholder: JSON.parse(JSON.stringify(shareholderAppliedFilterList)),
                    charges: JSON.parse(JSON.stringify(chargesAppliedFilterList)),
                    region: JSON.parse(JSON.stringify(regionAppliedFilterList))
                }
            default:
                return {}
        }
    }

    getSelectedAppliedFilterFlagList = (appliedFilterObj = {}, searchTextboxValue) => {
        const appliedFilterObjKeys = Object.keys(appliedFilterObj);
        const flagList = appliedFilterObjKeys.filter((filterKey) => {
            let filterValue = "";
            switch (filterKey) {
                case "company":
                case "charges":
                    filterValue = appliedFilterObj?.[filterKey]?.[`Company Name`]?.fields?.companyName?.value;
                    break;
                case "director":
                    filterValue = appliedFilterObj?.[filterKey]?.[`Director Name`]?.fields?.directorName?.value;
                    break;
                case "industry":
                    filterValue = appliedFilterObj?.[filterKey]?.[`Key words`]?.fields?.industryKeywords?.value;
                    break;
                case "shareholder":
                    filterValue = appliedFilterObj?.[filterKey]?.[`Shareholder Name`]?.fields?.shareholderName?.value;
                    break;
                case "region":
                    filterValue = appliedFilterObj?.[filterKey]?.[`Key words`]?.fields?.regionKeywords?.value;
                    break;
            }
            return filterValue !== searchTextboxValue;
        })
        return flagList;
    }

    handleSearchbarInputOnFocus = (trackingObj, searchTextboxValue) => {
        const {
            companyAppliedFilterList = {},
            applyLiveDormantFilter = ""
        } = this.props
        const appliedFilterObj = this.getAppliedFilterList("all");
        let allAppliedFilters = getSearchFilterByValue(searchTextboxValue);
        applyLiveDormantFilter ? allAppliedFilters['company'] = getDefaultFilterWithSearch(searchTextboxValue).company : ""; const selectedAppliedFilterFlagList = this.getSelectedAppliedFilterFlagList(appliedFilterObj, searchTextboxValue);
        this.callSearchApi({ value: searchTextboxValue }, trackingObj, allAppliedFilters, selectedAppliedFilterFlagList);
        if (searchTextboxValue) {
            allAppliedFilters["company"] = { ...allAppliedFilters["company"], ...companyAppliedFilterList }
        }
        this.setPowerSearchFilters(allAppliedFilters);
    }

    dropDownModalOptionOnClick = (e) => {
        this.props.dropDownOptionOnClick(e);
        this.setState({ isModalOpen: false })
    }

    render() {
        const {
            formId = "",
            searchButtonPlaceholder = "",
            searchTextboxValue = "",
            inputId = "",
            eventCreatedKeyDown = "",
            eventTypeKeyDown = "",
            eventTypeSearch = "",
            eventCreatedSearch = "",
            eventPage = "",
            eventCreatedOptionClick = "",
            companyList = [],
            directorList = [],
            searchIndustryList = [],
            searchShareholderList = [],
            searchChargesList = [],
            searchRegionList = [],
            directorCount = 0,
            companyCount = 0,
            searchIndustryCount = 0,
            searchShareholderCount = 0,
            searchChargesCount = 0,
            searchRegionCount = 0,
            headerSingleSearchOptions = {},
            singleSearchTypes = [],
            eventCreatedIconClick = "",
            showDropDownTextOnly = false,
            modalHeading = "",
            modelCssClass = "",
            dropDownOptionOnClick = () => { },
            disableSearchIconClick = false,
            showSearchText,
            inputBeforeIcon,
            disableInputForMobile = false
        } = this.props;
        const { minWidth, maxWidth } = this.state
        const newCompanyList = searchTextboxValue ? companyList : [];
        const newDirectorList = searchTextboxValue ? directorList : [];
        const newIndustryList = searchTextboxValue ? searchIndustryList : [];
        const newShareholderList = searchTextboxValue ? searchShareholderList : [];
        const newChargesList = searchTextboxValue ? searchChargesList : [];
        const newRegionList = searchTextboxValue ? searchRegionList : [];
        const newCompanyCount = searchTextboxValue ? companyCount : 0;
        const newDirectorCount = searchTextboxValue ? directorCount : 0;
        const newIndustryCount = searchTextboxValue ? searchIndustryCount : 0;
        const newShareholderCount = searchTextboxValue ? searchShareholderCount : 0;
        const newChargesCount = searchTextboxValue ? searchChargesCount : 0;
        const newRegionCount = searchTextboxValue ? searchRegionCount : 0;
        return (
            <div id="single_search_bar_cmp">
                <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                    <div className="dropdown" id={formId}>
                        {inputBeforeIcon && <picture>
                            <source srcSet={inputBeforeIcon?.webpImageUrl} />
                            <img src={inputBeforeIcon?.imageUrl} className="center-image lazyload"
                                width={inputBeforeIcon?.width}
                                height={inputBeforeIcon?.height}
                                alt={inputBeforeIcon?.alt} />
                        </picture>}
                        <MediaQuery minWidth={minWidth}>
                            {this.props.industry ? <input id={inputId} className="search-button w-100 h-100 dropdown-toggle"
                                pomandae2e={searchButtonPlaceholder} type="text" placeholder={searchButtonPlaceholder}
                                value={searchTextboxValue} tabIndex="0"
                                onKeyDown={(e) => this.onKeyPressed(e)}
                                onClick={() => this.toggleIndustrySearchModal(true, inputId, eventCreatedSearch, eventTypeSearch, eventCreatedOptionClick)}
                                readOnly
                            /> :
                                <input id={inputId} className="search-button w-100 h-100 dropdown-toggle"
                                    pomandae2e={searchButtonPlaceholder} type="text" placeholder={searchButtonPlaceholder}
                                    value={searchTextboxValue} tabIndex="0"
                                    onKeyDown={(e) => disableSearchIconClick ? "" : this.onKeyPressed(e, eventCreatedKeyDown, eventTypeKeyDown)}
                                    onChange={(e) => this.handleChangeSearch(e, eventTypeSearch, eventCreatedSearch, eventPage)}
                                    onFocus={() => this.handleSearchbarInputOnFocus({
                                        eventPage,
                                        eventType: eventTypeSearch,
                                        eventCreated: eventCreatedSearch,
                                        searchText: searchTextboxValue
                                    }, searchTextboxValue)}
                                    data-toggle="dropdown" autoComplete="off" autoCorrect="off" spellCheck="off" data-flip="false"
                                />}

                        </MediaQuery>
                        <MediaQuery maxWidth={maxWidth}>
                            {this.props.industry ? <input id={inputId} className="search-button w-100 h-100"
                                pomandae2e={searchButtonPlaceholder}
                                type="text" placeholder={searchButtonPlaceholder}
                                value={searchTextboxValue} tabIndex="0"
                                onKeyDown={(e) => disableSearchIconClick ? "" : this.onKeyPressed(e)}
                                onClick={() => this.toggleIndustrySearchModal(true, inputId, eventCreatedSearch, eventTypeSearch, eventCreatedOptionClick)}
                                readOnly
                            />
                                : !disableInputForMobile ? <input id={inputId} className="search-button w-100 h-100"
                                    pomandae2e={searchButtonPlaceholder}
                                    type="text" placeholder={searchButtonPlaceholder}
                                    value={searchTextboxValue} tabIndex="0"
                                    onKeyDown={(e) => this.onKeyPressed(e)}
                                    onFocus={() => {
                                        this.toggleSearchModal(true, inputId, searchButtonPlaceholder, eventCreatedSearch, eventTypeSearch, eventCreatedOptionClick);
                                        this.handleSearchbarInputOnFocus({
                                            eventPage,
                                            eventType: eventTypeSearch,
                                            eventCreated: eventCreatedSearch,
                                            searchText: searchTextboxValue
                                        }, searchTextboxValue)
                                    }}
                                    data-toggle="dropdown" autoComplete="off" autoCorrect="off" spellCheck="off" data-flip="false"
                                    readOnly
                                /> : null}
                        </MediaQuery>
                        {showSearchText ? <button className='search-bar-icon' onClick={this.handleSearchIconClick}>
                            <i className="fa fa-search" aria-hidden="true" pomandae2e={eventCreatedIconClick}></i>
                            {showSearchText}
                        </button>
                            :
                            disableInputForMobile ? <i className="fa fa-search search-bar-icon" data-toggle="dropdown" pomandae2e="searchbutton" aria-hidden="true" onClick={() => {
                                this.toggleSearchModal(true, inputId, searchButtonPlaceholder, eventCreatedSearch, eventTypeSearch, eventCreatedOptionClick);
                                this.handleSearchbarInputOnFocus({
                                    eventPage,
                                    eventType: eventTypeSearch,
                                    eventCreated: eventCreatedSearch,
                                    searchText: searchTextboxValue
                                }, searchTextboxValue)
                            }}></i> : <i className="fa fa-search search-bar-icon" pomandae2e="searchbutton" aria-hidden="true" onClick={disableSearchIconClick ? null : this.props.industry ?
                                () => this.toggleIndustrySearchModal(true, inputId, eventCreatedSearch, eventTypeSearch, eventCreatedIconClick) : this.handleSearchIconClick}></i>}
                        <MediaQuery minWidth={minWidth}>
                            <SingleSearchDropdown
                                searchTextboxValue={searchTextboxValue}
                                companyList={newCompanyList}
                                directorList={newDirectorList}
                                industryList={newIndustryList}
                                shareholderList={newShareholderList}
                                chargesList={newChargesList}
                                regionList={newRegionList}
                                directorCount={newDirectorCount}
                                companyCount={newCompanyCount}
                                industryCount={newIndustryCount}
                                shareholderCount={newShareholderCount}
                                chargesCount={newChargesCount}
                                regionCount={newRegionCount}
                                eventPage={eventPage}
                                eventCreatedOptionClick={eventCreatedOptionClick}
                                headerSingleSearchOptions={headerSingleSearchOptions}
                                id={inputId}
                                countOptionOnclick={() => this.props.dispatchSetHeaderSearchText(searchTextboxValue)}
                                dropDownOptionOnClick={dropDownOptionOnClick}
                                singleSearchTypes={singleSearchTypes}
                                showDropDownTextOnly={showDropDownTextOnly}
                            />
                        </MediaQuery>
                    </div>
                    <MediaQuery maxWidth={maxWidth}>
                        {this.state.isModalOpen && <SingleSearchModalBar
                            isOpen={this.state.isModalOpen}
                            handleModalBackLink={this.toggleSearchModal}
                            inputPlaceholder={this.state.modalPlaceholder}
                            handleOnKeyDown={this.onKeyPressed}
                            handleOnChange={this.handleChangeSearch}
                            inputSearchValue={searchTextboxValue}
                            handleSearchClick={this.handleSearchIconClick}
                            inputId={this.state.modalInputId}
                            eventCreated={this.state.modalEventCreated}
                            eventType={this.state.modalEventType}
                            eventPage={eventPage}
                            eventCreatedOption={this.state.modalOptionEventCreated}
                            companyList={newCompanyList}
                            directorList={newDirectorList}
                            industryList={newIndustryList}
                            shareholderList={newShareholderList}
                            chargesList={newChargesList}
                            regionList={newRegionList}
                            directorCount={newDirectorCount}
                            companyCount={newCompanyCount}
                            industryCount={newIndustryCount}
                            shareholderCount={newShareholderCount}
                            chargesCount={newChargesCount}
                            regionCount={newRegionCount}
                            headerSingleSearchOptions={headerSingleSearchOptions}
                            optionOnclickFunction={() => { this.setState({ isModalOpen: false }) }}
                            countOptionOnclick={() => this.props.dispatchSetHeaderSearchText(searchTextboxValue)}
                            singleSearchTypes={singleSearchTypes}
                            showDropDownTextOnly={showDropDownTextOnly}
                            modalHeading={modalHeading}
                            modelCssClass={modelCssClass}
                            dropDownOptionOnClick={this.dropDownModalOptionOnClick}
                        />}
                    </MediaQuery>
                </form>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    powerSearchType: state.searchReducer.powerSearchType,
    headerSearchText: state.common.headerSearchText,
    companyList: state.searchReducer.searchCompanyList,
    directorList: state.searchReducer.searchDirectorList,
    searchIndustryList: state.searchReducer.searchIndustryList,
    searchShareholderList: state.searchReducer.searchShareholderList,
    searchChargesList: state.searchReducer.searchChargesList,
    searchRegionList: state.searchReducer.searchRegionList,
    searchIndustryCount: state.searchReducer.searchIndustryCount,
    searchShareholderCount: state.searchReducer.searchShareholderCount,
    searchChargesCount: state.searchReducer.searchChargesCount,
    searchRegionCount: state.searchReducer.searchRegionCount,
    directorCount: state.searchReducer.searchDirectorCount,
    companyCount: state.searchReducer.searchCompanyCount,
    headerSingleSearchOptions: state.common.headerSingleSearchOptions,
    companyAppliedFilterList: state.searchReducer.appliedFilterList,
    directorAppliedFilterList: state.searchReducer.directorAppliedFilterList,
    userInfo: state.userProfile.userInfo,
    companyQueryData: state.searchReducer.companyQueryData,
    directorQueryData: state.searchReducer.directorQueryData,
    isEstimateOn: state.searchReducer.isEstimateOn,
    industryQueryData: state.searchReducer.industryQueryData,
    shareholderQueryData: state.searchReducer.shareholderQueryData,
    chargesQueryData: state.searchReducer.chargesQueryData,
    regionQueryData: state.searchReducer.regionQueryData,
    regionAppliedFilterList: state.searchReducer.regionAppliedFilterList,
    industryProfile: state.industryProfile,
    seeMoreModalObj: state.common.seeMoreModalObj,
    industryAppliedFilterList: state.searchReducer.industryAppliedFilterList,
    shareholderAppliedFilterList: state.searchReducer.shareholderAppliedFilterList,
    chargesAppliedFilterList: state.searchReducer.chargesAppliedFilterList,

});

const mapDispatchToProps = (dispatch) => ({
    toggleMenuModal: (toggleStatus) => dispatch(toggleMenuModal(toggleStatus)),
    dispatchToggleLoginModal: (toggleStatus, trackingInfo, onSuccessRender) => dispatch(toggleLoginModal(toggleStatus, trackingInfo, onSuccessRender)),
    setActivityTrackingData: trackingObj => dispatch(setActivityTrackingData(trackingObj)),
    dispatchSetHeaderSearchText: (searchText) => dispatch(setHeaderSearchText(searchText)),
    dispatchCallSingleSearchApi: (searchObj) => dispatch(callSingleSearchApiAction(searchObj)),
    dispatchToggleSignupModal: (toggleStatus, trackingInfo, onSuccessRender) => dispatch(toggleSignupModal(toggleStatus, trackingInfo, onSuccessRender)),
    dispatchCompanySearchAppliedFilter: (appliedFilter) => dispatch(setCompanySearchAppliedFilter(appliedFilter)),
    dispatchDirectorSearchAppliedFilter: (appliedFilter) => dispatch(setDirectorSearchAppliedFilter(appliedFilter)),
    dispatchSetPowerSearchType: (type) => dispatch(setPowerSearchType(type)),
    dispatchSeeMoreIndustryModel: toggleStatus => dispatch(toggleSeemoreModal(toggleStatus)),
    dispatchIndustrySearchAppliedFilter: (appliedFilter) => dispatch(setIndustrySearchAppliedFilter(appliedFilter)),
    dispatchShareholderSearchAppliedFilter: (appliedFilter) => dispatch(setShareholderSearchAppliedFilter(appliedFilter)),
    dispatchChargesSearchAppliedFilter: (appliedFilter) => dispatch(setChargesSearchAppliedFilter(appliedFilter)),
    dispatchRegionSearchAppliedFilter: (appliedFilter) => dispatch(setRegionSearchAppliedFilter(appliedFilter)),
    dispatchSendActivityTracking: (trackingObj) => dispatch(sendActivityTracking(trackingObj)),
    dispatchGetIndustryProfileSearchResult: searchedText => dispatch(getIndustryProfileSearchResult(searchedText)),
    dispatchResetAllSearchParams: searchParam => dispatch(resetAllSearchParams(searchParam)),
    dispatchSetHeaderSingleSearchOptions: optionObj => dispatch(setHeaderSingleSearchOptions(optionObj)),
    dispatchSetCompanySearchParams: (searchObj) => dispatch(setCompanySearchParams(searchObj)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SingleSearchBar));