import { call, put, takeLatest, all, select } from "redux-saga/effects";
import { SET_PRODUCT_PAGE_SCREEN, HANDLE_PRODUCT_PLAN_BUTTON_CLICK } from "../redux/actions/actionConstants";
import {
    sendActivityTracking, setLoading
} from "../redux/actions/commonAction"
import { getProducts } from "../components/ProductPage/Products";
import { setProductPageScreenSuccess } from "../redux/actions/productAction";
import {
    BUSINESS_PLAN_ONBOARD_PKG_PURCHASE_CREDIT,
    DOWNLOAD_REPORT, DOWNLOAD_REPORT_MSG, SR_ACCOUNT_PAGE,
    SR_CREDIT_REPORT, SR_SIGNUP_BTN_CLICK_HEADER, SR_SIGNUP_BTN_CLICK_LOGIN_MODAL,
    SR_SIGNUP_BTN_CLICK_MBL_MENU,
    SR_SUBMIT_INTEREST_BTN_CLICK
} from "../common/subscriptionConfigConst";
import {
    EP_SIGNUP_CTA_CLICK, EP_SUBMIT_INVESTMENT_INTEREST_MODAL,
    ET_SUBMIT_INVESTMENT_INTEREST_BTN,
    ET_SUBMIT_INVESTMENT_INTEREST_SUBMIT_BTN, LOGIN_EVENT_PAGE, LOGIN_TOGGLE_ON_EVENT_TYPE, SIGNUP_EVENT_PAGE,
    SIGNUP_TOGGLE_ON_EVENT_TYPE
} from "../common/trackingConstants";
import { isBrowser } from "../common/config";
import { createStripeSession } from "../redux/actions/subscriptionAction";
import { toggleLoginModal, toggleSignupModal } from "../redux/actions/loginSignupAction";
import { CREATE_STRIPE_SESSION_ON_SIGNUP_SUCCESS, TOGGLE_LOGIN_MODAL_ON_SUCCESS, TOGGLE_SIGNUP_MODAL_ON_SUCCESS } from "../common/loginSignupSuccessConstants";
import { callCreditReportApiAction, toggleCRDownloadLoadingModal, toggleSubmitInvestmentInterestModal } from "../redux/actions/companyAction";
import localStorageApi from '../api/localStorageApi';
import { updateUserFreeCRCountAction, updateUserReportCredits } from "../redux/actions/userAccountAction";
import { saveOrUpdateBusinessPlanOnboardApi } from "../api/businessPlanApi";
import { updateBusinessPlansCreditApi } from "../api/userAccountApi";

const planValues = (state) => state.subscription.planDetailValues
const userInfo = (state) => state.userProfile.userInfo


function* setProductPageScreenSaga(action) {
    try {
        const planDetailsValues = yield select(planValues);
        const user = yield select(userInfo);
        const token = isBrowser && (yield call(localStorageApi.getUserAuthToken));
        
        // Get products
        const products = getProducts(planDetailsValues);
        
        // Create product object with appropriate button states
        const productObj = createProductObjectWithButtonStates(products, user, action);
        
        // Handle specific signup logic if needed
        const serviceReq = action.payload.screenObj.serviceReq;
        if ([SR_SIGNUP_BTN_CLICK_LOGIN_MODAL, SR_SIGNUP_BTN_CLICK_HEADER, 
             SR_SIGNUP_BTN_CLICK_MBL_MENU, SR_SUBMIT_INTEREST_BTN_CLICK].includes(serviceReq)) {
            yield handleSignupActions(serviceReq, token);
        }
        
        yield put(setProductPageScreenSuccess(productObj));
    } catch (error) {
        console.log('inside setProductPageScreenSaga ', error);
    }
}

// Subscription hierarchy (in order from lowest to highest)
const SUBSCRIPTION_HIERARCHY = [
    "BusinessOneDay",
    "BusinessMonthly",
    "BusinessYearly",
    "SalesMonthly",
    "SalesYearly",
    "AdvisorsYearly",
    "InvestorsYearly"
];

// Get button config based on user subscription
function getButtonConfig(userSubscription, productName) {
    // Default for no subscription
    if (!userSubscription || userSubscription.active !== "yes") {
        return {
            text: "Purchase Now",
            disabled: null
        };
    }
    
    // Get subscription level
    const userProduct = getUserSubscriptionProduct(userSubscription);
    
    // If user is subscribed to this exact product
    if (userProduct === productName) {
        return {
            text: "Subscribed",
            disabled: true
        };
    }
    
    // Compare subscription levels
    const userLevel = SUBSCRIPTION_HIERARCHY.indexOf(userProduct);
    const productLevel = SUBSCRIPTION_HIERARCHY.indexOf(productName);
    
    // If user's subscription is higher than this product
    if (userLevel > productLevel) {
        return {
            text: "Subscribed",
            disabled: true
        };
    }
    
    // If user's subscription is lower (they can upgrade)
    return {
        text: "Upgrade Now",
        disabled: false
    };
}

// Helper to determine which product the user is subscribed to
function getUserSubscriptionProduct(userSubscription) {
    if (!userSubscription) return null;
    
    const { productName, interval, planType } = userSubscription;
    
    if (productName === "PomandaPlus") {
        // Map legacy PomandaPlus subscriptions to the new hierarchy
        if (interval === "day") {
            return "BusinessOneDay";
        } else if (interval === "month") {
            return planType === "Business" ? "BusinessMonthly" : 
                   planType === "Sales" ? "SalesMonthly" : "BusinessMonthly";
        } else if (interval === "year") {
            return planType === "Business" ? "BusinessYearly" : 
                   planType === "Sales" ? "SalesYearly" :
                   planType === "Advisors" ? "AdvisorsYearly" :
                   planType === "Investors" ? "InvestorsYearly" : "BusinessYearly";
        }
    }
    
    // For directly matching product names
    return productName;
}

// Helper function to handle signup actions
function* handleSignupActions(serviceReq, token) {
    yield put(sendActivityTracking({
        eventPage: EP_SIGNUP_CTA_CLICK,
        eventType: `ET_${serviceReq}`
    }));
    
    if (!token || token === "" || token === null) {
        yield put(toggleSignupModal(
            { status: true },
            {
                eventPage: SIGNUP_EVENT_PAGE,
                eventType: SIGNUP_TOGGLE_ON_EVENT_TYPE
            },
            {}
        ));
    }
}

// Main function to create product object with button states
function createProductObjectWithButtonStates(products, user, action) {
    const serviceReq = action.payload.screenObj.serviceReq;
    const userSubscription = user?.subscription;
    
    // Default business products
    const productObj = {
        product1: {
            type: 'periodic',
            isPeriodic: true,
            monthly: {
                ...products.businessMonthly,
                price: { ...products.businessMonthly.price },
                btn: getButtonConfig(userSubscription, "BusinessMonthly")
            },
            yearly: {
                ...products.businessYearly,
                price: { ...products.businessYearly.price },
                btn: getButtonConfig(userSubscription, "BusinessYearly")
            }
        },
        product2: {
            type: 'periodic',
            isPeriodic: true,
            monthly: {
                ...products.salesMonthly,
                btn: getButtonConfig(userSubscription, "SalesMonthly")
            },
            yearly: {
                ...products.salesYearly,
                btn: getButtonConfig(userSubscription, "SalesYearly")
            }
        },
        product3: {
            ...products.advisorsYearly,
            btn: getButtonConfig(userSubscription, "AdvisorsYearly")
        },
        product4: {
            ...products.investorsYearly,
            btn: getButtonConfig(userSubscription, "InvestorsYearly")
        },
        product5: {
            ...products.businessOneDay,
            btn: getButtonConfig(userSubscription, "BusinessOneDay")
        },
        free: {
            ...products.free,
            btn: { ...products.free.btn }
        },
        pomandaPlus5k: {
            ...products.pomandaPlus5k,
            btn: getButtonConfig(userSubscription, "SalesYearly")
        }
    };
    
    // Handle specific service request cases
    if (serviceReq === SR_CREDIT_REPORT) {
        productObj.product2 = {
            ...products.oneTimePurchaseCreditReport,
            smallDescription: () => products.oneTimePurchaseCreditReport.getSmallDescription(action.payload.screenObj.companyName)
        };
    } else if (serviceReq === SR_ACCOUNT_PAGE) {
        // Account page specific customizations if needed
    } 
    // Add other specific cases as needed
    
    return productObj;
}

export function* watchSetProductPageScreenSaga() {
    yield takeLatest(SET_PRODUCT_PAGE_SCREEN, setProductPageScreenSaga);
}

function* handleProductPlanButtonClickSaga(action) {
    try {
        const { 
            productId, saveBusinessPlanObj, remainingReports=0, ...productObj 
        } = action.payload.productObj;
        let token = isBrowser && (yield call(localStorageApi.getUserAuthToken))
        const userInfoObj = yield select(userInfo);
        
        switch (productId) {
            case "businessOneDay":
            case "businessMonthly":
            case "businessYearly":
            case "salesMonthly":
            case "salesYearly":
            case "advisors":
            case "investors":
            case "oneTimePurchaseCreditReport":
            case "percent30OffReport":
            case "pomandaPlusOneDay":
            case "oneTimePurchaseCompanyReport":
            case "oneTime5CreditReportPkg":
            case "oneTime20CreditReportPkg":
            case "oneTime100CreditReportPkg":
            case "oneTimeInfiniteCreditReportPkg":
            case "oneTime1ValuationReportPkg":
            case "oneTime5ValuationReportPkg":
            case "oneTime20ValuationReportPkg":
            case "oneTimeExtraCSVDownloadsPkg":
            case "oneTime5contactDetailsCreditPkg":
            case "oneTime100contactDetailsCreditPkg":
            case "oneTime500contactDetailsCreditPkg":
            case "oneTime1000contactDetailsCreditPkg":
            case "oneTime5000contactDetailsCreditPkg":
            case "oneTime10000contactDetailsCreditPkg":
                
                if (token && token !== "") {
                    yield put(createStripeSession(productObj));
                } else {
                    yield put(toggleSignupModal(
                        { status: true, signUpModelData: action.payload?.productObj?.signUpModelData },
                        {
                            eventPage: SIGNUP_EVENT_PAGE,
                            eventType: SIGNUP_TOGGLE_ON_EVENT_TYPE,
                            // ...trkAttribute
                        }, {
                        toggleType: TOGGLE_SIGNUP_MODAL_ON_SUCCESS,
                        serviceType: CREATE_STRIPE_SESSION_ON_SIGNUP_SUCCESS,
                        eventPage: action.payload?.eventPage,
                        stripeSessionObj: { ...action.payload.productObj }
                    }))
                }
                break;
            case "oneTimeEnterpriseValuationReportPkg":
                if (token && token !== "") {
                    yield put(createStripeSession(productObj));
                } else {
                    if (productObj.userType === "basic") {
                        yield put(toggleLoginModal(
                            { status: true },
                            {
                                eventPage: LOGIN_EVENT_PAGE,
                                eventType: LOGIN_TOGGLE_ON_EVENT_TYPE,
                                // ...trkAttribute
                            }, {
                            toggleType: TOGGLE_LOGIN_MODAL_ON_SUCCESS,
                            serviceType: CREATE_STRIPE_SESSION_ON_SIGNUP_SUCCESS,
                            eventPage: action.payload?.eventPage,
                            stripeSessionObj: { ...action.payload.productObj }
                        }))
                    } else {
                        yield put(toggleSignupModal(
                            { status: true },
                            {
                                eventPage: SIGNUP_EVENT_PAGE,
                                eventType: SIGNUP_TOGGLE_ON_EVENT_TYPE,
                                // ...trkAttribute
                            }, {
                            toggleType: TOGGLE_SIGNUP_MODAL_ON_SUCCESS,
                            serviceType: CREATE_STRIPE_SESSION_ON_SIGNUP_SUCCESS,
                            eventPage: action.payload?.eventPage,
                            stripeSessionObj: { ...action.payload.productObj }
                        }))
                    }
                }
                break;
            case "enterprise":
            case "whiteLabelEnquiry":
            case "subscriptionAssistanceRequest":
            case "valuationSubscription":
                const subHeading = `Get in touch with one of our team to discuss how we can create a subscription package that meets the needs of your organisation.`;
                const userMessage = `Please contact me to discuss options for teams and larger organisations...`;
                const subject = productId === "enterprise" ? "Enterprise Sales" : productId === "whiteLabelEnquiry" ? "White Label Inquiry" : productId === "valuationSubscription" ? "Valuation Subscription Inquiry" : "Subscription Assistance Request";
                const heading = productId === "enterprise" ? "Enterprise Sales" : productId === "whiteLabelEnquiry" ? "White Label Inquiry" : productId === "valuationSubscription" ? "Valuation Subscription Inquiry" : "Subscription Assistance Request";
                yield put(toggleSubmitInvestmentInterestModal({
                    toggleStatus: true,
                    companyId: productObj?.companyId,
                    subject,
                    subHeading,
                    heading,
                    userMessage,
                    descriptionLabel: "Describe your requirements",
                    mailRecipient: "SUBMIT_INTEREST_PRODUCT_EMAIL_ADDRESS",
                    eventPage: EP_SUBMIT_INVESTMENT_INTEREST_MODAL,
                    eventType: ET_SUBMIT_INVESTMENT_INTEREST_SUBMIT_BTN
                }));
                break;
            case "pomandaPlusFreeReport":
            case "unlimitedCR":
                yield all([
                    put(callCreditReportApiAction({ ...productObj, userId: userInfoObj._id })),
                    put(toggleCRDownloadLoadingModal({
                        loaderImg: true,
                        heading: DOWNLOAD_REPORT,
                        subHeading: DOWNLOAD_REPORT_MSG,
                        toggleStatus: true,
                        showContinueBtn: true
                    })),
                    userInfoObj?.subscription?.freeCR !== "UN_LIMITED" && put(updateUserFreeCRCountAction(userInfoObj.subscription.freeCR - 1))
                ]);
                break;
            case "oneCredit":
                yield all([
                    put(callCreditReportApiAction({ ...productObj, userId: userInfoObj._id })),
                    put(toggleCRDownloadLoadingModal({
                        loaderImg: true,
                        heading: DOWNLOAD_REPORT,
                        subHeading: DOWNLOAD_REPORT_MSG,
                        toggleStatus: true,
                        showContinueBtn: true
                    })),
                    put(updateUserReportCredits(userInfoObj.reportPkgSubscription.creditCount - 1))
                ]);
                break;
            case "businessPlanOnboardPkg":
            case "businessPlanOnboardDiscountPkg": {
                if(!saveBusinessPlanObj){
                    if (token && token !== "") {
                        yield put(createStripeSession(productObj));
                    } else {
                        yield put(toggleSignupModal(
                            { status: true, signUpModelData: action.payload?.productObj?.signUpModelData },
                            {
                                eventPage: SIGNUP_EVENT_PAGE,
                                eventType: SIGNUP_TOGGLE_ON_EVENT_TYPE,
                                // ...trkAttribute
                            }, {
                            toggleType: TOGGLE_SIGNUP_MODAL_ON_SUCCESS,
                            serviceType: CREATE_STRIPE_SESSION_ON_SIGNUP_SUCCESS,
                            eventPage: action.payload?.eventPage,
                            stripeSessionObj: { ...action.payload.productObj }
                        }))
                    }
                }else{
                    const {
                        companyExistingStatus, companyName, companyAddress, businessPlanId,
                        postCode, companyId, industryList, componentId, industryDescription, customerDesc,location,
                        startdate, salesTarget, tradingName, keyPeople, trad, userType, country
                    } = saveBusinessPlanObj;
                    const newUniqueId = `pom_${new Date().getTime()}${Math.floor(Math.random() * 10)}`;
                    const renderedUniqueId = saveBusinessPlanObj.uniqueId || newUniqueId;
                    const response = yield call(saveOrUpdateBusinessPlanOnboardApi, {
                        companyExistingStatus, companyName, companyAddress,
                        postCode, companyId, industryList, componentId,
                        locationList: trad, businessPlanId, uniqueId: renderedUniqueId, industryDescription,
                        salesTarget, location, startdate, customerDesc, tradingName, keyPeople,
                        status: "inactive", userType, country
                    });
                    if (!response.error) {
                        const bussPlanUrl = `${window.location.origin}/businessplan/${response.uniqueId}/summary?isSuccess=true&email=${userInfoObj?.email}&planSelected=${productId}&serviceRequested=${BUSINESS_PLAN_ONBOARD_PKG_PURCHASE_CREDIT}&businessPlanId=${response?.businessPlanId || productObj?.businessPlanId}&uniqueId=${response?.uniqueId}&period=BUSINESS_PLAN_ONBOARD_PKG`
                        window.open(bussPlanUrl, "_self");
                    }
                }
                // commented as we are allowing logged in user for purchase flow by handling login scenario in business plan onboard component.
                // else {
                //     yield put(toggleSignupModal(
                //         { status: true },
                //         {
                //             eventPage: SIGNUP_EVENT_PAGE,
                //             eventType: SIGNUP_TOGGLE_ON_EVENT_TYPE,
                //             // ...trkAttribute
                //         }, {
                //         toggleType: TOGGLE_SIGNUP_MODAL_ON_SUCCESS,
                //         serviceType: CREATE_STRIPE_SESSION_ON_SIGNUP_SUCCESS,
                //         eventPage: action.payload?.eventPage,
                //         stripeSessionObj: { ...action.payload.productObj }
                //     }))
                // }
                break;
            }
            case "prosperGetCredits": 
                yield put(toggleSubmitInvestmentInterestModal({
                    toggleStatus: true,
                    subject: "Contact Us",
                    subHeading: `If you are interested in getting an up to date 
                        Credit Report fill in this form and a member of the Prosper2 
                        team will be in touch.`,
                    heading: "Contact Us",
                    userMessage: "What can we help with?",
                    descriptionLabel: "Get in touch",
                    mailRecipient: "CONTACT_US_EMAIL_ADDRESS",
                    eventPage: EP_SUBMIT_INVESTMENT_INTEREST_MODAL,
                    eventType: ET_SUBMIT_INVESTMENT_INTEREST_BTN,
                    companyId: productObj.companyId
                }))
            default:
            // code block
        }
    } catch (error) {
        console.log('inside handleProductPlanButtonClickSaga ', error);
    }
}

export function* watchHandleProductPlanButtonClickSaga() {
    yield takeLatest(HANDLE_PRODUCT_PLAN_BUTTON_CLICK, handleProductPlanButtonClickSaga);
}
