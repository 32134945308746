import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from "reactstrap";
import YellowBorderButton from "../../common/Buttons/YellowBorderButton";
import './WatchListProfilingModal.css'
import { useDispatch, useSelector } from "react-redux";
import { getWatchListProfilingRequest, setWatchListProfilingSuccess } from "../../../redux/actions/watchListAction";
import WatchListProfilingTabMenu from "./WatchListProfilingTabMenu";
import MediaQuery from 'react-responsive';
import WatchListProfileCardComp from "./WatchListProfileCardComp";
import { EP_POWER_SEARCH_COMPANY, WATCHLIST_PROFILIING_DOWNLOAD_REPORT_BTN_CLICK, WATCHLIST_PROFILING_TAB_MENU_CLICK } from "../../../common/trackingConstants";
import { sendActivityTracking } from "../../../redux/actions/commonAction";

const watchListProfileTabMenuList = [
    {
        "menuText": "Company Status",
        "sectionId": "compStatus",
        "subSectionId": "",
        "tabs": {
            "Companies": {"chart": true, "rawData": true},
            "Turnover": {"chart": true, "rawData": true},
            "Employees": {"chart": true, "rawData": true}
        }
    },
    {   "menuText": "Age", 
        "sectionId": "compAgeTag",
        "subSectionId": "",
        "tabs": {
            "Companies": {"chart": true, "rawData": true},
            "Turnover": {"chart": true, "rawData": true},
            "Employees": {"chart": true, "rawData": true}
        }
    },
    {
        "menuText": "Company Size",
        "sectionId": "compSizeTag",
        "subSectionId": "",
        "tabs": {
            "Companies": {"chart": true, "rawData": true},
            "Turnover": {"chart": true, "rawData": true},
            "Employees": {"chart": true, "rawData": true}
        }
    },
    {
        "menuText": "Growth Rate",
        "sectionId": "compGrowthTag",
        "subSectionId": "",
        "tabs": {
            "Companies":{"chart": true, "rawData": true},
            "Turnover": {"chart": true, "rawData": true},
            "Employees": {"chart": true, "rawData": true}
        }
    },
    {
        "menuText": "Health Rating",
        "sectionId": "PomandaRating",
        "subSectionId": "",
        "tabs": {
            "Companies": {"chart": true, "rawData": true},
            "Turnover": {"chart": true, "rawData": true},
            "Employees": {"chart": true, "rawData": true}
        }
    },
    {
        "menuText": "Company Type",
        "sectionId": "CompanyTypeDesc",
        "subSectionId": "",
        "tabs": {
            "Companies": {"chart": true, "rawData": true},
            "Turnover": {"chart": true, "rawData": true},
            "Employees": {"chart": true, "rawData": true}
        }
    },
    {
        "menuText": "Ownership",
        "sectionId": "Ownership",
        "subSectionId": "",
        "tabs": {
            "Companies": {"chart": true, "rawData": true},
            "Turnover": {"chart": true, "rawData": true},
            "Employees": {"chart": true, "rawData": true}
        }
    },
    {
        "menuText": "Industry",
        "sectionId": "insustry",
        "subSectionId": "",
        "tabs": {
            "Companies": {"chart": true, "rawData": true},
            "Turnover": {"chart": true, "rawData": true},
            "Employees": {"chart": true, "rawData": true}
        }
    },
    {
        "menuText": "Location",
        "sectionId": "location",
        "subSectionId": "",
        "tabs": {
            "Companies": {"chart": true, "rawData": true},
            "Turnover": {"chart": true, "rawData": true},
            "Employees": {"chart": true, "rawData": true}
        }
    },
    {
        "menuText": "Next Accounts Date",
        "sectionId": "accountsLate",
        "subSectionId": "",
        "tabs": {
            "Companies": {"chart": true, "rawData": true},
            "Turnover": {"chart": true, "rawData": true},
            "Employees": {"chart": true, "rawData": true}
        }
    },
    {
        "menuText": "Companies Top 10",
        "sectionId": "top10Companies",
        "subSectionId": "",
        "tabs": {
            "Companies": {"chart": false, "rawData": false},
            "Turnover": {"chart": true, "rawData": true},
            "Employees": {"chart": true, "rawData": true}
        }
    }
]


const WatchListProfilingModal = (props) => {
    const dispatch = useDispatch()
    const [activeMenu, setActiveMenu] = useState({text: watchListProfileTabMenuList[0].menuText, sectionId: watchListProfileTabMenuList[0].sectionId, subSectionId: watchListProfileTabMenuList[0].subSectionId, tabs: watchListProfileTabMenuList[0].tabs})
    const watchlistProfiles = useSelector(state => state.watchListReducer.watchListProfilingObj)
    const [aciveMenuData, setActiveMenuData] = useState([])
    const {
        toggleModal = () => { },
        watchListName="",
        watchListId="",
        button = {},
        watchListDescription=""
    } = props;

    const handleDownloadReport = () => {
        dispatch(sendActivityTracking({
            eventPage: EP_POWER_SEARCH_COMPANY,
            eventType: WATCHLIST_PROFILIING_DOWNLOAD_REPORT_BTN_CLICK,
        }));
    }
    const {
        btnText="Download Report",
        btnOnClick= handleDownloadReport ,
        btncssClass='wlp-downlaod-btn'
    } = button

    const handleMenuClick = (item) => {
        setActiveMenu({ text: item.menuText, sectionId: item.sectionId, subSectionId: item.subSectionId, tabs: item.tabs })
        dispatch(sendActivityTracking({
            eventPage: EP_POWER_SEARCH_COMPANY,
            eventType: WATCHLIST_PROFILING_TAB_MENU_CLICK,
            attribute1: activeMenu?.text
        }))
    }

    useEffect(() => {
        dispatch(getWatchListProfilingRequest(watchListId))
        return (() => {
            dispatch(setWatchListProfilingSuccess(""))
        })
    },[])

    useEffect(() => {
        setActiveMenuData(watchlistProfiles?.[activeMenu.sectionId] )
    },[activeMenu])

    useEffect(() => {
        setActiveMenuData(watchlistProfiles?.[activeMenu?.sectionId])
    },[watchlistProfiles])

   
   
    return (
        <Modal isOpen={true} toggle={toggleModal} className="watchList-profiling-modal">
            <div className="wl-profiling-modal-header">
                <div className="wl-profiling-header-container">
                    <div className="wl-profiling-name-section">
                        <p className="wl-profiling-list-name">{`${watchListName} Profiling`}</p>
                        <p className="wl-profiling-list-desc" >{watchListDescription}</p>
                    </div>
                    <div className="wl-profiling-header-btn-section">
                        <MediaQuery minWidth={992}>
                            <YellowBorderButton text={btnText} handleClick={btnOnClick} tooltipId="watchListDownloadReportTooltip" />
                            <UncontrolledTooltip placement="bottom" target="watchListDownloadReportTooltip" innerClassName="watchlist-download-report-tooltip">Coming Soon...</UncontrolledTooltip>       
                        </MediaQuery>
                        <i className="fa fa-close wlp-modal-close" onClick={toggleModal} />
                    </div>
                </div>
                <WatchListProfilingTabMenu
                    menuItemsList={watchListProfileTabMenuList}
                    activeMenu={activeMenu.text}
                    handleMenuClick={handleMenuClick}
                />
            </div>
            <ModalBody className="wl-profiling-modal-body">
                <div className="wl-profiling-cards-container">
                    <div className="wl-profiling-cards-section">
                        {aciveMenuData?.map((obj) => {
                            return (
                                <WatchListProfileCardComp 
                                graphData={obj} 
                                currentTabObj={activeMenu.tabs?.[obj?.["category"]]} 
                                watchListName={watchListName} 
                                topActiveMenuId={activeMenu.sectionId}
                                topActiveMenuText={activeMenu.text}/>
                            )
                        })}
                    </div>
                    {
                        activeMenu?.subSectionId ?
                            (
                                <div className="wl-profiling-cards-section">
                                    {watchlistProfiles?.[activeMenu?.subSectionId]?.map((obj) => {
                                        return (
                                            <WatchListProfileCardComp 
                                            graphData={obj} 
                                            currentTabObj={activeMenu.tabs?.[obj?.["category"]]} 
                                            watchListName={watchListName} 
                                            topActiveMenuId={activeMenu.sectionId}
                                            topActiveMenuText={activeMenu.text} />
                                        )
                                    })}
                                </div>
                            )
                            : ""
                    }
                </div>
            </ModalBody>
            <MediaQuery maxWidth={991.98}>
                <ModalFooter className="wl-profiling-modal-footer">
                    <div className="wlp-download-btn-wrapper">
                        <YellowBorderButton text={btnText} handleClick={btnOnClick} cssClassName={btncssClass} tooltipId="watchListDownloadReportTooltip" />
                        <UncontrolledTooltip placement="bottom" target="watchListDownloadReportTooltip" innerClassName="watchlist-download-report-tooltip">Coming Soon...</UncontrolledTooltip>       
                    </div>
                </ModalFooter>
            </MediaQuery>
        </Modal>
    )
}

export default WatchListProfilingModal