import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import './WatchListProfileChartZoomModal.css';
import TreeMapParent from "../../TreeMapParent/TreeMapParent";

const WatchListProfileChartZoomModal = (props) => {
    const {
        toggle = () => { },
        graphData = [],
        screen = {},
        heading=""
    } = props
    return (
        <Modal isOpen={true} toggle={toggle} className="wlp-chart-zoom-modal">
            <ModalHeader>
                <div className="wlp-chart-zoom-modal-header">
                    <p className="wlp-chart-zoom-header-text">{heading}</p>
                    <i className="fa fa-close wlp-chart-zoom-modal-close" onClick={toggle} />
                </div>
            </ModalHeader>
            <ModalBody className='wlp-chart-zoom-modal-body'>
                <TreeMapParent
                    graphData={graphData}
                    screen={screen}
                    isWatchListProfile={true}
                    colors={['#3B82F6', '#FACC15', '#A061FF', '#A5A5A5', '#F66', '#6B77FF', '#F6863B']}
                />
            </ModalBody>
        </Modal>
    )
}

export default WatchListProfileChartZoomModal;