export const H_CHOOSE_PLAN = "Choose a Plan";
export const H_SUB_POMANDA_PLUS = "Subscribe To Pomanda Plus"
export const SH_UNLOCK_FULL_POWER = "Unlock the full power of Pomanda - no minimum contract period, cancel at any time.";
export const SH_CONTINUE_POMANDA_USE = "You've Reached Your Daily Limit. Unlock the full power of Pomanda - no minimum contract period, cancel at any time.";
export const SH_NO_MINIMUM_CONTRACT = "No minimum contract period, cancel at any time."
export const H_SUB_DISCOUNTED_CREDIT_REPORT = "Discounted Credit Report"
export const SH_DISCOUNTED_CREDIT_REPORT = "You've already used your free monthly credit report. Use your 30% subscriber discount now!";
export const H_DOWNLOAD_CSV_FOR_SUBSCRIBERS = "Downloading is only available to Pomanda subscribers"
export const H_SAVED_SEARCHES_FOR_SUBSCRIBERS = "Saved Searches are only available to Pomanda subscribers"
export const H_UPDATE_INDUSTRIES_FOR_SUBSCRIBERS = "Industry updating is only available to Pomanda subscribers"
export const SH_ONE_TIME_CREDIT_REPORT = "<b>Our Reports Include</b> - credit scores, detailed company, directors & shareholder information, mortgages, historical financial data and much more...";
export const H_CHARGES_PS_FOR_SUBSCRIBERS = "Charges & Mortgages filters are available only for Pomanda Plus subscribers."

// export const H_SUB_DISCOUNTED_CREDIT_REPORT="Discounted Credit Report"
export const H_SUB_FREE_CREDIT_REPORT = "Would you like to use your FREE Credit Report for ";
export const H_DOWNLOAD_FINANCIALS_EXCEL_FOR_SUBSCRIBERS = "Excel download is only available to Pomanda subscribers";
export const H_SUB_ONE_CREDIT = "Would you like to download a Credit Report for "

export const SR_CREDIT_REPORT = "CreditReport";
export const SR_DAILY_SEARCH_LIMIT = "DailySearchLimit"
export const COMPETITORS_LIMIT_REACHED = "COMPETITORS_LIMIT_REACHED";
export const SR_CH_FILLING = "CH_FILLING"
export const SR_SIMILAR_COMPANY = "SIMILAR_COMPANY"
export const SR_POWER_SEARCH = "PowerSearch"
export const SR_ACCOUNT_PAGE = "ACCOUNT_PAGE"
export const SR_DOWNLOAD_CSV = "DOWNLOAD_CSV";
export const SR_SAVED_SEARCHES = "SAVED_SEARCHES";
export const SR_ADD_SAVED_SEARCH = "ADD_SAVED_SEARCH";
export const SR_UPDATE_INDUSTRIES = "UPDATE_INDUSTRIES";
export const SR_DOWNLOAD_FINANCIALS = "DOWNLOAD_FINANCIALS"
export const SR_SIGNUP_BTN_CLICK_HEADER = "SIGNUP_BTN_CLICK_HEADER";
export const SR_SIGNUP_BTN_CLICK_LOGIN_MODAL = "SIGNUP_BTN_CLICK_LOGIN_MODAL";
export const SR_SIGNUP_BTN_CLICK_CNT_DOT = "SIGNUP_BTN_CLICK_CNT_DOT";
export const SR_SIGNUP_BTN_CLICK_MBL_MENU = "SIGNUP_BTN_CLICK_MBL_MENU";
export const SR_SUBMIT_INTEREST_BTN_CLICK = "SR_SUBMIT_INTEREST_BTN_CLICK";
export const SR_RESTRICTED_FILTER_ACCESS = "SR_RESTRICTED_FILTER_ACCESS";
export const SR_COMPANY_PROFILE_UPGRADE_PLAN = "SR_COMPANY_PROFILE_UPGRADE_PLAN";

export const CR_SUCC_PAYMENT_RESPONSE_MODAL = "Click below to download now, or you can access it at any time using Order History from your ACCOUNT."
export const SUCC_PAYMENT_RESPONSE_MODAL = "You’re now subscribed to Pomanda Plus, click below to begin exploring millions of U.K. businesses & Directors."
export const SUCC_PAYMENT_BUSINESS_ONE_DAY_RESPONSE_MODAL = "You’re now subscribed to Pomanda Day Pass, click below to begin exploring millions of U.K. businesses & Directors."
export const SUCC_PAYMENT_BUSINESS_RESPONSE_MODAL = "You’re now subscribed to Pomanda Business, click below to begin exploring millions of U.K. businesses & Directors."
export const SUCC_PAYMENT_SALES_RESPONSE_MODAL = "You’re now subscribed to Pomanda Sales, click below to begin exploring millions of U.K. businesses & Directors."
export const SUCC_PAYMENT_ADVISORS_RESPONSE_MODAL = "You’re now subscribed to Pomanda Advisors, click below to begin exploring millions of U.K. businesses & Directors."
export const SUCC_PAYMENT_INVESTORS_RESPONSE_MODAL = "You’re now subscribed to Pomanda Investors, click below to begin exploring millions of U.K. businesses & Directors."
export const FAIL_PAYMENT_RESPONSE_MODAL = "Your payment was unsuccessful, please click below to try again."
export const DOWNLOAD_REPORT = "Download Report";
export const DOWNLOAD_REPORT_MSG = "Your report will open in new tab, this may take a minute, you can continue to use Pomanda in meantime.";
export const DOWNLOAD_REPORT_READY_MSG = "Credit report is ready for download.";
export const DOWNLOAD_COMPANY_REPORT_READY_MSG = "Company Report is ready for download."
export const DOWNLOAD_REPORT_ERROR = "Download Report Error";
export const DOWNLOAD_REPORT_ERROR_MSG = "There was a problem with your credit report. We are looking into it and will get back to you shortly.";
export const DOWNLOAD_COMPANY_REPORT_ERROR_MSG = "There was a problem with your company report. We are looking into it and will get back to you shortly."
export const SR_TRY_POMANDA_PLUS = "SR_TRY_POMANDA_PLUS";
export const ET_SUBSCRIPTION_CANCEL_SUCCESS = "ET_SUBSCRIPTION_CANCEL_SUCCESS";
export const ET_SUBSCRIPTION_CANCEL_FAILED = "ET_SUBSCRIPTION_CANCEL_FAILED";
export const SR_ONE_TIME_PURCHASE_COMPANY_REPORT = "SR_ONE_TIME_PURCHASE_COMPANY_REPORT";
export const CP_SUCCESS_PAYMENT_RESPONSE_MODAL = "You can access purchased reports from the ACCOUNT > Order History section";
export const SR_CREDIT_REPORT_PKG_PURCHASE = "SR_CREDIT_REPORT_PKG_PURCHASE";
export const CR_PKG_SUCCESS_PAYMENT_RESPONSE_MODAL = "You have successfully paid for a credit report package.";
export const CDC_PKG_SUCCESS_PAYMENT_RESPONSE_MODAL = "You have successfully paid for a contact details credit package";
export const SR_VALUATION_REPORT_PKG_PURCHASE = "SR_VALUATION_REPORT_PKG_PURCHASE";
export const SR_CONTACT_DETAILS_CREDIT_PKG_PURCHASE = "SR_CONTACT_DETAILS_CREDIT_PKG_PURCHASE"
export const VR_PKG_SUCCESS_PAYMENT_RESPONSE_MODAL = "You have successfully paid for a valuation report package.";
export const BUSINESS_PLAN_ONBOARD_PKG_PURCHASE = "BUSINESS_PLAN_ONBOARD_PKG_PURCHASE"
export const BUSINESS_PLAN_ONBOARD_ONE_PKG_PURCHASE = "BUSINESS_PLAN_ONBOARD_ONE_PKG_PURCHASE"
export const BUSINESS_PLAN_ONBOARD_PKG_PURCHASE_CREDIT = "BUSINESS_PLAN_ONBOARD_PKG_PURCHASE_CREDIT"
export const BUSINESS_ONBOARD_PKG_PAYMENT_SUCCESS_RESPONSE = "You have successfully paid for a business plan.";
export const SR_EXTRA_CSV_CREDITS_PKG_PURCHASE = "SR_EXTRA_CSV_CREDITS_PKG_PURCHASE";
export const SR_POMANDA_PLUS_5K_PKG_PURCHASE = "SR_POMANDA_PLUS_5K_PKG_PURCHASE";
export const EXTRA_CSV_CREDITS_PKG_PURCHASE_SUCCESS_MSG = "You have successfully paid for Extra CSV credits.";
export const VR_ONE_TIME_ONE_PURCHASE_REPORT = "oneTime1ValuationReportPkg";
export const VALUATION_REPORT_PKG = "VALUATION_REPORT_PKG"
export const PLUS_USER_COMPETITOR_LIMIT = 5;
export const PRO_USER_COMPETITOR_LIMIT = Infinity
