export const LOGIN_EVENT_PAGE = "login_page";
export const LOGIN_TOGGLE_ON_EVENT_TYPE = "login_modal_open";
export const LOGIN_TOGGLE_OFF_EVENT_TYPE = "login_modal_close";
export const SIGNUP_EVENT_PAGE = "signup_page";
// export const EP_UNKNOWN_EMAIL_CAPTURE_MODEL = "EP_UNKNOWN_EMAIL_CAPTURE_MODEL";
export const SIGNUP_TOGGLE_ON_EVENT_TYPE = "signup_modal_open";
export const SIGNUP_TOGGLE_OFF_EVENT_TYPE = "signup_modal_close";
// export const ET_UNKNOW_EMAIL_MODAL_OPEN  = "ET_UNKNOW_EMAIL_MODAL_OPEN";
// export const ET_UNKNOW_EMAIL_MODAL_CLOSE = "ET_UNKNOW_EMAIL_MODAL_CLOSE";
export const LOGIN_ERROR_EVENT_TYPE = "login_error";
export const SIGNUP_ERROR_EVENT_TYPE = "signup_error";
export const SIGNUP_SUCCESS_EVENT_TYPE = "signup_success";
export const LOGIN_SUCCESS_EVENT_TYPE = "login_success";
export const FORGOT_PASSWORD_EVENT_PAGE = "forgot_password_modal";
export const FORGOT_PASSWORD_ON_EVENT_TYPE = "forgot_modal_open";
export const FORGOT_PASSWORD_OFF_EVENT_TYPE = "forgot_modal_close";
export const TOGGLE_SELF_CLICK = "self_click";
export const TOGGLE_AUTO_CLICK = "auto_click";
export const FORGOT_PASSWORD_EMAIL_SUBMIT = "forgot_password_email_submit";
export const FORGOT_PASSWORD_EMAIL_SUBMIT_ERROR = "forgot_password_email_submit_error";
export const FORGOT_PASSWORD_RESPONSE_MODAL_EVENT_PAGE = "forgot_password_response_modal";
export const FORGOT_PASSWORD_EMAIL_SUBMIT_SUCCESS = "forgot_password_email_submit_success";
export const RESET_PASSWORD_EVENT_PAGE = "reset_password_modal";
export const RESET_PASSWORD_ON_EVENT_TYPE = "reset_modal_open";
export const RESET_PASSWORD_OFF_EVENT_TYPE = "reset_modal_close";
export const RESET_PASSWORD_LINK_VALIDITY_CHECK = "reset_password_link_validity_check";
export const RESET_PASSWORD_LINK_INVALID = "reset_password_link_invalid";
export const RESET_PASSWORD_REQUEST_TRACKING = "reset_password_request";
export const RESET_PASSWORD_ERROR = "reset_password_error";
export const EP_NEW_LOGIN_MODAL = "EP_NEW_LOGIN_MODAL";
export const ET_NEW_LOGIN_MODAL_OPEN = "ET_NEW_LOGIN_MODAL_OPEN";
export const ET_NEW_LOGIN_MODAL_CLOSE = "ET_NEW_LOGIN_MODAL_CLOSE";
export const EP_NEW_SIGNUP_MODAL = "EP_NEW_SIGNUP_MODAL";
export const ET_NEW_SIGNUP_MODAL_OPEN = "ET_NEW_SIGNUP_MODAL_OPEN";
export const ET_NEW_SIGNUP_MODAL_CLOSE = "ET_NEW_SIGNUP_MODAL_CLOSE";

export const EP_POWER_SEARCH_COMPANY = "EP_POWER_SEARCH_COMPANY";
export const ET_POWER_SEARCH_COMPANY_ONLOAD = "ET_POWER_SEARCH_COMPANY_ONLOAD";
export const ET_POWER_SEARCH_ESTIMATION_TOGGLE_TOP = "ET_POWER_SEARCH_ESTIMATION_TOGGLE_TOP";
export const POWER_SEARCH_ESTIMATION_TOGGLE = "POWER_SEARCH_ESTIMATION_TOGGLE";
export const ET_POWER_SEARCH_COMPANY_SORT_COLUMN = "ET_POWER_SEARCH_COMPANY_SORT_COLUMN";
export const POWER_SEARCH_COMPANY_TABLE_SORT = "POWER_SEARCH_COMPANY_TABLE_SORT";
export const ET_POWER_SEARCH_COMPANY_PROFILE_CLICK = "ET_POWER_SEARCH_COMPANY_PROFILE_CLICK";
export const POWER_SEARCH_COMPANY_PROFILE_CLICK = "POWER_SEARCH_COMPANY_PROFILE_CLICK";
export const ET_POWER_SEARCH_TOGGLE_SEARCH = "ET_POWER_SEARCH_TOGGLE_SEARCH";
export const POWER_SEARCH_HEADER_TOGGLE = "POWER_SEARCH_HEADER_TOGGLE";
export const EP_POWER_SEARCH_DIRECTOR = "EP_POWER_SEARCH_DIRECTOR";
export const ET_POWER_SEARCH_DIRECTOR_ONLOAD = "ET_POWER_SEARCH_DIRECTOR_ONLOAD";
export const ET_POWER_SEARCH_DIRECTOR_SEARCH = "ET_POWER_SEARCH_DIRECTOR_SEARCH";
export const ET_POWER_SEARCH_DIRECTOR_SORT_COLUMN = "ET_POWER_SEARCH_DIRECTOR_SORT_COLUMN";
export const POWER_SEARCH_DIRECTOR_TABLE_SORT = "POWER_SEARCH_DIRECTOR_TABLE_SORT";
export const ET_POWER_SEARCH_DIRECTOR_PROFILE_CLICK = "ET_POWER_SEARCH_DIRECTOR_PROFILE_CLICK";
export const POWER_SEARCH_DIRECTOR_PROFILE_CLICK = "POWER_SEARCH_DIRECTOR_PROFILE_CLICK";
export const ET_POWER_SEARCH_COMPANY_FILTER_APPLIED = "ET_POWER_SEARCH_COMPANY_FILTER_APPLIED";
export const ET_POWER_SEARCH_DIRECTOR_FILTER_APPLIED = "ET_POWER_SEARCH_DIRECTOR_FILTER_APPLIED";
export const ET_POWER_SEARCH_INDUSTRY_FILTER_APPLIED = "ET_POWER_SEARCH_INDUSTRY_FILTER_APPLIED";
export const ET_POWER_SEARCH_SHAREHOLDER_FILTER_APPLIED = "ET_POWER_SEARCH_SHAREHOLDER_FILTER_APPLIED";
export const ET_POWER_SEARCH_CHARGES_FILTER_APPLIED = "ET_POWER_SEARCH_CHARGES_FILTER_APPLIED";
export const ET_POWER_SEARCH_REGION_FILTER_APPLIED = "ET_POWER_SEARCH_REGION_FILTER_APPLIED";
export const POWER_SEARCH_FILTER_SECTION = "POWER_SEARCH_FILTER_SECTION";
export const ET_POWER_SEARCH_SHOW_HIDE_FILTER = "ET_POWER_SEARCH_SHOW_HIDE_FILTER";
export const POWER_SEARCH_HEADER_SHOW_HIDE_BUTTON = "POWER_SEARCH_HEADER_SHOW_HIDE_BUTTON";
export const ET_POWER_SEARCH_SEE_HIDE_APPLIED_FILTER = "ET_POWER_SEARCH_SEE_HIDE_APPLIED_FILTER";
export const POWER_SEARCH_SEE_HIDE_APPLIED_FILTER_BUTTON = "POWER_SEARCH_SEE_HIDE_APPLIED_FILTER_BUTTON";
export const ET_POWER_SEARCH_REMOVE_APPLIED_FILTER = "ET_POWER_SEARCH_REMOVE_APPLIED_FILTER";
export const POWER_SEARCH_APPLIED_FILTER_REMOVE_BUTTON = "POWER_SEARCH_APPLIED_FILTER_REMOVE_BUTTON";
export const ET_POWER_SEARCH_APPLIED_FILTER_FIELD_CLICK = "ET_POWER_SEARCH_APPLIED_FILTER_FIELD_CLICK";
export const POWER_SEARCH_APPLIED_FILTER_FIELD = "POWER_SEARCH_APPLIED_FILTER_FIELD";
export const ET_USER_VISIT_FROM_SHARED_URL = "ET_USER_VISIT_FROM_SHARED_URL";
export const POWER_SEARCH_FILTER_CLOSE_BUTTON = "POWER_SEARCH_FILTER_CLOSE_BUTTON";
export const POWER_SEARCH_FILTER_SHOW_RESULT = "POWER_SEARCH_FILTER_SHOW_RESULT";
export const ET_POWER_SEARCH_HEADER_SORT_BUTTON_CLICK = "ET_POWER_SEARCH_HEADER_SORT_BUTTON_CLICK";
export const EP_POWER_SEARCH_INDUSTRY = "EP_POWER_SEARCH_INDUSTRY";
export const EP_POWER_SEARCH_SHAREHOLDER = "EP_POWER_SEARCH_SHAREHOLDER";
export const EP_POWER_SEARCH_CHARGES = "EP_POWER_SEARCH_CHARGES";
export const EP_POWER_SEARCH_REGION = "EP_POWER_SEARCH_REGION";
export const ET_POWER_SEARCH_INDUSTRY_PROFILE_CLICK = "ET_POWER_SEARCH_INDUSTRY_PROFILE_CLICK";
export const POWER_SEARCH_INDUSTRY_PROFILE_CLICK = "POWER_SEARCH_INDUSTRY_PROFILE_CLICK";

export const ET_POWER_SEARCH_SHAREHOLDER_PROFILE_CLICK = "ET_POWER_SEARCH_SHAREHOLDER_PROFILE_CLICK";
export const POWER_SEARCH_SHAREHOLDER_PROFILE_CLICK = "POWER_SEARCH_SHAREHOLDER_PROFILE_CLICK";
export const ET_POWER_SEARCH_CHARGES_PROFILE_CLICK = "ET_POWER_SEARCH_CHARGES_PROFILE_CLICK";
export const POWER_SEARCH_CHARGES_PROFILE_CLICK = "POWER_SEARCH_CHARGES_PROFILE_CLICK";
export const ET_POWER_SEARCH_REGION_PROFILE_CLICK = "ET_POWER_SEARCH_REGION_PROFILE_CLICK";
export const POWER_SEARCH_REGION_PROFILE_CLICK = "POWER_SEARCH_REGION_PROFILE_CLICK";
export const ET_POWER_SEARCH_INDUSTRY_SORT_COLUMN = "ET_POWER_SEARCH_INDUSTRY_SORT_COLUMN";
export const POWER_SEARCH_INDUSTRY_TABLE_SORT = "POWER_SEARCH_INDUSTRY_TABLE_SORT";
export const ET_POWER_SEARCH_SHAREHOLDER_SORT_COLUMN = "ET_POWER_SEARCH_SHAREHOLDER_SORT_COLUMN";
export const POWER_SEARCH_SHAREHOLDER_TABLE_SORT = "POWER_SEARCH_SHAREHOLDER_TABLE_SORT";
export const ET_POWER_SEARCH_CHARGES_SORT_COLUMN = "ET_POWER_SEARCH_CHARGES_SORT_COLUMN";
export const POWER_SEARCH_CHARGES_TABLE_SORT = "POWER_SEARCH_CHARGES_TABLE_SORT";
export const ET_POWER_SEARCH_REGION_SORT_COLUMN = "ET_POWER_SEARCH_REGION_SORT_COLUMN";
export const POWER_SEARCH_REGION_TABLE_SORT = "POWER_SEARCH_REGION_TABLE_SORT";
export const ET_POWER_SEARCH_INDUSTRY_ONLOAD = "ET_POWER_SEARCH_INDUSTRY_ONLOAD";
export const ET_POWER_SEARCH_SHAREHOLDER_ONLOAD = "ET_POWER_SEARCH_SHAREHOLDER_ONLOAD";
export const ET_POWER_SEARCH_CHARGES_ONLOAD = "ET_POWER_SEARCH_CHARGES_ONLOAD";
export const ET_POWER_SEARCH_REGION_ONLOAD = "ET_POWER_SEARCH_REGION_ONLOAD";

//HOMEPAGE - POWER SEARCH
export const HOMEPAGE_HEADER_SEARCH_BUTTON = "HOMEPAGE_HEADER_SEARCH_BUTTON";
export const EP_HOMEPAGE = "EP_HOMEPAGE";
export const ET_HOMEPAGE_ONLOAD = "ET_HOMEPAGE_ONLOAD";
export const ET_HOMEPAGE_SINGLE_SEARCH = "ET_HOMEPAGE_SINGLE_SEARCH";
export const HOMEPAGE_SEARCHBAR_SEARCH = "HOMEPAGE_SEARCHBAR_SEARCH";
export const HOMEPAGE_SEARCHBAR_OPTION = "HOMEPAGE_SEARCHBAR_OPTION";
export const HOMEPAGE_SEARCHBAR_ICON = "HOMEPAGE_SEARCHBAR_ICON";
export const HOMEPAGE_SEARCHBAR_ENTER = "HOMEPAGE_SEARCHBAR_ENTER";
export const HEADER_SEARCH_BUTTON = "HEADER_SEARCH_BUTTON";
export const HOMEPAGE_FOOTER_SEARCH_BUTTON = "HOMEPAGE_FOOTER_SEARCH_BUTTON";
export const ET_POWER_SEARCH_TABLE_SCROLL = "ET_POWER_SEARCH_TABLE_SCROLL";
export const ET_TRY_MODAL_OPTION_CLICK = "ET_TRY_MODAL_OPTION_CLICK";

export const ET_SINGLE_SEARCH_COMPANY_COUNT_CLICK = "ET_SINGLE_SEARCH_COMPANY_COUNT_CLICK";
export const ET_SINGLE_SEARCH_DIRECTOR_COUNT_CLICK = "ET_SINGLE_SEARCH_DIRECTOR_COUNT_CLICK";
export const ET_SINGLE_SEARCH_INDUSTRY_COUNT_CLICK = "ET_SINGLE_SEARCH_INDUSTRY_COUNT_CLICK";
export const ET_SINGLE_SEARCH_SHAREHOLDER_COUNT_CLICK = "ET_SINGLE_SEARCH_SHAREHOLDER_COUNT_CLICK";
export const ET_SINGLE_SEARCH_CHARGES_COUNT_CLICK = "ET_SINGLE_SEARCH_CHARGES_COUNT_CLICK";
export const ET_SINGLE_SEARCH_REGION_COUNT_CLICK = "ET_SINGLE_SEARCH_REGION_COUNT_CLICK";
export const ET_SINGLE_SEARCH_COMPANY_CLICK = "ET_SINGLE_SEARCH_COMPANY_CLICK";
export const ET_SINGLE_SEARCH_DIRECTOR_CLICK = "ET_SINGLE_SEARCH_DIRECTOR_CLICK";
export const ET_SINGLE_SEARCH_INDUSTRY_CLICK = "ET_SINGLE_SEARCH_INDUSTRY_CLICK";
export const ET_SINGLE_SEARCH_SHAREHOLDER_CLICK = "ET_SINGLE_SEARCH_SHAREHOLDER_CLICK";
export const ET_SINGLE_SEARCH_CHARGES_CLICK = "ET_SINGLE_SEARCH_CHARGES_CLICK";
export const ET_SINGLE_SEARCH_REGION_CLICK = "ET_SINGLE_SEARCH_REGION_CLICK";
export const ET_SINGLE_SEARCH_TRY_OPTION_CLICK = "ET_SINGLE_SEARCH_TRY_OPTION_CLICK";
export const ET_SINGLE_SEARCH_NO_RESULT_CLICK = "ET_SINGLE_SEARCH_NO_RESULT_CLICK";

export const ET_MODAL_OPEN = "modal_open";
export const ET_MODAL_CLOSE = "modal_close";
export const EP_SUBSCRIPTION = "subscription";
export const ET_SUBSCRIPTION_BUTTON_CLICK = "subscription_button_click";
export const ET_SESSION_CREATION_SUCCESSFUL = "ET_SESSION_CREATION_SUCCESSFUL";
export const ET_SESSION_CREATION_FAILED = "ET_SESSION_CREATION_FAILED";
export const ET_PAYMENT_SUCCESS = "ET_PAYMENT_SUCCESS";
export const ET_PAYMENT_FAILURE = "ET_PAYMENT_FAILURE";
export const ET_STATUS_UPDATION_SUCCESSFUL = "ET_STATUS_UPDATION_SUCCESSFUL";
export const ET_STATUS_UPDATION_FAILED = "ET_STATUS_UPDATION_FAILED";
export const COMPANY_PROFILE = "company_profile";
export const CH_FILINGS_CLICK = "CH_filings_click";
export const ET_CH_FILINGS_REPORT_LOGIN_MODAL = "ET_CH_FILINGS_REPORT_LOGIN_MODAL";
export const SIMILAR_COMPANY_MODAL_OPEN = "similar_company_modal_open";
export const SIMILAR_COMPANY_PAGINATION = "similar_company_pagination";
export const SIMILAR_COMPANYNAME_CLICK = "similar_companyname_click";
export const ET_SAMPLE_REPORT_CLICK = "sample_report_click";
export const ET_CREDIT_REPORT_CLICK = "credit_report_click";
export const ET_BUY_CREDIT_REPORT_CLICK = "buy_credit_report_click";
export const ET_SHOW_CREDIT_PLAN_BTN_CLICK = "ET_SHOW_CREDIT_PLAN_BTN_CLICK";
export const ET_VALUATION_CREDIT_UPDATE_SUCCESS = "ET_VALUATION_CREDIT_UPDATE_SUCCESS";
export const ET_VALUATION_CREDIT_UPDATE_FAILURE = "ET_VALUATION_CREDIT_UPDATE_FAILURE";
export const ET_FREE_SEARCH_EXPIRE_SIGN_IN_LINK_CLICK = "ET_FREE_SEARCH_EXPIRE_SIGN_IN_LINK_CLICK";
export const CHECK_FREE_SEARCH_EXPIRATION_AND_REDIRECT = "CHECK_FREE_SEARCH_EXPIRATION_AND_REDIRECT";
export const SHOW_DAILY_SEARCH_EXPIRE_MODAL = "SHOW_DAILY_SEARCH_EXPIRE_MODAL"
export const ET_BUSINESS_PLAN_BTN_CLICK = "ET_BUSINESS_PLAN_BTN_CLICK"

export const EP_ACCOUNT = "EP_ACCOUNT";
export const EP_SOLUTIONS = "EP_SOLUTIONS";
export const ET_ACCOUNT_ONLOAD = "ET_ACCOUNT_ONLOAD";
export const ET_SOLUTIONS_PAGE_ONLOAD = "ET_SOLUTIONS_PAGE_ONLOAD";
export const HEADER_ACCOUNT_BUTTON = "HEADER_ACCOUNT_BUTTON";
export const HEADER_WHAT_WE_DO_BUTTON = "HEADER_WHAT_WE_DO_BUTTON";
export const HEADER_LEAD_GEN = "HEADER_LEAD_GEN";
export const FOOTER_ACCOUNT_BUTTON = "FOOTER_ACCOUNT_BUTTON";
export const ET_CONTACT_US_SUBMIT_FAILED = "ET_CONTACT_US_SUBMIT_FAILED";
export const ET_CONTACT_US_SUBMIT_SUCCESSFUL = "ET_CONTACT_US_SUBMIT_SUCCESSFUL";
export const ET_CONTACT_US_CLICK = "ET_CONTACT_US_CLICK";
export const ET_SUBSCRIPTION_ASSISTANCE_REQUEST_BUTTON_CLICK = "ET_SUBSCRIPTION_ASSISTANCE_REQUEST_BUTTON_CLICK";
export const FOOTER_CONTACT_US_LINK = "FOOTER_CONTACT_US_LINK";

//popular company page
export const EP_POPULAR_COMPANIES = "EP_POPULAR_COMPANIES";
export const ET_POPULAR_COMPANIES_ONLOAD = "ET_POPULAR_COMPANIES_ONLOAD";
export const FOOTER_POPULAR_COMPANIES_BUTTON = "FOOTER_POPULAR_COMPANIES_BUTTON";
export const ET_POPULAR_COMPANIES_CLICK = "ET_POPULAR_COMPANIES_CLICK";
export const ET_POPULAR_COMPANIES_PAGINATION_CLICK = "ET_POPULAR_COMPANIES_PAGINATION_CLICK";
export const ET_POPULAR_COMPANIES_RESULT_CLICK = "ET_POPULAR_COMPANIES_RESULT_CLICK";
export const ET_POPULAR_COMPANIES_NUMBER_PAGINATION_CLICK = "ET_POPULAR_COMPANIES_NUMBER_PAGINATION_CLICK";

//footer 
export const EP_FOOTER = "EP_FOOTER";
export const ET_PRIVACY_POLICY_CLICK = "ET_PRIVACY_POLICY_CLICK";
export const FOOTER_PRIVACY_POLICY_LINK = "FOOTER_PRIVACY_POLICY_LINK";
export const ET_TERMS_OF_USE_CLICK = "ET_TERMS_OF_USE_CLICK";
export const FOOTER_TERMS_LINK = "FOOTER_TERMS_LINK";
export const EP_USER_FIRST_VISIT = "USER_FIRST_VISIT";
export const ET_TRACKER_ID_CREATION = "TRACKER_ID_CREATION";
export const ET_INSTANT_CREDIT_REPORT_CLICK = "ET_INSTANT_CREDIT_REPORT_CLICK";
export const FOOTER_INSTANT_CREDIT_REPORT_BUTTON = "FOOTER_INSTANT_CREDIT_REPORT_BUTTON";
export const ET_INDUSTRY_INSIGHTS_CLICK = "ET_INDUSTRY_INSIGHTS_CLICK";
export const FOOTER_INDUSTRY_INSIGHTS_BUTTON = "FOOTER_INDUSTRY_INSIGHTS_BUTTON";
export const ET_COMPANY_HEALTHCHECK_CLICK = "ET_COMPANY_HEALTHCHECK_CLICK";
export const FOOTER_COMPANY_HEALTHCHECK_BUTTON = "FOOTER_COMPANY_HEALTHCHECK_BUTTON";
export const ET_BLOG_CLICK = "ET_BLOG_CLICK";
export const ET_POMANDA_API_SERVICE_CLICK = "ET_POMANDA_API_SERVICE_CLICK"
export const FOOTER_BLOG_BUTTON = "FOOTER_BLOG_BUTTON";
export const ET_FAQ_CLICK = "ET_FAQ_CLICK";
export const EP_FAQ = "EP_FAQ";
export const ET_FAQ_ONLOAD = "ET_FAQ_ONLOAD";
export const FOOTER_FAQ_BUTTON = "FOOTER_FAQ_BUTTON";
export const ET_TWITTER_CLICK = "ET_TWITTER_CLICK";
export const FOOTER_TWITTER_BUTTON = "FOOTER_TWITTER_BUTTON";
export const ET_LINKEDIN_CLICK = "ET_LINKEDIN_CLICK";
export const FOOTER_LINKEDIN_BUTTON = "FOOTER_LINKEDIN_BUTTON";
export const ET_COOKIE_POLICY_CLICK = "ET_COOKIE_POLICY_CLICK";
export const FOOTER_COOKIE_POLICY_BUTTON = "FOOTER_COOKIE_POLICY_BUTTON";
export const ET_ACCEPTABLE_USE_CLICK = "ET_ACCEPTABLE_USE_CLICK";
export const FOOTER_ACCEPTABLE_USE_BUTTON = "FOOTER_ACCEPTABLE_USE_BUTTON";
export const ET_FAIR_USAGE_POLICY_CLICK = "ET_FAIR_USAGE_POLICY_CLICK";
export const FOOTER_FAIR_USAGE_POLICY_BUTTON = "FOOTER_FAIR_USAGE_POLICY_BUTTON";
export const ET_ABOUT_US_CLICK = "ET_ABOUT_US_CLICK";
export const FOOTER_ABOUT_US_BUTTON = "FOOTER_ABOUT_US_BUTTON";
export const FOOTER_CONTACT_US_BUTTON = "FOOTER_CONTACT_US_BUTTON";
export const ET_BUSINESS_VALUATION_CLICK = "ET_BUSINESS_VALUATION_CLICK";
export const FOOTER_BUSINESS_VALUATION_BUTTON = "FOOTER_BUSINESS_VALUATION_BUTTON"

//abfpage tracking
export const EP_ABFPAGE = "EP_ABFPAGE";
export const ET_ABFPAGE_LOAD = "ET_ABFPAGE_LOAD";
export const ABFPAGE_COMPANY_INFO_SEARCHBAR_ENTER = "ABFPAGE_COMPANY_INFO_SEARCHBAR_ENTER";
export const ET_ABFPAGE_COMPANY_SEARCH = "ET_ABFPAGE_COMPANY_SEARCH";
export const ABFPAGE_COMPANY_INFO_SEARCHBAR_SEARCH = "ABFPAGE_COMPANY_INFO_SEARCHBAR_SEARCH";
export const ET_ABFPAGE_COMPANY_PROFILE_CLICK = "ET_ABFPAGE_COMPANY_PROFILE_CLICK";
export const ABFPAGE_COMPANY_INFO_SEARCHBAR_OPTION = "ABFPAGE_COMPANY_INFO_SEARCHBAR_OPTION";
export const ABFPAGE_COMPANY_INFO_SEARCHBAR_ICON = "ABFPAGE_COMPANY_INFO_SEARCHBAR_ICON";

//Blog page
export const EP_BLOG = "EP_BLOG";
export const ET_BLOG_LIST_PAGE_ONLOAD = "ET_BLOG_LIST_PAGE_ONLOAD";
export const ET_BLOG_DETAIL_PAGE_ONLOAD = "ET_BLOG_DETAIL_PAGE_ONLOAD";
export const ET_BLOG_DETAIL_PAGE_LIST_CLICK = "ET_BLOG_DETAIL_PAGE_LIST_CLICK";

//company house page tracking
export const EP_COMPANY_HOUSE_PAGE = "EP_COMPANY_HOUSE_PAGE";
export const ET_COMPANY_HOUSE_PAGE_LOAD = "ET_COMPANY_HOUSE_PAGE_LOAD";
export const ET_COMPANY_HOUSE_PAGE_COMPANY_SEARCH = "ET_COMPANY_HOUSE_PAGE_COMPANY_SEARCH";
export const COMPANY_HOUSE_PAGE_HEADER_SEARCH_BUTTON = "COMPANY_HOUSE_PAGE_HEADER_SEARCH_BUTTON";
export const COMPANY_HOUSE_PAGE_SEARCHBAR_ENTER = "COMPANY_HOUSE_PAGE_SEARCHBAR_ENTER";
export const COMPANY_HOUSE_PAGE_SEARCHBAR_ICON = "COMPANY_HOUSE_PAGE_SEARCHBAR_ICON";
export const COMPANY_HOUSE_PAGE_DIRECTOR_SEARCH_BUTTON = "COMPANY_HOUSE_PAGE_DIRECTOR_SEARCH_BUTTON";
export const COMPANY_HOUSE_PAGE_POWER_SEARCH_BUTTON = "COMPANY_HOUSE_PAGE_POWER_SEARCH_BUTTON";
export const COMPANY_HOUSE_PAGE_SEARCH_MODAL_SEARCHBAR_ICON = "COMPANY_HOUSE_PAGE_SEARCH_MODAL_SEARCHBAR_ICON";
export const COMPANY_HOUSE_PAGE_SEARCH_MODAL_SEARCHBAR_ENTER = "COMPANY_HOUSE_PAGE_SEARCH_MODAL_SEARCHBAR_ENTER";
export const ET_COMPANY_HOUSE_PAGE_COMPANY_PROFILE_CLICK = "ET_COMPANY_HOUSE_PAGE_COMPANY_PROFILE_CLICK";
export const COMPANY_HOUSE_PAGE_HEADER_ACCOUNT_BTN = "COMPANY_HOUSE_PAGE_HEADER_ACCOUNT_BTN";
export const EP_DIRECTOR_PROFILE = "director_profile";
export const ET_COMPANY_REPORT_PDF_REDIRECT = "ET_COMPANY_REPORT_PDF_REDIRECT";
export const ET_DIRECTOR_REPORT_PDF_REDIRECT = "ET_DIRECTOR_REPORT_PDF_REDIRECT";
export const EP_PDF_REPORT_DOWNLOAD = "EP_PDF_REPORT_DOWNLOAD";
export const NO_RESULT_FILTER_BUTTON = "NO_RESULT_FILTER_BUTTON";
export const ET_COMPANY_REPORT_LOGIN_MODAL = "ET_COMPANY_REPORT_LOGIN_MODAL";
export const ET_DIRECTOR_REPORT_LOGIN_MODAL = "ET_DIRECTOR_REPORT_LOGIN_MODAL";
export const ET_SHARE_BUTTON_CLICK = "ET_SHARE_BUTTON_CLICK";
export const ET_SHARE_REPORT_URL_BTN_CLICK = "ET_SHARE_REPORT_URL_BTN_CLICK";
export const ET_SOCIAL_MEDIA_BUTTON_CLICK = "ET_SOCIAL_MEDIA_BUTTON_CLICK";
export const LOGIN_TOGGLE_FOR_ANONYMOUS_USER = "LOGIN_TOGGLE_FOR_ANONYMOUS_USER";
export const ET_SHARE_PAGE_URL_BTN_CLICK = "ET_SHARE_PAGE_URL_BTN_CLICK";
export const ET_COMPANY_CHARGES_LOGIN_MODAL = "ET_COMPANY_CHARGES_LOGIN_MODAL";
export const ET_COMPANY_REPORT_BUY_BTN_CLICK = "ET_COMPANY_REPORT_BUY_BTN_CLICK";
export const TOGGLE_HEALTH_CHECK_MODAL_ON = "TOGGLE_HEALTH_CHECK_MODAL_ON";

// Landing pages 
export const EP_COMPANY_HOUSE_LANDING_PAGE_1 = "EP_COMPANY_HOUSE_LANDING_PAGE_1";
export const EP_COMPANY_HOUSE_LANDING_PAGE_2 = "EP_COMPANY_HOUSE_LANDING_PAGE_2";
export const EP_COMPANY_HOUSE_LANDING_PAGE_3 = "EP_COMPANY_HOUSE_LANDING_PAGE_3";
export const EP_COMPANY_HOUSE_LANDING_PAGE_4 = "EP_COMPANY_HOUSE_LANDING_PAGE_4";
export const EP_LEAD_GENERATION_LANDING_PAGE_1 = "EP_LEAD_GENERATION_LANDING_PAGE_1";
export const EP_LEAD_GENERATION_LANDING_PAGE_2 = "EP_LEAD_GENERATION_LANDING_PAGE_2";
export const EP_LEAD_GENERATION_LANDING_PAGE_3 = "EP_LEAD_GENERATION_LANDING_PAGE_3";
export const EP_LEAD_GENERATION_LANDING_PAGE_4 = "EP_LEAD_GENERATION_LANDING_PAGE_4";
export const ET_LANDING_PAGE_ONLOAD = "ET_LANDING_PAGE_ONLOAD";
export const ET_SCROLL_BUTTON_CLICK = "ET_SCROLL_BUTTON_CLICK";
export const ET_RENDER_BUTTON_CLICK = "ET_RENDER_BUTTON_CLICK";
export const ET_SIGNUP_SUCCESS_FROM_LANDING_PAGE = "ET_SIGNUP_SUCCESS_FROM_LANDING_PAGE";
export const ET_LANDING_PAGE_SIGNUP_BUTTON_CLICK = "ET_LANDING_PAGE_SIGNUP_BUTTON_CLICK";

//submit interest
export const ET_SUBMIT_INVESTMENT_INTEREST_BTN = "ET_SUBMIT_INVESTMENT_INTEREST_BTN";
export const ET_SUBMIT_INVESTMENT_INTEREST_LOGIN_MODAL = "ET_SUBMIT_INVESTMENT_INTEREST_LOGIN_MODAL";
export const EP_SUBMIT_INVESTMENT_INTEREST_MODAL = "EP_SUBMIT_INVESTMENT_INTEREST_MODAL";
export const ET_SUBMIT_INVESTMENT_INTEREST_SUBMIT_BTN = "ET_SUBMIT_INVESTMENT_INTEREST_SUBMIT_BTN";
export const ET_SUBMIT_INVESTMENT_INTEREST_MODAL_ONLOAD = "ET_SUBMIT_INVESTMENT_INTEREST_MODAL_ONLOAD"

//download csv
export const ET_DOWNLOAD_RESULTS_BUTTON = "ET_DOWNLOAD_RESULTS_BUTTON";
export const ET_DOWNLOAD_RESULT_CONFIRMATION_MODAL_OPEN = "ET_DOWNLOAD_RESULT_CONFIRMATION_MODAL_OPEN";
export const ET_DOWNLOAD_RESULT_CONFIRMATION_MODAL_CLOSE = "ET_DOWNLOAD_RESULT_CONFIRMATION_MODAL_CLOSE";
export const ET_DOWNLOAD_RESULT_REQUEST = "ET_DOWNLOAD_RESULT_REQUEST";
export const ET_INCREASE_DOWNLOAD_RESULT_LIMIT_REQUEST = "ET_INCREASE_DOWNLOAD_RESULT_LIMIT_REQUEST";

//save searches
export const ET_SAVE_SEARCHES_BUTTON_CLICK = "ET_SAVE_SEARCHES_BUTTON_CLICK";
export const ET_SAVE_SEARCHES_MODAL_OPEN = "ET_SAVE_SEARCHES_MODAL_OPEN";
export const ET_SAVE_SEARCHES_SUBSCRIPTION_OPEN = "ET_SAVE_SEARCHES_SUBSCRIPTION_OPEN";
export const ET_SAVE_CURRENT_SEARCH_SUBSCRIPTION_OPEN = "ET_SAVE_CURRENT_SEARCH_SUBSCRIPTION_OPEN";
export const ET_SAVED_CURRENT_SEARCH = "ET_SAVED_CURRENT_SEARCH";
export const ET_SAVE_SEARCH_PAGINATION_CLICK = "ET_SAVE_SEARCH_PAGINATION_CLICK";
export const ET_SAVE_SEARCH_SORT_CLICK = "ET_SAVE_SEARCH_SORT_CLICK";
export const ET_SAVE_SEARCH_TYPE_CHANGE = "ET_SAVE_SEARCH_TYPE_CHANGE";
export const ET_SAVE_SEARCH_OPEN = "ET_SAVE_SEARCH_OPEN";
export const ET_SAVE_SEARCH_DELETE_OPTION_CLICK = "ET_SAVE_SEARCH_DELETE_OPTION_CLICK";
export const ET_SAVE_SEARCH_SHARE_OPTION_CLICK = "ET_SAVE_SEARCH_SHARE_OPTION_CLICK";
export const ET_SAVE_SEARCH_DELETE_CONFIRMATION_YES = "ET_SAVE_SEARCH_DELETE_CONFIRMATION_YES";

//update Industry mix
export const ET_UPDATE_INDUSTRY_MIX_MODAL_OPEN = "ET_UPDATE_INDUSTRY_MIX_MODAL_OPEN";
export const ET_UPDATE_INDUSTRY_MIX_SUBSCRIPTION_OPEN = "ET_UPDATE_INDUSTRY_MIX_SUBSCRIPTION_OPEN";
export const ET_SAVE_INDUSTRY_MIX_CHANGES = "ET_SAVE_INDUSTRY_MIX_CHANGES";
export const ET_RESET_INDUSTRY_MIX_CHANGES = "ET_RESET_INDUSTRY_MIX_CHANGES";
export const ET_SEE_ALL_INDUSTRY_BREAKDOWN_MODAL_OPEN = "ET_SEE_ALL_INDUSTRY_BREAKDOWN_MODAL_OPEN";
export const ET_OPEN_INDUSTRY_LIST_FOR_INDUSTRY_MIX = "ET_OPEN_INDUSTRY_LIST_FOR_INDUSTRY_MIX";
export const ET_ADD_INDUSTRIES_TO_BREAKDOWN = "ET_ADD_INDUSTRIES_TO_BREAKDOWN";
export const ET_REMOVE_INDUSTRY_FROM_BREAKDOWN = "ET_REMOVE_INDUSTRY_FROM_BREAKDOWN";
export const ET_CHANGE_INDUSTRY_BREAKDOWN_WEIGHT = "ET_CHANGE_INDUSTRY_BREAKDOWN_WEIGHT";
export const ET_CLOSE_INDUSTRY_MIX_MODAL = "ET_CLOSE_INDUSTRY_MIX_MODAL"

export const ET_ALREADY_SUBSCRIBED_PLEASE_LOGIN_CLICK = "ET_ALREADY_SUBSCRIBED_PLEASE_LOGIN_CLICK";
//Account Page
export const ET_EDIT_PROFILE = "ET_EDIT_PROFILE";
export const ET_EDIT_PROFILE_BUTTON_CLICK = "ET_EDIT_PROFILE_BUTTON_CLICK";
export const ET_EDIT_PROFILE_CANCEL_BUTTON_CLICK = "ET_EDIT_PROFILE_CANCEL_BUTTON_CLICK";
export const ET_SIGNOUT = "ET_SIGNOUT";
export const ET_SAVE_PROFILE_FAILED = "ET_SAVE_PROFILE_FAILED";
export const ET_SAVE_PROFILE_SUCCESSFUL = "ET_SAVE_PROFILE_SUCCESSFUL";
export const ET_CHANGE_PASSWORD_SUCCESSFUL = "ET_CHANGE_PASSWORD_SUCCESSFUL";
export const ET_CHANGE_PASSWORD_FAILED = "ET_CHANGE_PASSWORD_FAILED";
export const ET_EMAIL_CHANGE_MODAL_OPEN = "ET_EMAIL_CHANGE_MODAL_OPEN";
export const ET_EMAIL_CHANGE_MODAL_CONTINUE_BUTTON_CLICK = "ET_EMAIL_CHANGE_MODAL_CONTINUE_BUTTON_CLICK";
export const ET_EMAIL_CHANGE_MODAL_CANCEL_BUTTON_CLICK = "ET_EMAIL_CHANGE_MODAL_CANCEL_BUTTON_CLICK";
export const ET_LEGAL_DOC_BUTTON_CLICK = "ET_LEGAL_DOC_BUTTON_CLICK";
export const ET_ADD_CARD_FAILED = "ET_ADD_CARD_FAILED";
export const ET_ADD_CARD_SUCCESSFUL = "ET_ADD_CARD_SUCCESSFUL";
export const ET_MAKE_DEFAULT_PAYMENT_METHOD_FAILED = "ET_MAKE_DEFAULT_PAYMENT_METHOD_FAILED";
export const ET_MAKE_DEFAULT_PAYMENT_METHOD_SUCCESSFUL = "ET_MAKE_DEFAULT_PAYMENT_METHOD_SUCCESSFUL";
export const ET_ACCOUNT_PAGE_LOADED = "ET_ACCOUNT_PAGE_LOADED";
export const ET_PROFILE_TAB_CLICK = "ET_PROFILE_TAB_CLICK";
export const ET_CONTACT_US_TAB_CLICK = "ET_CONTACT_US_TAB_CLICK";
export const ET_LEGAL_DOCS_TAB_CLICK = "ET_LEGAL_DOCS_TAB_CLICK";
export const ET_SUBSCRIPTION_TAB_CLICK = "ET_SUBSCRIPTION_TAB_CLICK";
export const ET_ORDER_HISTORY_TAB_CLICK = "ET_ORDER_HISTORY_TAB_CLICK";
export const ET_SIGNOUT_TAB_CLICK = "ET_SIGNOUT_TAB_CLICK";
export const ET_SUBSCRIPTION_CANCEL_PLAN_BUTTON_CLICK = "ET_SUBSCRIPTION_CANCEL_PLAN_BUTTON_CLICK";
export const ET_VALUATION_REPORTS_TAB_CLICK = "ET_VALUATION_REPORTS_TAB_CLICK";
export const ET_WHITE_LABEL_TAB_CLICK = "ET_WHITE_LABEL_TAB_CLICK";
export const ET_BUSINESS_PLANS_TAB_CLICK = "ET_BUSINESS_PLANS_TAB_CLICK";
export const ET_UPLOAD_WATCHLIST_CSV_TAB_CLICK = "ET_UPLOAD_WATCHLIST_CSV_TAB_CLICK";

export const HEADER_SINGLE_SEARCH_SEARCHBAR_ICON = "HEADER_SINGLE_SEARCH_SEARCHBAR_ICON";
export const HEADER_SINGLE_SEARCH_SEARCHBAR_ENTER = "HEADER_SINGLE_SEARCH_SEARCHBAR_ENTER";
export const ET_HEADER_SINGLE_SEARCH = "ET_HEADER_SINGLE_SEARCH";
export const HEADER_SINGLE_SEARCH_SEARCHBAR_SEARCH = "HEADER_SINGLE_SEARCH_SEARCHBAR_SEARCH";
export const HEADER_SINGLE_SEARCH_SEARCHBAR_OPTION = "HEADER_SINGLE_SEARCH_SEARCHBAR_OPTION";

// download financial excel
export const ET_DOWNLOAD_FINANCIALS_BTN_CLICK = "ET_DOWNLOAD_FINANCIALS_BTN_CLICK";

export const EP_SIGNUP_CTA_CLICK = "EP_SIGNUP_CTA_CLICK"
//NEW HOMEPAGE
export const EP_CREDIT_REPORT_HOMEPAGE = "EP_CREDIT_REPORT_HOMEPAGE";
export const EP_FIND_COMPANY_DIRECTOR_HOMEPAGE = "EP_FIND_COMPANY_DIRECTOR_HOMEPAGE";
export const EP_VALUE_BUSINESS_HOMEPAGE = "EP_VALUE_BUSINESS_HOMEPAGE";
export const EP_LEAD_GENERATION_HOMEPAGE = "EP_LEAD_GENERATION_HOMEPAGE";
export const EP_ACCESS_INVESTMENT_HOMEPAGE = "EP_ACCESS_INVESTMENT_HOMEPAGE";
export const EP_ACCESS_INVESTMENT_HOMEPAGE_ONLOAD = "EP_ACCESS_INVESTMENT_HOMEPAGE_ONLOAD";
export const EP_SCORECARD_HOMEPAGE = "EP_SCORECARD_HOMEPAGE";
export const EP_INDUSTRY_INSIGHT_HOMEPAGE = "EP_INDUSTRY_INSIGHT_HOMEPAGE";


export const ET_YOUTUBE_LINK_CLICKED = "ET_YOUTUBE_LINK_CLICKED"
export const ET_YOUTUBE_VIDEO_CLOSED = "ET_YOUTUBE_VIDEO_CLOSED"
export const ET_SAMPLE_COMPANY_INFO_REPORT_CLICK = "ET_SAMPLE_COMPANY_INFO_REPORT_CLICK";
export const ET_SAMPLE_DIRECTOR_INFO_REPORT_CLICK = "ET_SAMPLE_DIRECTOR_INFO_REPORT_CLICK";
export const ET_TRY_POWER_SEARCH_CLICK = "ET_TRY_POWER_SEARCH_CLICK";
export const ET_FIND_COMPANY_DIRECTOR_LINK_CLICK = "ET_FIND_COMPANY_DIRECTOR_LINK_CLICK"
export const ET_CREDIT_REPORT_LINK_CLICK = "ET_CREDIT_REPORT_LINK_CLICK"
export const ET_VALUE_BUSINESS_LINK_CLICK = "ET_VALUE_BUSINESS_LINK_CLICK"
export const ET_LEAD_GENERATION_LINK_CLICK = "ET_LEAD_GENERATION_LINK_CLICK";
export const ET_ACCESS_FUNDING_LINK_CLICK = "ET_ACCESS_FUNDING_LINK_CLICK";
export const ET_SCORECARD_LINK_CLICK = "ET_SCORECARD_LINK_CLICK";
export const ET_CREDIT_REPORT_HOMEPAGE_ONLOAD = "ET_CREDIT_REPORT_HOMEPAGE_ONLOAD";
export const ET_VALUE_BUSINESS_HOMEPAGE_ONLOAD = "ET_VALUE_BUSINESS_HOMEPAGE_ONLOAD";
export const ET_FIND_COMPANY_DIRECTOR_HOMEPAGE_ONLOAD = "ET_FIND_COMPANY_DIRECTOR_HOMEPAGE_ONLOAD";
export const ET_LEAD_GENERATION_HOMEPAGE_ONLOAD = "ET_LEAD_GENERATION_HOMEPAGE_ONLOAD";
export const ET_SCORECARD_HOMEPAGE_ONLOAD = "ET_SCORECARD_HOMEPAGE_ONLOAD";
export const ET_INDUSTRY_INSIGHT_HOMEPAGE_ONLOAD = "ET_INDUSTRY_INSIGHT_HOMEPAGE_ONLOAD";
export const ET_SOLUTIONS_FIND_COMPANY_DIRECTOR_MENU_CLICK = "ET_SOLUTIONS_FIND_COMPANY_DIRECTOR_MENU_CLICK";
export const ET_SOLUTIONS_CREDIT_REPORT_MENU_CLICK = "ET_SOLUTIONS_CREDIT_REPORT_MENU_CLICK";
export const ET_SOLUTIONS_VALUE_BUSINESS_MENU_CLICK = "ET_SOLUTIONS_VALUE_BUSINESS_MENU_CLICK";
export const ET_SOLUTIONS_LEAD_GENERATION_MENU_CLICK = "ET_SOLUTIONS_LEAD_GENERATION_MENU_CLICK";
export const ET_SOLUTIONS_ACCESS_FUNDING_MENU_CLICK = "ET_SOLUTIONS_ACCESS_FUNDING_MENU_CLICK";
export const ET_SOLUTIONS_SCORECARD_MENU_CLICK = "ET_SOLUTIONS_SCORECARD_MENU_CLICK";
export const HOMEPAGE_MENU_MODAL = "HOMEPAGE_MENU_MODAL";
export const HEADER = "HEADER";
export const ET_SEE_ALL_RESULTS_CLICK = "ET_SEE_ALL_RESULTS_CLICK";
export const ET_SCORECARD_FAQ_LINK_CLICK = "ET_SCORECARD_FAQ_LINK_CLICK";
export const ET_SCORECARD_FAQ_BUTTON_CLICK = "ET_SCORECARD_FAQ_BUTTON_CLICK";
export const ET_SEE_INDUSTRY_PROFILE_CLICK = "ET_SEE_INDUSTRY_PROFILE_CLICK"
export const ET_SEE_ALL_INDUSTRY_PROFILE_CLICK = "ET_SEE_ALL_INDUSTRY_PROFILE_CLICK"
export const ET_SOLUTIONS_MENU_MODAL_CLICK = "ET_SOLUTIONS_MENU_MODAL_CLICK"

export const VALUE_BUSINESS_SEARCHBAR_ENTER = "VALUE_BUSINESS_SEARCHBAR_ENTER";
export const ET_PRODUCT_HOMEPAGE_SEARCHBAR_ENTER_CLICK = "ET_PRODUCT_HOMEPAGE_SEARCHBAR_ENTER_CLICK";
export const ET_PRODUCT_HOMEPAGE_SINGLE_SEARCH = "ET_PRODUCT_HOMEPAGE_SINGLE_SEARCH";
export const VALUE_BUSINESS_SEARCHBAR_SEARCH = "VALUE_BUSINESS_SEARCHBAR_SEARCH";
export const VALUE_BUSINESS_SEARCHBAR_OPTION = "VALUE_BUSINESS_SEARCHBAR_OPTION";
export const VALUE_BUSINESS_SEARCHBAR_ICON = "VALUE_BUSINESS_SEARCHBAR_ICON";

export const KNOW_COMPANY_DIRECTOR_SEARCHBAR_ENTER = "KNOW_COMPANY_DIRECTOR_SEARCHBAR_ENTER";
export const KNOW_COMPANY_DIRECTOR_SEARCHBAR_SEARCH = "KNOW_COMPANY_DIRECTOR_SEARCHBAR_SEARCH";
export const KNOW_COMPANY_DIRECTOR_SEARCHBAR_OPTION = "KNOW_COMPANY_DIRECTOR_SEARCHBAR_OPTION";
export const KNOW_COMPANY_DIRECTOR_SEARCHBAR_ICON = "KNOW_COMPANY_DIRECTOR_SEARCHBAR_ICON";

export const KNOW_COMPANY_SEARCHBAR_ENTER = "KNOW_COMPANY_SEARCHBAR_ENTER";
export const KNOW_COMPANY_SEARCHBAR_SEARCH = "KNOW_COMPANY_SEARCHBAR_SEARCH";
export const KNOW_COMPANY_SEARCHBAR_OPTION = "KNOW_COMPANY_SEARCHBAR_OPTION";
export const KNOW_COMPANY_SEARCHBAR_ICON = "KNOW_COMPANY_SEARCHBAR_ICON";

export const FIND_COMPANY_DIRECTOR_SEARCHBAR_ENTER = "FIND_COMPANY_DIRECTOR_SEARCHBAR_ENTER";
export const FIND_COMPANY_DIRECTOR_SEARCHBAR_SEARCH = "FIND_COMPANY_DIRECTOR_SEARCHBAR_SEARCH";
export const FIND_COMPANY_DIRECTOR_SEARCHBAR_OPTION = "FIND_COMPANY_DIRECTOR_SEARCHBAR_OPTION";
export const FIND_COMPANY_DIRECTOR_SEARCHBAR_ICON = "FIND_COMPANY_DIRECTOR_SEARCHBAR_ICON";

export const GET_CREDIT_REPORTS_SEARCHBAR_ENTER = "GET_CREDIT_REPORTS_SEARCHBAR_ENTER";
export const GET_CREDIT_REPORTS_SEARCHBAR_SEARCH = "GET_CREDIT_REPORTS_SEARCHBAR_SEARCH";
export const GET_CREDIT_REPORTS_SEARCHBAR_OPTION = "GET_CREDIT_REPORTS_SEARCHBAR_OPTION";
export const GET_CREDIT_REPORTS_SEARCHBAR_ICON = "GET_CREDIT_REPORTS_SEARCHBAR_ICON";

export const KNOW_THE_COMPANY_SEARCHBAR_ENTER = "KNOW_THE_COMPANY_SEARCHBAR_ENTER";
export const KNOW_THE_COMPANY_SEARCHBAR_SEARCH = "KNOW_THE_COMPANY_SEARCHBAR_SEARCH";
export const KNOW_THE_COMPANY_SEARCHBAR_OPTION = "KNOW_THE_COMPANY_SEARCHBAR_OPTION";
export const KNOW_THE_COMPANY_SEARCHBAR_ICON = "KNOW_THE_COMPANY_SEARCHBAR_ICON";
export const ET_COMPANY_HOUSE_EMAIL_NOTIFICATION_SUCCESSFUL = "ET_COMPANY_HOUSE_EMAIL_NOTIFICATION_SUCCESSFUL"
export const ET_COMPANY_HOUSE_EMAIL_NOTIFICATION_FAILED = "ET_COMPANY_HOUSE_EMAIL_NOTIFICATION_FAILED"
export const ET_CH_EMAIL_REDIRECT = "CH_EMAIL_REDIRECT"

export const LEAD_GENERATION_SEARCHBAR_ENTER = "LEAD_GENERATION_SEARCHBAR_ENTER";
export const LEAD_GENERATION_SEARCHBAR_SEARCH = "LEAD_GENERATION_SEARCHBAR_SEARCH";
export const LEAD_GENERATION_SEARCHBAR_OPTION = "LEAD_GENERATION_SEARCHBAR_OPTION";
export const LEAD_GENERATION_SEARCHBAR_ICON = "LEAD_GENERATION_SEARCHBAR_ICON";

export const ET_GET_IN_TOUCH_CLICK = "ET_GET_IN_TOUCH_CLICK";
export const ET_REGISTER_INTEREST_CLICK = "ET_REGISTER_INTEREST_CLICK";
export const ET_SUBMIT_INTEREST = "ET_SUBMIT_INTEREST";
export const ET_RESET_FILTER_BUTTON_CLICK = "ET_RESET_FILTER_BUTTON_CLICK";

export const ET_RESET_FILTER_SUCCESS = "ET_RESET_FILTER_SUCCESS";
export const ET_RESET_FILTER_FAILED = "ET_RESET_FILTER_FAILED";

export const ET_POWER_SEARCH_ESTIMATION_TOGGLE_LEFT = "ET_POWER_SEARCH_ESTIMATION_TOGGLE_LEFT";
export const ET_SCORE_CARD_LOGIN_CLICK = "ET_SCORE_CARD_LOGIN_CLICK";
export const ET_SCORE_CARD_SIGNUP_CLICK = "ET_SCORE_CARD_SIGNUP_CLICK";
export const ET_SCORE_CARD_YEAR_DROPDOWN_CLICK = "ET_SCORE_CARD_YEAR_DROPDOWN_CLICK";
export const ET_SCORE_CARD_INDICATORS_TABLE_SHOW_MORE_BTN_CLICK = "ET_SCORE_CARD_INDICATORS_TABLE_SHOW_MORE_BTN_CLICK";
export const ET_SCORE_CARD_INDICATORS_TABLE_SHOW_LESS_BTN_CLICK = "ET_SCORE_CARD_INDICATORS_TABLE_SHOW_LESS_BTN_CLICK";
export const ET_RATING_IN_HEADER_CLICK = "ET_RATING_IN_HEADER_CLICK";

export const POMANDA_SCORECARD_SEARCHBAR_ENTER = "POMANDA_SCORECARD_SEARCHBAR_ENTER";
export const POMANDA_SCORECARD_SEARCHBAR_SEARCH = "POMANDA_SCORECARD_SEARCHBAR_SEARCH";
export const POMANDA_SCORECARD_SEARCHBAR_OPTION = "POMANDA_SCORECARD_SEARCHBAR_OPTION";
export const POMANDA_SCORECARD_SEARCHBAR_ICON = "POMANDA_SCORECARD_SEARCHBAR_ICON";

export const EP_PRODUCT_PAGE = "EP_PRODUCT_PAGE"
export const ET_HEADER_NAV_PRODUCT_CLICK = "ET_HEADER_NAV_PRODUCT_CLICK"
export const ET_PLAN_BILL_TOGGLE = "ET_PLAN_BILL_TOGGLE"
export const ET_PLAN_BUTTON_CLICK = "ET_PLAN_BUTTON_CLICK"
export const ET_TRY_POMANDA_BUTTON_CLICK = "ET_TRY_POMANDA_BUTTON_CLICK"
export const ET_PRODUCT_PAGE_ONLOAD = "ET_PRODUCT_PAGE_ONLOAD"
export const ET_PLAN_UPGRADE_BUTTON_CLICK = "ET_PLAN_UPGRADE_BUTTON_CLICK";
export const ET_COMPARE_POMANDA_PLANS_AND_PRICING_SCROLL = "ET_COMPARE_POMANDA_PLANS_AND_PRICING_SCROLL"

export const ET_SCORE_CARD_AGREE_NO_BTN_CLICK = "ET_SCORE_CARD_AGREE_NO_BTN_CLICK";
export const ET_SCORE_CARD_AGREE_YES_BTN_CLICK = "ET_SCORE_CARD_AGREE_YES_BTN_CLICK";
export const SCORE_CARD_AGREE_NO_BTN = "SCORE_CARD_AGREE_NO_BTN";
export const ET_ABOUT_SECTION_AGREE_YES_BTN_CLICK = "ET_ABOUT_SECTION_AGREE_YES_BTN_CLICK";
export const ET_ABOUT_SECTION_AGREE_NO_BTN_CLICK = "ET_ABOUT_SECTION_AGREE_NO_BTN_CLICK";
export const ABOUT_SECTION_AGREE_NO_BTN = "ABOUT_SECTION_AGREE_NO_BTN";
export const SCORE_CARD_CUSTOM_ESTIMAT_BTN = "SCORE_CARD_CUSTOM_ESTIMAT_BTN";
export const ET_SCORE_CARD_CUSTOM_ESTIMAT_CLICK = "ET_SCORE_CARD_CUSTOM_ESTIMAT_CLICK";

//choose package modal
export const ET_CHOOSE_PACKAGE_MODAL_OPEN = "ET_CHOOSE_PACKAGE_MODAL_OPEN";
export const ET_CHOOSE_PACKAGE_MODAL_CLOSE = "ET_CHOOSE_PACKAGE_MODAL_CLOSE";
export const ET_CHOOSE_PACKAGE_BTN_CLICK = "ET_CHOOSE_PACKAGE_BTN_CLICK"
export const ADD_INDUSTRY_BTN_CLICK = "ADD_INDUSTRY_BTN_CLICK";
export const SAVE_INDUSTRY_MIX_BTN_CLICK = "SAVE_INDUSTRY_MIX_BTN_CLICK";
export const CHOOSE_PACKAGE_MODAL_CLOSE_BTN = "CHOOSE_PACKAGE_MODAL_CLOSE_BTN";

export const ET_SIDE_MENU_CLICK = "side_menu_click";
export const ET_ABOUT_SECTION_SEE_ALL_DIRECTOR_CLICK = "ET_ABOUT_SECTION_SEE_ALL_DIRECTOR_CLICK";
export const ET_ABOUT_SECTION_SEE_ALL_INDUSTRY_CLICK = "ET_ABOUT_SECTION_SEE_ALL_INDUSTRY_CLICK";
export const ET_ABOUT_SECTION_DIRECTOR_NAME_CLICK = "ET_ABOUT_SECTION_DIRECTOR_NAME_CLICK";
export const ET_ABOUT_SECTION_INDUSTRY_NAME_CLICK = "ET_ABOUT_SECTION_INDUSTRY_NAME_CLICK";
export const ABOUT_SECTION_INDUSTRY_NAME_CLICK = "ABOUT_SECTION_INDUSTRY_NAME_CLICK";
export const ABOUT_SECTION_SEE_ALL_INDUSTRY_CLICK = "ABOUT_SECTION_SEE_ALL_INDUSTRY_CLICK";
export const ET_VALUATION_SECTION_AGREE_YES_BTN_CLICK = "ET_VALUATION_SECTION_AGREE_YES_BTN_CLICK";
export const ET_VALUATION_SECTION_AGREE_NO_BTN_CLICK = "ET_VALUATION_SECTION_AGREE_NO_BTN_CLICK"
export const VALUATION_AGREE_NO_BTN_CLICK = "VALUATION_AGREE_NO_BTN_CLICK";
export const ET_OVERVIEW_SHAREHOLDER_NAME_CLICK = "ET_OVERVIEW_SHAREHOLDER_NAME_CLICK";
export const ET_OVERVIEW_SHOW_MORE_SHAREHOLDER_CLICK = "ET_OVERVIEW_SHOW_MORE_SHAREHOLDER_CLICK";
export const ET_OVERVIEW_CHARGES_NAME_CLICK = "ET_OVERVIEW_CHARGES_NAME_CLICK";
export const ET_OVERVIEW_SHOW_MORE_CHARGES_CLICK = "ET_OVERVIEW_SHOW_MORE_CHARGES_CLICK";
export const ET_OVERVIEW_SHOW_MORE_GROUP_STRUCTURE_CLICK = "ET_OVERVIEW_SHOW_MORE_GROUP_STRUCTURE_CLICK";
export const ET_OVERVIEW_GROUP_STRUCTURE_COMPANY_CLICK = "ET_OVERVIEW_GROUP_STRUCTURE_COMPANY_CLICK";
export const ET_INDUSTRY_MULTIPLE_TEXT_CLICK = "ET_INDUSTRY_MULTIPLE_TEXT_CLICK";
export const ET_OPEN_INDUSTRY_MULTIPLE_MODAL = "ET_OPEN_INDUSTRY_MULTIPLE_MODAL";
export const ET_EDIT_INDUSTRY_MULTIPLE_CLICK = "ET_EDIT_INDUSTRY_MULTIPLE_CLICK";
export const EDIT_INDUSTRY_MULTIPLE_CLICK = "EDIT_INDUSTRY_MULTIPLE_CLICK";
export const ET_SIMILAR_COMPANIES_AGREE_YES_BTN_CLICK = "ET_SIMILAR_COMPANIES_AGREE_YES_BTN_CLICK";
export const ET_SIMILAR_COMPANIES_AGREE_NO_BTN_CLICK = "ET_SIMILAR_COMPANIES_AGREE_NO_BTN_CLICK";
export const SIMILAR_COMPANIES_AGREE_NO_BTN = "SIMILAR_COMPANIES_AGREE_NO_BTN";
export const ET_PERFORMANCE_INDICATOR_TABLE_COMPANY_VALUE_EDIT_CLICK = "ET_PERFORMANCE_INDICATOR_TABLE_COMPANY_VALUE_EDIT_CLICK";
export const PERFORMANCE_INDICATOR_TABLE_COMPANY_VALUE_EDIT_BTN = "PERFORMANCE_INDICATOR_TABLE_COMPANY_VALUE_EDIT_BTN";
export const ET_FINANCIAL_SECTION_AGREE_YES_BTN_CLICK = "ET_FINANCIAL_SECTION_AGREE_YES_BTN_CLICK";
export const ET_FINANCIAL_SECTION_AGREE_NO_BTN_CLICK = "ET_FINANCIAL_SECTION_AGREE_NO_BTN_CLICK";
export const ET_FINANCIAL_SECTION_REF_ESTIMAT_BTN_CLICK = "ET_FINANCIAL_SECTION_REF_ESTIMAT_BTN_CLICK";
export const FINANCIAL_AGREE_NO_BTN_CLICK = "FINANCIAL_AGREE_NO_BTN_CLICK";
export const FINANCIAL_REF_ESTIMAT_BTN_CLICK = "FINANCIAL_REF_ESTIMAT_BTN_CLICK";

//estimate homepage
export const ESTIMATE_HOMEPAGE_SEARCHBAR_ENTER = "ESTIMATE_HOMEPAGE_SEARCHBAR_ENTER";
export const EP_ESTIMATE_HOMEPAGE = "EP_ESTIMATE_HOMEPAGE";
export const ESTIMATE_HOMEPAGE_SEARCHBAR_SEARCH = "ESTIMATE_HOMEPAGE_SEARCHBAR_SEARCH";
export const ESTIMATE_HOMEPAGE_SEARCHBAR_OPTION = "ESTIMATE_HOMEPAGE_SEARCHBAR_OPTION";
export const ESTIMATE_HOMEPAGE_SEARCHBAR_ICON = "ESTIMATE_HOMEPAGE_SEARCHBAR_ICON";
export const EP_PROSPER_HOMEPAGE = "EP_PROSPER_HOMEPAGE";
export const ET_PROSPER_HOMEPAGE_ONLOAD = "ET_PROSPER_HOMEPAGE_ONLOAD";
export const ET_SOLUTIONS_ESTIMATE_MENU_CLICK = "ET_SOLUTIONS_ESTIMATE_MENU_CLICK";
export const ET_ESTIMATE_HOMEPAGE_ONLOAD = "ET_ESTIMATE_HOMEPAGE_ONLOAD";
export const ET_ESTIMATE_LINK_CLICK = "ET_ESTIMATE_LINK_CLICK";
export const ET_ESTIMATE_WARNING_LEARN_MORE_CLICK = "ET_ESTIMATE_WARNING_LEARN_MORE_CLICK";

// businessplan homepage
export const EP_BUSINESSPLAN_HOMEPAGE = "EP_BUSINESSPLAN_HOMEPAGE";
export const EP_BUSINESSPLAN_PPC_HOMEPAGE = "EP_BUSINESSPLAN_PPC_HOMEPAGE";
export const ET_BUSINESS_PLAN_PACKAGE_REGISTER_INTEREST_BTN_CLICK = "ET_BUSINESS_PLAN_PACKAGE_REGISTER_INTEREST_BTN_CLICK";
export const ET_BUSINESS_PLAN_PACKAGE_REGISTER_INTEREST_SUBMIT_FORM = "ET_BUSINESS_PLAN_PACKAGE_REGISTER_INTEREST_SUBMIT_FORM";
export const ET_BUSINESSPLAN_HOMEPAGE_ONLOAD = "ET_BUSINESSPLAN_HOMEPAGE_ONLOAD";
export const ET_BUSINESSPLAN_SUBMIT_CONTACT_US_BTN_CLICK = "ET_BUSINESSPLAN_ADVICE_CONTACT_US_BTN_CLICK"
export const ET_SOLUTIONS_BUSINESS_PLAN_MENU_CLICK = "ET_SOLUTIONS_BUSINESS_PLAN_MENU_CLICK";
export const ET_BUSINESS_PLAN_TEMPLATE_BTN_CLICK = "ET_BUSINESS_PLAN_TEMPLATE_BTN_CLICK";
export const ET_BUSINESS_PLAN_CONTACT_US_BTN_CLICK = "ET_BUSINESS_PLAN_CONTACT_US_BTN_CLICK";
export const BUSINESS_PLAN_HOMEPAGE_SEARCHBAR_SEARCH = "BUSINESS_PLAN_HOMEPAGE_SEARCHBAR_SEARCH";
export const BUSINESS_PLAN_HOMEPAGE_SEARCHBAR_ENTER = "BUSINESS_PLAN_HOMEPAGE_SEARCHBAR_ENTER";
export const BUSINESS_PLAN_HOMEPAGE_SEARCHBAR_OPTION = "BUSINESS_PLAN_HOMEPAGE_SEARCHBAR_OPTION";
export const BUSINESS_PLAN_HOMEPAGE_SEARCHBAR_ICON = "BUSINESS_PLAN_HOMEPAGE_SEARCHBAR_ICON"

// valutionReportPPC homepage 
export const VALUATION_REPORT_PPC_HOMEPAGE_SEARCHBAR_ENTER = "VALUATION_REPORT_HOMEPAGE_SEARCHBAR_ENTER";
export const EP_VALUATION_REPORT_PPC_HOMEPAGE = "EP_VALUATION_REPORT_PPC_HOMEPAGE";
export const VALUATION_REPORT_PPC_HOMEPAGE_SEARCHBAR_SEARCH = "VALUATION_REPORT_HOMEPAGE_SEARCHBAR_SEARCH";
export const VALUATION_REPORT_PPC_HOMEPAGE_SEARCHBAR_OPTION = "VALUATION_REPORT_HOMEPAGE_SEARCHBAR_OPTION";
export const VALUATION_REPORT_PPC_HOMEPAGE_SEARCHBAR_ICON = "VALUATION_REPORT_PPC_HOMEPAGE_SEARCHBAR_ICON";
export const ET_SEARCH_BAR_VIEW_ALL_COMPANIES_BTN_CLICK = "ET_SEARCH_BAR_VIEW_ALL_COMPANIES_BTN_CLICK";
export const ET_SEARCH_BAR_SEARCH_ICON_CLICK = "ET_SEARCH_BAR_SEARCH_ICON_CLICK";
export const ET_SOLUTIONS_VALUATION_REPORT_PPC_MENU_CLICK = "ET_SOLUTIONS_VALUATION_REPORT_PPC_MENU_CLICK";
export const ET_VALUATION_REPORT_PPC_HOMEPAGE_ONLOAD = "ET_VALUATION_REPORT_PPC_HOMEPAGE_ONLOAD";
export const ET_VALUATION_REPORT_PPC_LINK_CLICK = "ET_VALUATION_REPORT_PPC_LINK_CLICK";
export const ET_VALUATION_REPORT_PACKAGE_BUY_NOW_BTN_CLICK = "ET_VALUATION_REPORT_PACKAGE_BUY_NOW_BTN_CLICK";
export const ET_VALUATION_REPORT_ENTERPRISE_BUY_BTN_CLICK = "ET_VALUATION_REPORT_ENTERPRISE_BUY_BTN_CLICK";
export const ET_VALUATION_REPORT_PPC_SAMPLE_REPORT_BTN_CLICK = "ET_VALUATION_REPORT_PPC_SAMPLE_REPORT_BTN_CLICK";
export const ET_VALUATION_REPORT_PPC_SEE_PRICING_PLAN_LINK_CLICK = "ET_VALUATION_REPORT_PPC_SEE_PRICING_PLAN_LINK_CLICK"
export const ET_SOLUTIONS_PPC_BUSINESS_VALUE_MENU_CLICK = "ET_SOLUTIONS_PPC_BUSINESS_VALUE_MENU_CLICK"
export const ET_SOLUTIONS_PPC_VALUATION_REPORT_MENU_CLICK = "ET_SOLUTIONS_PPC_VALUATION_REPORT_MENU_CLICK"
export const ET_PPC_HOMEPAGE_LEARN_MORE_BTN_CLICK = "ET_PPC_HOMEPAGE_LEARN_MORE_BTN_CLICK"
export const ET_ENTERPRISE_PACKAGE_BUY_BTN_CLICK = "ET_ENTERPRISE_PACKAGE_BUY_BTN_CLICK"
export const ET_VIEW_ALL_COMPANIES_BTN_CLICK = "ET_VIEW_ALL_COMPANIES_BTN_CLICK"
export const EP_DIRECT_VALUATION_REPORT_PPC_LEADPAGE = "EP_DIRECT_VALUATION_REPORT_PPC_LEADPAGE"
export const ET_COMPANY_VALUATION_LEARN_MORE_BTN_CLICK = "ET_COMPANY_VALUATION_LEARN_MORE_BTN_CLICK"
export const ET_OPEN_VALUATION_REPORT_BTN_CLICK = "ET_OPEN_VALUATION_REPORT_BTN_CLICK"
export const ET_DOWNLOAD_VALUATION_REPORT_BTN_CLICK = "ET_DOWNLOAD_VALUATION_REPORT_BTN_CLICK"
export const ET_REMAINING_CREDITS_BUY_VALUATION_REPORT_BTN_CLICK = "ET_REMAINING_CREDITS_BUY_VALUATION_REPORT_BTN_CLICK"
export const ET_EXPIRED_BUY_VALUATION_REPORT_BTN_CLICK = "ET_EXPIRED_BUY_VALUATION_REPORT_BTN_CLICK"
export const ET_VALUATION_REPORT_BUY_CONFIRMATION_YES_CLICK = "ET_VALUATION_REPORT_BUY_CONFIRMATION_YES_CLICK"
export const ET_VALUATION_REPORT_BUY_CONFIRMATION_NO_CLICK = "ET_VALUATION_REPORT_BUY_CONFIRMATION_NO_CLICK"
export const ET_REPORT_PACKAGE_LEARN_MORE_LINK_CLICK = "ET_REPORT_PACKAGE_LEARN_MORE_LINK_CLICK"
export const ET_PPC_HOMEPAGE_SEE_PRICING_PLANS_BTN_CLICK = "ET_PPC_HOMEPAGE_SEE_PRICING_PLANS_BTN_CLICK"
export const ET_ENTERPRISE_PACKAGE_CONTACT_US_BTN_CLICK = "ET_ENTERPRISE_PACKAGE_CONTACT_US_BTN_CLICK";
export const ET_REPORT_PACKAGE_GET_IN_TOUCH_LINK_CLICK = "ET_REPORT_PACKAGE_GET_IN_TOUCH_LINK_CLICK";
export const ET_AUTOMATED_BUSINESS_START_BROWSING_LINK_CLICK = "ET_AUTOMATED_BUSINESS_START_BROWSING_LINK_CLICK";
export const ET_BUSINESS_VALUATION_VIDEO_CLICK = "ET_BUSINESS_VALUATION_VIDEO_CLICK";
export const ET_BUSINESS_VALUATION_CLOSE_VIDEO_BTN_CLICK = "ET_BUSINESS_VALUATION_CLOSE_VIDEO_BTN_CLICK";
export const ET_VALUATION_REPORT_PPC_HOMEPAGE_SCROLL = "ET_VALUATION_REPORT_PPC_HOMEPAGE_SCROLL"

// searchCompaniesPPC landingpage 

export const SEARCH_COMPANIES_PPC_LANDING_PAGE_SEARCHBAR_ENTER = "SEARCH_COMPANIES_PPC_LANDING_PAGE_SEARCHBAR_ENTER";
export const EP_SEARCH_COMPANIES_PPC_LANDING_PAGE = "EP_SEARCH_COMPANIES_PPC_LANDING_PAGE";
export const SEARCH_COMPANIES_PPC_LANDING_PAGE_SEARCHBAR_SEARCH = "SEARCH_COMPANIES_PPC_LANDING_PAGE_SEARCHBAR_SEARCH";
export const SEARCH_COMPANIES_PPC_LANDING_PAGE_SEARCHBAR_OPTION = "SEARCH_COMPANIES_PPC_LANDING_PAGE_SEARCHBAR_OPTION";
export const SEARCH_COMPANIES_PPC_LANDING_PAGE_SEARCHBAR_ICON = "SEARCH_COMPANIES_PPC_LANDING_PAGE_SEARCHBAR_ICON";
export const ET_SEARCH_COMPANIES_PPC_LANDING_PAGE_ONLOAD = "ET_SEARCH_COMPANIES_PPC_LANDING_PAGE_ONLOAD";
export const ET_SEARCH_COMPANIES_RETURN_BTN_CLICK = "ET_SEARCH_COMPANIES_RETURN_BTN_CLICK";
export const EP_DIRECT_SEARCH_COMPANIES_PPC_LEADPAGE = "EP_DIRECT_SEARCH_COMPANIES_PPC_LEADPAGE";
export const ET_SEE_MORE_COMPANIES_BTN_CLICK = "ET_SEE_MORE_COMPANIES_BTN_CLICK"
export const ET_BACK_BTN_CLICK = "ET_BACK_BTN_CLICK"


// selected package landing page

export const EP_SELECT_PACKAGE_PPC_LANDING_PAGE = "EP_SELECT_PACKAGE_PPC_LANDING_PAGE";
export const ET_SELECT_PACKAGE_PPC_PAGE_ONLOAD = "ET_SELECT_PACKAGE_PPC_PAGE_ONLOAD";
export const ET_SELECT_PACKAGE_PPC_BACK_BTN_CLICK = "ET_SELECT_PACKAGE_PPC_BACK_BTN_CLICK";
export const EP_DIRECT_SELECT_PACKAGE_PPC_LEADPAGE = "EP_DIRECT_SELECT_PACKAGE_PPC_LEADPAGE"


//industry insights
export const ET_SOLUTIONS_INDUSTRY_MENU_CLICK = "ET_SOLUTIONS_INDUSTRY_MENU_CLICK"
export const ET_INDUSTRY_INSIGHT_LINK_CLICK = "ET_INDUSTRY_INSIGHT_LINK_CLICK";

export const POMANDA_INDUSTRY_INSIGHT_SEARCHBAR_ENTER = "POMANDA_INDUSTRY_INSIGHT_SEARCHBAR_ENTER";
export const POMANDA_INDUSTRY_INSIGHT_SEARCHBAR_SEARCH = "POMANDA_INDUSTRY_INSIGHT_SEARCHBAR_SEARCH";
export const POMANDA_INDUSTRY_INSIGHT_SEARCHBAR_OPTION = "POMANDA_INDUSTRY_INSIGHT_SEARCHBAR_OPTION";
export const POMANDA_INDUSTRY_INSIGHT_SEARCHBAR_ICON = "POMANDA_INDUSTRY_INSIGHT_SEARCHBAR_ICON";
export const SIMILAR_COMPANIES_SEE_MORE_BTN_CLICK = "SIMILAR_COMPANIES_SEE_MORE_BTN_CLICK";
export const SAVE_SEARCHES_BTN_CLICK = "SAVE_SEARCHES_BTN_CLICK";
export const SAVE_CURRENT_SEARCH_BTN_CLICK = "SAVE_CURRENT_SEARCH_BTN_CLICK";

//industry profile page
export const EP_INDUSTRY_PROFILE = "EP_INDUSTRY_PROFILE";
export const ET_INDUSTRY_ONLOAD = "ET_INDUSTRY_ONLOAD";
export const ET_HEADER_SEEMORE_CLICK = "ET_HEADER_SEEMORE_CLICK";
export const ET_SEARCH_MODAL_INDUSTRY_CLICK = "ET_SEARCH_MODAL_INDUSTRY_CLICK";
export const ET_RELATED_INDUSTRY_CLICK = "ET_RELATED_INDUSTRY_CLICK";
export const ET_SEE_ALL_BUTTON_CLICK = "ET_SEE_ALL_BUTTON_CLICK";
export const ET_SEEMORE_MODAL_CLOSE_BUTTON_CLICK = "ET_SEEMORE_MODAL_CLOSE_BUTTON_CLICK";
export const ET_INDUSTRY_SHARE_BUTTON_CLICK = "ET_INDUSTRY_SHARE_BUTTON_CLICK";
export const ET_INDUSTRY_PROFILE_BUTTON_CLICK = "ET_INDUSTRY_PROFILE_BUTTON_CLICK";
export const ET_TREE_MAP_INDUSTRY_CLICK = "ET_TREE_MAP_INDUSTRY_CLICK";
export const ET_OTHER_INDUSTRIES_SEEMORE_BUTTON_CLICK = "ET_OTHER_INDUSTRIES_SEEMORE_BUTTON_CLICK";
export const ET_DCHART_COMPANY_LINK_CLICK = "ET_DCHART_COMPANY_LINK_CLICK";
export const ET_INDUSTRY_INSIGHT_COMPANY_LINK_CLICK = "ET_INDUSTRY_INSIGHT_COMPANY_LINK_CLICK";
export const ET_INDUSTRY_PROFILE_SEARCH_SUSSESS = "ET_INDUSTRY_PROFILE_SEARCH_SUSSESS";
export const ET_INDUSTRY_PROFILE_SEARCH_FAILED = "ET_INDUSTRY_PROFILE_SEARCH_FAILED";
export const ET_SOLUTIONS_MODAL_CREDIT_REPORT_MENU_CLICK = "ET_SOLUTIONS_MODAL_CREDIT_REPORT_MENU_CLICK";
export const ET_SOLUTIONS_MODAL_HEALTH_CHECK_MENU_CLICK = "ET_SOLUTIONS_MODAL_HEALTH_CHECK_MENU_CLICK";
export const ET_SOLUTIONS_MODAL_VALUE_BUSINESS_MENU_CLICK = "ET_SOLUTIONS_MODAL_VALUE_BUSINESS_MENU_CLICK";
export const ET_SOLUTIONS_MODAL_LEAD_GENERATION_MENU_CLICK = "ET_SOLUTIONS_MODAL_LEAD_GENERATION_MENU_CLICK";
export const ET_SOLUTIONS_MODAL_ACCESS_FUNDING_MENU_CLICK = "ET_SOLUTIONS_MODAL_ACCESS_FUNDING_MENU_CLICK";
export const ET_SOLUTIONS_MODAL_CLOSE = "ET_SOLUTIONS_MODAL_CLOSE";

export const ET_ABFPAGE_SINGLE_SEARCH = "ET_ABFPAGE_SINGLE_SEARCH";
export const ABFPAGE_SEARCHBAR_SEARCH = "ABFPAGE_SEARCHBAR_SEARCH";
export const ABFPAGE_SEARCHBAR_OPTION = "ABFPAGE_SEARCHBAR_OPTION";
export const ET_COMPANYHOUSE_SEARCHBAR_SEARCH = "ET_COMPANYHOUSE_SEARCHBAR_SEARCH";
export const ET_COMPANYHOUSE_SEARCHBAR_OPTION = "ET_COMPANYHOUSE_SEARCHBAR_OPTION";
export const ET_COMPANYHOUSE_SEARCHBAR_ICON = "ET_COMPANYHOUSE_SEARCHBAR_ICON";
export const CHOOSE_PKG_MODAL_SIGN_IN_CLICK = "CHOOSE_PKG_MODAL_SIGN_IN_CLICK";
export const EP_LOCATION_PROFILE_PAGE = "EP_LOCATION_PROFILE_PAGE";
export const ET_LOCATION_SHARE_BUTTON_CLICK = "ET_LOCATION_SHARE_BUTTON_CLICK";
export const ET_TREE_MAP_LOCATION_CLICK = "ET_TREE_MAP_LOCATION_CLICK";
export const ET_LOCATION_PROFILE_ONLOAD = "ET_LOCATION_PROFILE_ONLOAD";
export const ET_LOCAITON_PROFILE_SEARCH_FAILED = "ET_LOCAITON_PROFILE_SEARCH_FAILED";
export const ET_LOCAITON_PROFILE_SEARCH_SUCCESS = "ET_LOCAITON_PROFILE_SEARCH_SUCCESS";
export const ET_SEARCH_MODAL_LOCATION_CLICK = "ET_SEARCH_MODAL_LOCATION_CLICK";
export const ET_CAROUSEL_LOAD = "ET_CAROUSEL_LOAD";
export const ET_CAROUSEL_NEXT_BTN_CLICK = "ET_CAROUSEL_NEXT_BTN_CLICK";
export const ET_CAROUSEL_PREV_BTN_CLICK = "ET_CAROUSEL_PREV_BTN_CLICK";
export const ET_CAROUSEL_BLOG_CLICK = "ET_CAROUSEL_BLOG_CLICK";
export const ET_USE_POMANDA_PLUS_SECTION_GET_STARTED_BTN_CLICK = "ET_USE_POMANDA_PLUS_SECTION_GET_STARTED_BTN_CLICK";
export const ET_FEATURES_TABLE_SUBSCRIBE_BTN_CLICK = "ET_FEATURES_TABLE_SUBSCRIBE_BTN_CLICK";
export const ET_ENTERPRISE_CONTACT_SALES_BTN_CLICK = "ET_ENTERPRISE_CONTACT_SALES_BTN_CLICK";
export const ET_LEAD_GENERATION_SEE_MORE_BTN_CLICK = "ET_LEAD_GENERATION_SEE_MORE_BTN_CLICK";
export const ET_RESEARCH_ANALYTICS_SEE_MORE_BTN_CLICK = "ET_RESEARCH_ANALYTICS_SEE_MORE_BTN_CLICK";
export const ET_SME_INSIGHT_SEE_MORE_BTN_CLICK = "ET_SME_INSIGHT_SEE_MORE_BTN_CLICK";
export const ET_LEAD_GENERATION_GET_STARTED_BTN_CLICK = "ET_LEAD_GENERATION_GET_STARTED_BTN_CLICK";
export const ET_RESEARCH_ANALYTICS_GET_STARTED_BTN_CLICK = "ET_RESEARCH_ANALYTICS_GET_STARTED_BTN_CLICK";
export const ET_SME_INSIGHT_GET_STARTED_BTN_CLICK = "ET_SME_INSIGHT_GET_STARTED_BTN_CLICK";

//valution
export const ET_VALUATION_CALCULATOR_BTN_CLICK = "ET_VALUATION_CALCULATOR_BTN_CLICK";
export const ET_CREDIT_REPORT_PACKAGE_BUY_NOW_BTN_CLICK = "ET_CREDIT_REPORT_PACKAGE_BUY_NOW_BTN_CLICK";
export const ET_BUSINESS_VALUATION_REPORTS_BTN_CLICK = "ET_BUSINESS_VALUATION_REPORTS_BTN_CLICK";
export const ET_COMPANY_CREDIT_REPORTS_BTN_CLICK = "ET_COMPANY_CREDIT_REPORTS_BTN_CLICK"

export const ET_BUY_CREDIT_REPORT_MODAL_OPEN = "ET_BUY_CREDIT_REPORT_MODAL_OPEN";
export const ET_BUY_CREDIT_REPORT_MODAL_CLOSE = "ET_BUY_CREDIT_REPORT_MODAL_CLOSE";
export const EP_BUY_CREDIT_REPORT_MODAL = "EP_BUY_CREDIT_REPORT_MODAL";
export const ET_EXPLORE_CREDIT_REPORT_PACKAGES_LINK_CLICK = "ET_EXPLORE_CREDIT_REPORT_PACKAGES_LINK_CLICK";
export const ET_BUY_MORE_LINK_CLICK = "ET_BUY_MORE_LINK_CLICK";
export const ET_COMPANY_SHOW_ME_THE_VALUATION_BTN_CLICK = "ET_COMPANY_VALUATION_CALCULATOR_NO_FINANCIAL_BTN_CLICK"
export const ET_COMPANY_VALUATION_SUBHEADER_LINK_CLICK = "ET_COMPANY_VALUATION_SUBHEADER_LINK_CLICK";
export const ET_COMPANY_VALUATION_DESCRIPTION_LINK_CLICK = "ET_COMPANY_VALUATION_DESCRIPTION_LINK_CLICK";
export const ET_COMPANY_VALUATION_PLAN_MODAL_CLOSE_BTN_CLICK = "ET_COMPANY_VALUATION_PLAN_MODAL_CLOSE_BTN_CLICK";
export const CREDIT_REPORT_PACKAGE_TERMS_LINK = "CREDIT_REPORT_PACKAGE_TERMS_LINK";

// valuation calculator
export const EP_VALUATION_CALCULATOR = "EP_VALUATION_CALCULATOR";
export const ET_VALUATION_CALCULATOR_INFO_BTN_CLICK = "ET_VALUATION_CALCULATOR_INFO_BTN_CLICK";
export const ET_VALUATION_CALCULATOR_PROFIT_LOSS_EXPAND_CLICK = "ET_VALUATION_CALCULATOR_PROFIT_LOSS_EXPAND_BTN_CLICK";
export const ET_VALUATION_CALCULATOR_PROFIT_LOSS_COLLAPSE_CLICK = "ET_VALUATION_CALCULATOR_PROFIT_LOSS_COLLAPSE_BTN_CLICK";
export const ET_VALUATION_CALCULATOR_BALANCE_SHEET_COLLAPSE_CLICK = "ET_VALUATION_CALCULATOR_BALANCE_SHEET_COLLAPSE_CLICK";
export const ET_VALUATION_CALCULATOR_BALANCE_SHEET_EXPAND_CLICK = "ET_VALUATION_CALCULATOR_BALANCE_SHEET_EXPAND_CLICK";
export const ET_VALUATION_CALCULATOR_SLIDER_CLICK = "ET_VALUATION_CALCULATOR_SLIDER_CLICK";
export const ET_VALUATION_CALCULATOR_INPUT_BOX_VALUE_ENTERNED = "ET_VALUATION_CALCULATOR_INPUT_BOX_VALUE_ENTERNED";
export const ET_VALUATION_CALC_INDUSTRY_EDIT_BTN_CLICK = "ET_VALUATION_CALC_INDUSTRY_EDIT_BTN_CLICK";
export const ET_VALUATION_TYPE_CHANGE = "ET_VALUATION_TYPE_CHANGE";
export const ET_VALUATION_CALC_GET_REPORT_BTN_CLICK = "ET_VALUATION_CALC_GET_REPORT_BTN_CLICK";
export const ET_VALUATION_CALC_BACK_TO_COMPANY_PROFILE_BTN_CLICK = "ET_VALUATION_CALC_BACK_TO_COMPANY_PROFILE_BTN_CLICK";
export const ET_VALUATION_CALC_NO_FINANCIAL_MODEL_CONTINUE_BTN_CLICK = "ET_VALUATION_CALC_NO_FINANCIAL_MODEL_CONTINUE_BTN_CLICK";
export const ET_VALUATION_CALC_RESET_BTN_CLICK = "ET_VALUATION_CALC_RESET_BTN_CLICK";
export const ET_VALUATION_CALC_RESET_MODEL_RESET_BTN_CLICK = "ET_VALUATION_CALC_RESET_MODEL_RESET_BTN_CLICK";
export const ET_VALUATION_CALC_RESET_MODEL_CANCEL_BTN_CLICK = "ET_VALUATION_CALC_RESET_MODEL_CANCEL_BTN_CLICK";
export const ET_VALUATION_CALC_NO_FINANCIAL_MODEL_CLOSE_BTN_CLICK = "ET_VALUATION_CALC_NO_FINANCIAL_MODEL_CLOSE_BTN_CLICK";
export const ET_ORDER_HISTORY_FILTER_APPLIED = "ET_ORDER_HISTORY_FILTER_APPLIED";
export const ET_ORDER_HISTORY_FILTER_RESET = "ET_ORDER_HISTORY_FILTER_RESET";
export const ET_ORDER_HISTORY_FILTER_BTN_CLICK = "ET_ORDER_HISTORY_FILTER_BTN_CLICK";
export const ET_ORDER_HISTORY_FILTER_MODAL_OPEN = "ET_ORDER_HISTORY_FILTER_MODAL_OPEN";
export const ET_ORDER_HISTORY_FILTER_MODAL_CLOSE = "ET_ORDER_HISTORY_FILTER_MODAL_CLOSE";
export const ET_ORDER_HISTORY_PAGINATION_BTN_CLICK = "ET_ORDER_HISTORY_PAGINATION_BTN_CLICK";
export const ET_VALUATION_REPORT_FILTER_APPLIED = "ET_VALUATION_REPORT_FILTER_APPLIED";
export const ET_VALUATION_REPORT_FILTER_RESET = "ET_VALUATION_REPORT_FILTER_RESET";
export const ET_VALUATION_REPORT_FILTER_BTN_CLICK = "ET_VALUATION_REPORT_FILTER_BTN_CLICK";
export const ET_VALUATION_REPORT_FILTER_MODAL_OPEN = "ET_VALUATION_REPORT_FILTER_MODAL_OPEN";
export const ET_VALUATION_REPORT_FILTER_MODAL_CLOSE = "ET_VALUATION_REPORT_FILTER_MODAL_CLOSE";
export const ET_VALUATION_REPORT_PAGINATION_BTN_CLICK = "ET_VALUATION_REPORT_PAGINATION_BTN_CLICK";
export const ET_VALUATION_REPORT_PDF_DOWNLOAD_ICON_CLICK = "ET_VALUATION_REPORT_PDF_DOWNLOAD_ICON_CLICK";
export const ET_VALUATION_REPORT_PPT_DOWNLOAD_ICON_CLICK = "ET_VALUATION_REPORT_PPT_DOWNLOAD_ICON_CLICK";
export const ET_VALUATION_REPORTS_BUY_MORE_BTN_CLICK = "ET_VALUATION_REPORTS_BUY_MORE_BTN_CLICK";
export const ET_VALUATION_REPORT_CALCULATOR_ICON_CLICK = "ET_VALUATION_REPORT_CALCULATOR_ICON_CLICK";
export const ET_VALUATION_REPORT_PDF_ICON_CLICK = "ET_VALUATION_REPORT_PDF_ICON_CLICK"
export const VALUATION_CALC_DESCRIPTION_LEARN_MORE_LINK_CLICK = "VALUATION_CALC_DESCRIPTION_LEARN_MORE_LINK_CLICK";
export const ET_VALUATION_CALC_PROFESSIONAL_VALUATION_REPORT_CLICK = "ET_VALUATION_CALC_PROFESSIONAL_VALUATION_REPORT_CLICK";
export const ET_OPEN_BUSINESS_VALUATION_BTN_CLICK = "ET_OPEN_BUSINESS_VALUATION_BTN_CLICK";
export const ET_GET_PROFESSIONAL_BUSINESS_VALUATION_BTN_CLICK = "ET_GET_PROFESSIONAL_BUSINESS_VALUATION_BTN_CLICK";
export const ET_COMPANY_VALUATION_ESTIMATES_TEXT_CLICK = "ET_COMPANY_VALUATION_ESTIMATES_TEXT_CLICK"

//business plan
export const ET_BUSINESS_PLANS_FILTER_RESET = "ET_BUSINESS_PLANS_FILTER_RESET";
export const ET_BUSINESS_PLANS_FILTER_APPLIED = "ET_BUSINESS_PLANS_FILTER_APPLIED";
export const ET_BUSINESS_PLANS_OPEN_ICON_CLICK = "ET_BUSINESS_PLANS_OPEN_ICON_CLICK";
export const ET_BUSINESS_PLAN_MENU_CLICK = "ET_BUSINESS_PLAN_MENU_CLICK";
export const ET_BUSINESS_PLAN_ONLOAD = "ET_BUSINESS_PLAN_ONLOAD";
export const ET_BUSINESS_PLAN_PREVIOUS_BTN_CLICK = "ET_BUSINESS_PLAN_PREVIOUS_BTN_CLICK";
export const ET_BUSINESS_PLAN_NEXT_BTN_CLICK = "ET_BUSINESS_PLAN_NEXT_BTN_CLICK";
export const ET_BUSINESS_PLAN_EXIT_BTN_CLICK = "ET_BUSINESS_PLAN_EXIT_BTN_CLICK";
export const ET_BUSINESS_PLAN_PREVIEW_BTN_CLICK = "ET_BUSINESS_PLAN_PREVIEW_BTN_CLICK";
export const ET_BUSINESS_PLAN_REPORT_BTN_CLICK = "ET_BUSINESS_PLAN_REPORT_BTN_CLICK";
export const ET_BUSINESS_PLANS_BUY_MORE_BTN_CLICK = "ET_BUSINESS_PLANS_BUY_MORE_BTN_CLICK";
export const ET_BUSINESS_PLAN_PDF_DOWNLOAD_ICON_CLICK = "ET_BUSINESS_PLAN_PDF_DOWNLOAD_ICON_CLICK";
export const ET_BUSINESS_PLAN_PPT_DOWNLOAD_ICON_CLICK = "ET_BUSINESS_PLAN_PPT_DOWNLOAD_ICON_CLICK";
export const CREATE_STRIPE_SESSION_BUSINESS_PLAN_CREDITS = "CREATE_STRIPE_SESSION_BUSINESS_PLAN_CREDITS";
export const ET_BUSINESS_PLAN_SIGN_UP_BTN_CLICK = "ET_BUSINESS_PLAN_SIGN_UP_BTN_CLICK";
export const ET_BUSINESS_PLAN_USE_CREDIT_BTN_CLICK = "ET_BUSINESS_PLAN_USE_CREDIT_BTN_CLICK";
export const USER_REQUEST_SH01_DATA = 'USER_REQUEST_SH01_DATA'

//competitor analysis...
export const ET_CA_GET_STARTED_BTN_CLICK = "ET_CA_GET_STARTED_BTN_CLICK";
export const ET_CA_ADD_COMPETITOR_BTN_CLICK = "ET_CA_ADD_COMPETITOR_BTN_CLICK";
export const EP_COMPETITOR_ANALYSIS = "EP_COMPETITOR_ANALYSIS";
export const ET_CA_BACK_BTN_CLICK = "ET_CA_BACK_BTN_CLICK";
export const ET_CA_EXPORT_BTN_CLICK = "ET_CA_EXPORT_BTN_CLICK";
export const ET_CA_SIZE_HEADER_COLUMN_CLICK = "ET_CA_SIZE_HEADER_COLUMN_CLICK";
export const ET_CA_GROWTH_RATE_HEADER_COLUMN_CLICK = "ET_CA_GROWTH_RATE_HEADER_COLUMN_CLICK";
export const ET_CA_PRODUCTION_HEADER_COLUMN_CLICK = "ET_CA_PRODUCTION_HEADER_COLUMN_CLICK";
export const ET_CA_PROFILTABILITY_HEADER_COLUMN_CLICK = "ET_CA_PROFILTABILITY_HEADER_COLUMN_CLICK";
export const ET_CA_EMPLOYEES_HEADER_COLUMN_CLICK = "ET_CA_EMPLOYEES_HEADER_COLUMN_CLICK";
export const ET_CA_PAY_STRUCTURE_HEADER_COLUMN_CLICK = "ET_CA_PAY_STRUCTURE_HEADER_COLUMN_CLICK";
export const ET_CA_EFFICIENCY_HEADER_COLUMN_CLICK = "ET_CA_EFFICIENCY_HEADER_COLUMN_CLICK";
export const ET_CA_DEBTOR_DAYS_HEADER_COLUMN_CLICK = "ET_CA_DEBTOR_DAYS_HEADER_COLUMN_CLICK";
export const ET_CA_CREDITOR_DAYS_HEADER_COLUMN_CLICK = "ET_CA_CREDITOR_DAYS_HEADER_COLUMN_CLICK";
export const ET_CA_STOCK_DAYS_HEADER_COLUMN_CLICK = "ET_CA_STOCK_DAYS_HEADER_COLUMN_CLICK";
export const ET_CA_CASH_BALANCE_HEADER_COLUMN_CLICK = "ET_CA_CASH_BALANCE_HEADER_COLUMN_CLICK";
export const ET_CA_DEBT_LEVEL_HEADER_COLUMN_CLICK = "ET_CA_DEBT_LEVEL_HEADER_COLUMN_CLICK";
export const ET_COMPETITOR_ANALYSIS_ONLOAD = "ET_COMPETITOR_ANALYSIS_ONLOAD";
export const ET_CA_REMOVE_COMPETITOR_BTN_CLICK = "ET_CA_REMOVE_COMPETITOR_BTN_CLICK";
export const ET_CA_EDIT_COMPETITOR_INDUSTRY_BTN_CLICK = "ET_CA_EDIT_COMPETITOR_INDUSTRY_BTN_CLICK";
export const ET_CA_COMPETITOR_NAME_LINK_CLICK = "ET_CA_COMPETITOR_NAME_LINK_CLICK";
export const ET_CA_OPEN_REMOVE_COMPETITOR_CONFIRMATION_MODAL = "ET_CA_OPEN_REMOVE_COMPETITOR_CONFIRMATION_MODAL";
export const ET_CA_CLOSE_REMOVE_COMPETITOR_CONFIRMATION_MODAL = "ET_CA_CLOSE_REMOVE_COMPETITOR_CONFIRMATION_MODAL";
export const ET_CA_CLOSE_ADD_COMPETITOR_MODAL = "ET_CA_CLOSE_ADD_COMPETITOR_MODAL";
export const ET_CA_BACK_TO_ADD_COMPETITOR_MODAL_ICON_CLICK = "ET_CA_BACK_TO_ADD_COMPETITOR_MODAL_ICON_CLICK";
export const ET_CA_ADD_COMPETITOR_MODAL_COMPANY_NAME_CLICK = "ET_CA_ADD_COMPETITOR_MODAL_COMPANY_NAME_CLICK";
export const ET_CA_ADD_COMPETITOR_MODAL_CONTINUE_BTN_CLICK = "ET_CA_ADD_COMPETITOR_MODAL_CONTINUE_BTN_CLICK";
export const ET_CA_ADD_COMPETITOR_MODAL_ONLOAD = "ET_CA_ADD_COMPETITOR_MODAL_ONLOAD";
export const ET_HEALTH_CHECK_MODAL_ADD_COMPETITOR_BTN_CLICK = "ET_HEALTH_CHECK_MODAL_ADD_COMPETITOR_BTN_CLICK"
export const ET_ADD_COMPETITOR_TO_ANALYSIS_SEARCH = "ET_ADD_COMPETITOR_TO_ANALYSIS_SEARCH";
export const ET_SEARCH_COMPETITOR_ICON_CLICK = "ET_SEARCH_COMPETITOR_ICON_CLICK";
export const ET_SEARCH_COMPETITOR_ENTER_CLICK = "ET_SEARCH_COMPETITOR_ENTER_CLICK"
//SIC Code Page
export const EP_INDUSTRY_SIC_CODE = "EP_INDUSTRY_SIC_CODE";
export const ET_INDUSTRY_SIC_CODE_PAGINATION_CLICK = "ET_INDUSTRY_SIC_CODE_PAGINATION_CLICK";
export const ET_INDUSTRY_SIC_CODE_ONLOAD = "ET_INDUSTRY_SIC_CODE_ONLOAD";
export const ET_INDUSTRY_SIC_CODE_RESULT_CLICK = "ET_INDUSTRY_SIC_CODE_RESULT_CLICK";
export const EP_INDUSTRY_SIC_CODE_COMPANY_LIST = "EP_INDUSTRY_SIC_CODE_COMPANY_LIST";
export const ET_INDUSTRY_SIC_CODE_COMPANY_LIST_ONLOAD = "ET_INDUSTRY_SIC_CODE_COMPANY_LIST_ONLOAD";
export const ET_INDUSTRY_SIC_CODE_COMPANY_LIST_NUMBER_PAGINATION_CLICK = "ET_INDUSTRY_SIC_CODE_COMPANY_LIST_NUMBER_PAGINATION_CLICK";
export const ET_INDUSTRY_SIC_CODE_COMPANY_LIST_RESULT_CLICK = "ET_INDUSTRY_SIC_CODE_COMPANY_LIST_RESULT_CLICK"
export const ET_VALUATION_REPORT_LEARN_MORE_CLICK = "ET_VALUATION_REPORT_LEARN_MORE_CLICK";
//Free profiles visit expire eventTypes
export const ET_FREE_ACCESS_EXPIRE_MODAL_CLOSE_ICON_CLICK = "ET_FREE_ACCESS_EXPIRE_MODAL_CLOSE_ICON_CLICK";
export const ET_FREE_ACCESS_EXPIRE_MODAL_SIGN_IN_CLICK = "ET_FREE_ACCESS_EXPIRE_MODAL_SIGN_IN_CLICK";
export const ET_FREE_ACCESS_EXPIRE_MODAL_FREE_TRAIL_CLICK = "ET_FREE_ACCESS_EXPIRE_MODAL_FREE_TRAIL_CLICK";
export const ET_FREE_ACCESS_EXPIRE_ALERT_MODAL_ONLOAD = "ET_FREE_ACCESS_EXPIRE_ALERT_MODAL_ONLOAD"

export const EP_BUY_SELL_BUSINESS_PPC_HOME_PAGE = "EP_BUY_SELL_BUSINESS_PPC_HOME_PAGE";
export const ET_BUY_SELL_BUSINESS_PPC_HOME_PAGE_ONLOAD = "ET_BUY_SELL_BUSINESS_PPC_HOME_PAGE_ONLOAD";
export const ET_BUY_SELL_BUSINESS_PPC_HOME_PAGE_SAMPLE_REPORT_BTN_CLICK = "ET_BUY_SELL_BUSINESS_PPC_HOME_PAGE_SAMPLE_REPORT_BTN_CLICK";
export const BUY_SELL_BUSINESS_PPC_HOME_PAGE_SEARCHBAR_ENTER = "BUY_SELL_BUSINESS_PPC_HOME_PAGE_SEARCHBAR_ENTER";
export const BUY_SELL_BUSINESS_PPC_HOME_PAGE_SEARCHBAR_SEARCH = "BUY_SELL_BUSINESS_PPC_HOME_PAGE_SEARCHBAR_SEARCH";
export const BUY_SELL_BUSINESS_PPC_HOME_PAGE_SEARCHBAR_OPTION = "BUY_SELL_BUSINESS_PPC_HOME_PAGE_SEARCHBAR_OPTION";
export const BUY_SELL_BUSINESS_PPC_HOME_PAGE_SEARCHBAR_ICON = "BUY_SELL_BUSINESS_PPC_HOME_PAGE_SEARCHBAR_ICON";
export const BUY_SELL_BUSINESS_PPC_HOME_GET_IN_TOUCH_BTN_CLICK = "BUY_SELL_BUSINESS_PPC_HOME_GET_IN_TOUCH_BTN_CLICK";
export const BUY_SELL_BUSINESS_PPC_HOME_SUBMIT_CONTACT_US_BTN_CLICK = "BUY_SELL_BUSINESS_PPC_HOME_SUBMIT_CONTACT_US_BTN_CLICK";

export const EP_BUSINESS_PLAN_PPC_HOMEPAGE = "EP_BUSINESS_PLAN_PPC_HOMEPAGE"
export const ET_BUSINESS_PLAN_PPC_HOMEPAGE_ONLOAD = "ET_BUSINESS_PLAN_PPC_HOMEPAGE_ONLOAD"
export const ET_START_WITH_AI_BTN_CLICK = "ET_START_WITH_AI_BTN_CLICK";
export const ET_START_MY_AI_BUSINESS_PLAN_BTN_CLICK = "ET_START_MY_AI_BUSINESS_PLAN_BTN_CLICK"
export const ET_BUSINESS_PLAN_PPC_HOMEPAGE_SCROLL = "ET_BUSINESS_PLAN_PPC_HOMEPAGE_SCROLL";
export const EP_BUSINESS_PLAN_ONBOARD = "EP_BUSINESS_PLAN_ONBOARD";
export const EP_BUSINESS_PLAN_ONBOARD_PPC = "EP_BUSINESS_PLAN_ONBOARD_PPC";
export const ET_EXISTING_COMPANY_CARD_CLICK = "ET_EXISTING_COMPANY_CARD_CLICK";
export const ET_STILL_AN_IDEA_CARD_CLICK = "ET_STILL_AN_IDEA_CARD_CLICK";
export const ET_INSIDE_UK_CARD_CLICK = "ET_INSIDE_UK_CARD_CLICK";
export const ET_OUTSIDE_UK_CARD_CLICK = "ET_OUTSIDE_UK_CARD_CLICK";
export const ET_BUSINESS_PLAN_LOCATION_PAGE_ONLOAD = "ET_BUSINESS_PLAN_LOCATION_PAGE_ONLOAD";
export const ET_BUSINESS_PLAN_GET_STARTED_PAGE_ONLOAD = "ET_BUSINESS_PLAN_GET_STARTED_PAGE_ONLOAD";
export const ET_BUSINESS_PLAN_COMPANY_SEARCH_PAGE_ONLOAD = "ET_BUSINESS_PLAN_COMPANY_SEARCH_PAGE_ONLOAD";
export const ET_BP_INSIDE_UK_COMPANY_INFO_PAGE = "ET_BP_INSIDE_UK_COMPANY_INFO_PAGE";
export const ET_BP_COMPANY_DESCRIPTION_PAGE_ONLOAD = "ET_BP_COMPANY_DESCRIPTION_PAGE_ONLOAD";
export const ET_BP_KEY_PEOPLE_PAGE_ONLOAD = "ET_BP_KEY_PEOPLE_PAGE_ONLOAD";
export const ET_BP_EXISTING_OUTSIDE_UK_BASIC_COMPANY_INFO_PAGE = "ET_BP_EXISTING_OUTSIDE_UK_BASIC_COMPANY_INFO_PAGE";
export const ET_BP_NON_EXISTING_BASIC_COMPANY_INFO_PAGE = "ET_BP_NON_EXISTING_BASIC_COMPANY_INFO_PAGE";
export const ET_BP_COMPANY_SINGLE_SEARCH = "ET_BP_COMPANY_SINGLE_SEARCH";
export const ET_BP_COMPANY_SEARCHBAR_SEARCH = "ET_BP_COMPANY_SEARCHBAR_SEARCH";
export const ET_BP_COMPANY_SEARCHBAR_OPTION_CLICK = "ET_BP_COMPANY_SEARCHBAR_OPTION_CLICK"
export const ET_BUSINESS_PLAN_SAMPLE_CLICK = "ET_BUSINESS_PLAN_SAMPLE_CLICK"
export const ET_BUSINESS_PLAN_GREETING_BTN_CLICK = "ET_BUSINESS_PLAN_GREETING_BTN_CLICK"
export const ET_BUSINESS_PLAN_BUY_BTN_CLICK = "ET_BUSINESS_PLAN_BUY_BTN_CLICK"
export const ET_BUSINESS_PLAN_BUY_BACK_BTN_CLICK = "ET_BUSINESS_PLAN_BUY_BACK_BTN_CLICK"
export const ET_BUSINESS_PLAN_GOTOPLAN_BTN_CLICK = "ET_BUSINESS_PLAN_GOTOPLAN_BTN_CLICK"
export const ET_BUSINESS_PLAN_UNLOCK_BTN_CLICK = "ET_BUSINESS_PLAN_UNLOCK_BTN_CLICK"

export const ET_UPGRADE_INCREASE_CSV_DOWNLOAD_BUY_CLICK = "ET_UPGRADE_INCREASE_CSV_DOWNLOAD_BUY_CLICK";
export const ET_INCREASE_LIMIT_CONTACT_US_BTN_CLICK = "ET_INCREASE_LIMIT_CONTACT_US_BTN_CLICK";
export const ET_CLOSE_INCREASE_UPGRADE_MODAL_BTN_CLICK = "ET_CLOSE_INCREASE_UPGRADE_MODAL_BTN_CLICK";
export const ET_UPGRADE_CSV_DOWNLOADS_MODAL_OPEN = "ET_UPGRADE_CSV_DOWNLOADS_MODAL_OPEN";
export const ET_INCREASE_CSV_DOWNLOADS_MODAL_OPEN = "ET_INCREASE_CSV_DOWNLOADS_MODAL_OPEN"

export const EP_ABOUTPAGE = "EP_ABOUTPAGE";
export const ET_ABOUTPAGE_ONLOAD = "ET_ABOUTPAGE_ONLOAD";
export const ET_ABOUTPAGE_OUR_TEAM_EXPAND_CLICK = "ET_ABOUTPAGE_OUR_TEAM_EXPAND_CLICK";

export const EP_CONTACTPAGE = "EP_CONTACTPAGE"
export const ET_CONTACTPAGE_ONLOAD = "ET_CONTACTPAGE_ONLOAD"

// Swoop Landing Page
export const EP_SWOOP_LANDING_PAGE = "EP_SWOOP_LANDING_PAGE";
export const ET_STILL_AN_BUSINESS_IDEA_CARD_CLICK = "ET_STILL_AN_BUSINESS_IDEA_CARD_CLICK";
export const ET_EXISTING_BUSINESS_CARD_CLICK = "ET_EXISTING_BUSINESS_CARD_CLICK";
export const ET_START_BUSINESS_PLAN_BTN_CLICK = "ET_START_BUSINESS_PLAN_BTN_CLICK";
export const ET_SWOOP_PAGE_ONLOAD = "ET_SWOOP_PAGE_ONLOAD"

// Business Valuation Pricing Page
export const EP_BUSINESS_VALUATION_PRICE_PAGE = "EP_BUSINESS_VALUATION_PRICE_PAGE";
export const ET_HEADER_VALUATION_REPORT_BUY_BTN_CLICK = "ET_HEADER_VALUATION_REPORT_BUY_BTN_CLICK";
export const ET_ABOUT_VALUATION_SECTION_BUY_BTN_CLICK = "ET_ABOUT_VALUATION_SECTION_BUY_BTN_CLICK";
export const ET_BUSINESS_VALUATION_PRICE_PAGE_ONLOAD = "ET_BUSINESS_VALUATION_PRICE_PAGE_ONLOAD";
export const ET_BUSINESS_VALUATION_BACK_ICON_CLICK = "ET_BUSINESS_VALUATION_BACK_ICON_CLICK";
export const ET_BUSINESS_VAL_CARD_COMPANY_NAME_CLICK = "ET_BUSINESS_VAL_CARD_COMPANY_NAME_CLICK";
export const ET_TOGGLE_MONTHLY_YEARLY_BTN_CLICK = "ET_TOGGLE_MONTHLY_YEARLY_BTN_CLICK";
export const ET_COMPANY_REPORTS_EXPIRE_UPGRADE_TO_PRO = "ET_COMPANY_REPORTS_EXPIRE_UPGRADE_TO_PRO";
export const ET_COMPETITORS_LIMIT_REACHED_UPGRADE_TO_PRO = "ET_COMPETITORS_LIMIT_REACHED_UPGRADE_TO_PRO";
export const ET_COMPANY_REPORTS_EXPIRE_MODAL_CLOSE_BTN_CLICK = "ET_COMPANY_REPORTS_EXPIRE_MODAL_CLOSE_BTN_CLICK";
export const ET_COMPETITORS_LIMIT_REACHED_MODAL_CLOSE_BTN_CLICK = "ET_COMPETITORS_LIMIT_REACHED_MODAL_CLOSE_BTN_CLICK";
export const GENERATE_COMPANY_REPORT = "GENERATE_COMPANY_REPORT";
export const GET_COMPANY_REPORT_URL = "GET_COMPANY_REPORT_URL";
export const ET_ONE_DAY_PASS_UPGRADE_TO_PLUS = "ET_ONE_DAY_PASS_UPGRADE_TO_PLUS";
export const ET_ONE_DAY_PASS_TO_PLUS_UPGRADE_MODAL_CLOSE = "ET_ONE_DAY_PASS_TO_PLUS_UPGRADE_MODAL_CLOSE";
//help centre
export const EP_HELP_CENTRE_LANDING_PAGE = "EP_HELP_CENTRE_LANDING_PAGE"
export const ET_HELP_CENTRE_LANDING_PAGE_ONLOAD = "ET_HELP_CENTRE_LANDING_PAGE_ONLOAD"
export const EP_HELP_CENTRE_MENU_PAGE = "EP_HELP_CENTRE_MENU_PAGE"
export const ET_HELP_CENTRE_MENU_PAGE_ONLOAD = "ET_HELP_CENTRE_MENU_PAGE_ONLOAD"
export const EP_HELP_CENTRE_CONTENT_PAGE = "EP_HELP_CENTRE_CONTENT_PAGE"
export const ET_HELP_CENTRE_CONTENT_PAGE_ONLOAD = "ET_HELP_CENTRE_CONTENT_PAGE_ONLOAD"
export const ET_FEED_BACK_BTN_CLICK = "ET_FEED_BACK_BTN_CLICK"
export const ET_CONTENT_LINK_CLICK = "ET_CONTENT_LINK_CLICK"
export const ET_OK_BTN_CLICK = "ET_OK_BTN_CLICK"
export const ET_OPEN_FORM_MODAL = "ET_OPEN_FORM_MODAL"

// pomanda api service docs
export const EP_POMANDA_API_DOCS_PAGE = "EP_POMANDA_API_DOCS_PAGE";
export const ET_API_DOC_MENU_CLICK = "ET_API_DOC_MENU_CLICK";
export const ET_POMANDA_API_DOCS_PAGE_ONLOAD = "ET_POMANDA_API_DOCS_PAGE_ONLOAD"
// pomanda api service
export const EP_POMANDA_API_HOMEPAGE = "EP_POMANDA_API_HOMEPAGE";
export const ET_POMANDA_API_HOMEPAGE_ONLOAD = "ET_POMANDA_API_HOMEPAGE_ONLOAD";
export const ET_HEADER_EXPLORE_DOCS_BTN_CLICK = "ET_HEADER_EXPLORE_DOCS_BTN_CLICK";
export const ET_HEADER_ENQUIRE_NOW_BTN_CLICK = "ET_HEADER_ENQUIRE_NOW_BTN_CLICK";
export const ET_ENQUIRE_NOW_MODAL_HEADER_CLOSE_BTN_CLICK = "ET_ENQUIRE_NOW_MODAL_HEADER_CLOSE_BTN_CLICK";
export const ET_ENQUIRE_NOW_MODAL_FOOTER_CANCEL_BTN_CLICK = "ET_ENQUIRE_NOW_MODAL_FOOTER_CANCEL_BTN_CLICK";
export const ET_ENQUIRE_NOW_DETAILS_SEND_BTN_CLICK = "ET_ENQUIRE_NOW_DETAILS_SEND_BTN_CLICK";
export const ET_FOOTER_EXPLORE_NOW_BTN_CLICK = "ET_FOOTER_EXPLORE_NOW_BTN_CLICK";
export const ET_FOOTER_ENQUIRE_NOW_BTN_CLICK = "ET_FOOTER_ENQUIRE_NOW_BTN_CLICK"

//WatchLists
export const ET_WATCHLIST_BUTTON_CLICK = "ET_WATCHLIST_BUTTON_CLICK"
export const ET_WATCHLIST_SHOW_MODAL_OPEN = "ET_WATCHLIST_SHOW_MODAL_OPEN"
export const ET_WATCHLIST_SHOW_MODAL_CLOSE = "ET_WATCHLIST_SHOW_MODAL_CLOSE"
export const ET_WATCHLIST_SHOW_MODAL_EYE_ICON_CLICK = "ET_WATCHLIST_SHOW_MODAL_EYE_ICON_CLICK"
export const ET_WATCHLIST_SHOW_MODAL_EDIT_ICON_CLICK = "ET_WATCHLIST_SHOW_MODAL_EDIT_ICON_CLICK"
export const ET_WATCHLIST_SHOW_MODAL_DELETE_ICON_CLICK = "ET_WATCHLIST_SHOW_MODAL_DELETE_ICON_CLICK"
export const ET_SHOW_MODAL_CREATE_NEW_BTN_CLICK = "ET_SHOW_MODAL_CREATE_NEW_BTN_CLICK"

export const CREATE_WATCHLIST_MODAL_CANCEL_BTN_CLICK = "CREATE_WATCHLIST_MODAL_CANCEL_BTN_CLICK"
export const CREATE_WATCHLIST_MODAL_CLOSE_BTN_CLICK = "CREATE_WATCHLIST_MODAL_CLOSE_BTN_CLICK"
export const CREATE_WATCHLIST_MODAL_CREATE_BTN_CLICK = "CREATE_WATCHLIST_MODAL_CREATE_BTN_CLICK"
export const EDIT_WATCHLIST_MODAL_CANCEL_BTN_CLICK = "EDIT_WATCHLIST_MODAL_CANCEL_BTN_CLICK"
export const EDIT_WATCHLIST_MODAL_CLOSE_BTN_CLICK = "EDIT_WATCHLIST_MODAL_CLOSE_BTN_CLICK"
export const EDIT_WATCHLIST_MODAL_APPLY_BTN_CLICK = "EDIT_WATCHLIST_MODAL_APPLY_BTN_CLICK"
export const CONFIRMATION_MODAL_CLOSE_BTN_CLICK = "CONFIRMATION_MODAL_CLOSE_BTN_CLICK"
export const CONFIRMATION_MODAL_CANCEL_BTN_CLICK = "CONFIRMATION_MODAL_CANCEL_BTN_CLICK"
export const CONFIRMATION_MODAL_DELETE_BTN_CLICK = "CONFIRMATION_MODAL_DELETE_BTN_CLICK"

export const PS_HEADER_WATCHLIST_BTN_CLICK = "PS_HEADER_WATCHLIST_BTN_CLICK"
export const PS_HEADER_WATCHLIST_TAB_VIEW_BTN_CLICK = "PS_HEADER_WATCHLIST_TAB_VIEW_BTN_CLICK"
export const PS_HEADER_UPLOAD_CSV_BTN_CLICK = "PS_HEADER_UPLOAD_CSV_BTN_CLICK"

export const COMPANY_LIST_VIEW_HEADER_EYE_BTN_CLICK = "COMPANY_LIST_VIEW_HEADER_EYE_BTN_CLICK"
export const ADD_SINGLE_COMPANY_IN_WATCHLIST_BTN_CLICK = "ADD_SINGLE_COMPANY_IN_WATCHLIST_BTN_CLICK"
export const EDIT_SINGLE_COMPANY_IN_WATCHLIST_BTN_CLICK = "EDIT_SINGLE_COMPANY_IN_WATCHLIST_BTN_CLICK"

export const WATCHLIST_DEFAULT_MODAL_CREATE_WATCHLIST_BTN_CLICK = "WATCHLIST_DEFAULT_MODAL_CREATE_WATCHLIST_BTN_CLICK"
export const WATCHLIST_DEFAULT_MODAL_CLOSE_BTN_CLICK = "WATCHLIST_DEFAULT_MODAL_CLOSE_BTN_CLICK"
export const WATCHLIST_POMANDA_PRO_MODAL_CLOSE_BTN_CLICK = "WATCHLIST_POMANDA_PRO_MODAL_CLOSE_BTN_CLICK"
export const WATCHLIST_POMANDA_PRO_MODAL_UPGRADE_BTN_CLICK = "WATCHLIST_POMANDA_PRO_MODAL_UPGRADE_BTN_CLICK"
export const WATCHLIST_ADD_EDIT_MODAL_CLOSE_BTN_CLICK = "WATCHLIST_ADD_EDIT_MODAL_CLOSE_BTN_CLICK"
export const WATCHLIST_ADD_EDIT_MODAL_CREATE_NEW_BTN_CLICK = "WATCHLIST_ADD_EDIT_MODAL_CREATE_NEW_BTN_CLICK"
export const WATCHLIST_ADD_EDIT_MODAL_UPDATE_BTN_CLICK = "WATCHLIST_ADD_EDIT_MODAL_UPDATE_BTN_CLICK"
export const WATCHLIST_ADD_EDIT_MODAL_ADD_BTN_CLICK = "WATCHLIST_ADD_EDIT_MODAL_ADD_BTN_CLICK"
export const WATCHLIST_UPLOAD_CSV_MODAL_SELECT_FILE_BTN_CLICK = "WATCHLIST_UPLOAD_CSV_MODAL_SELECT_FILE_BTN_CLICK"
export const WATCHLIST_UPLOAD_CSV_MODAL_CREATE_WATCHLIST_BTN_CLICK = "WATCHLIST_UPLOAD_CSV_MODAL_CREATE_WATCHLIST_BTN_CLICK"
export const WATCHLIST_UPLOAD_CSV_MODAL_CANCEL_BTN_CLICK = "WATCHLIST_UPLOAD_CSV_MODAL_CANCEL_BTN_CLICK"
export const WATCHLIST_UPLOAD_CSV_MODAL_TEMPLATE_BTN_CLICK = "WATCHLIST_UPLOAD_CSV_MODAL_TEMPLATE_BTN_CLICK"
export const WATCHLIST_LEFT_SIDE_FILTER_EDIT_BTN_CLICK = "WATCHLIST_LEFT_SIDE_FILTER_EDIT_BTN_CLICK"
export const WATCHLIST_FILTER_TAB_VIEW_EDIT_BTN_CLICK = "WATCHLIST_FILTER_TAB_VIEW_EDIT_BTN_CLICK"
export const WATCHLIST_LEFT_SIDE_FILTER_DELETE_BTN_CLICK = "WATCHLIST_LEFT_SIDE_FILTER_DELETE_BTN_CLICK"
export const WATCHLIST_FILTER_TAB_VIEW_DELETE_BTN_CLICK = "WATCHLIST_FILTER_TAB_VIEW_DELETE_BTN_CLICK"

export const COMPANY_PROFILE_WATCHLIST_BUTTON_CLICK = "COMPANY_PROFILE_WATCHLIST_BUTTON_CLICK"
export const COMPANY_PROFILE_WATCHLIST_ACTIVE_BTN_CLICK_TAB_VIEW = "COMPANY_PROFILE_WATCHLIST_ACTIVE_BTN_CLICK_TAB_VIEW"
export const COMPANY_PROFILE_WATCHLIST_INACTIVE_BTN_CLICK_TAB_VIEW = "COMPANY_PROFILE_WATCHLIST_INACTIVE_BTN_CLICK_TAB_VIEW"

export const COMPANY_HOUSE_SH01_MODAL_OPEN = "COMPANY_HOUSE_SH01_MODAL_OPEN";
export const COMPANY_HOUSE_SH01_MODAL_PDF_CLICK = "COMPANY_HOUSE_SH01_MODAL_PDF_CLICK";
export const COMPANY_HOUSE_VIEW_TRANSCATIONS_BTN_CLICK = "COMPANY_HOUSE_VIEW_TRANSCATIONS_BTN_CLICK"