const { SR_ONE_TIME_PURCHASE_COMPANY_REPORT } = require("../../common/subscriptionConfigConst");

export const getProducts = (planDetailsValues) => {
    const {
        BusinessOneDay,
        oneTimePurchase = {},
        percent30OffReport,
        BusinessMonthly,
        BusinessYearly,
        SalesMonthly,
        SalesYearly,
        AdvisorsYearly,
        InvestorsYearly
    } = planDetailsValues;

    return {
        businessMonthly: {
            ...BusinessMonthly,
            btn: {
                text: "Purchase Now",
                disabled: null
            },
            textNextToPrice: () => 'Pomanda Plus',
        },
        businessYearly: {
            ...BusinessYearly,
            btn: {
                text: "Purchase Now",
                disabled: null
            },
            textNextToPrice: (annualValue) => `£ ${annualValue} per month Billed Annually`,
        },
        salesMonthly: {
            ...SalesMonthly,
            btn: {
                text: "Purchase Now",
                disabled: null,
            },
            textNextToPrice: (annualValue) => `(£ ${annualValue} Billed Annually)`,
        },
        salesYearly: {
            ...SalesYearly,
            btn: {
                text: "Purchase Now",
                disabled: null,
            },
            textNextToPrice: (annualValue) => `£ ${annualValue} per month Billed Annually`,
        },
        advisorsYearly: {
            ...AdvisorsYearly,
            btn: {
                text: "Purchase Now",
                disabled: null,
            },
            textNextToPrice: (annualValue) => ``,
        },
        investorsYearly: {
            ...InvestorsYearly,
            btn: {
                text: "Purchase Now",
                disabled: null,
            },
            textNextToPrice: (annualValue) => ``,
        },
        pomandaPlusFreeReport: {
            productId: "pomandaPlusFreeReport",
            productTitle: "Pomanda Plus",
            productName: "freeCR",
            freeCreditReport: {
                noOfReport: 1,
                period: "month"
            },
            subHeading: "Free Credit Report",
            smallDescription: "Get more out of Pomanda with unlimited company information",
            btn: {
                text: "claim now",
                type: "yellowBackground"
            }
        },
        oneTimePurchaseCreditReport: {
            ...oneTimePurchase,
            productName: "oneTimePurchase",
            getSmallDescription: (name) => `Purchase one time credit report for <span class='font-weight-bold'>${name}</span>`,
            btn: {
                text: "purchase",
                type: "yellowBackground"
            }
        },
        percent30OffReport: {
            ...percent30OffReport,
            getSmallDescription: (name) => `Purchase credit report for <span class='font-weight-bold'>${name}</span>`,
            btn: {
                text: "purchase",
                type: "yellowBackground"
            }
        },
        businessOneDay: {
            ...BusinessOneDay,
            btn: {
                text: "Purchase Now",
                disabled: null
            }
        },
        oneTimePurchaseCompanyReport: {
            productId: "oneTimePurchaseCompanyReport",
            productTitle: "Immediately available, up to date Credit Report",
            productName: "oneTimePurchase",
            price: {
                value: 4.99,
                fontSize: "big",
                period: "one_time"
            },
            serviceName: SR_ONE_TIME_PURCHASE_COMPANY_REPORT,
            smallDescription: "",
            btn: {
                text: "purchase",
                type: "yellowBackground"
            }
        },
        oneCredit: {
            productId: "oneCredit",
            productTitle: "Credit Reports",
            productName: "oneCredit",
            subHeading: "Use 1 Credit",
            smallDescription: "",
            btn: {
                text: "download",
                type: "yellowBackground"
            }
        },
        free: {
            productId: "free",
            productTitle: "Free",
            productName: "PomandaPlus",
            smallDescription: "Pomanda Basic Account",
            price: {
                value: 0,
                period: "monthly"
            },
            btn: {
                text: "Create Account",
                disabled: null
            },
            periodTextNextToPrice: 'monthly',
            featureHeading: "What you can do:",
            featurePoints: ["3 Company or Director Profile Views", "Healthcheck"],
            planComparisionPoints: {
                companyProfileViews: "3 Company Profile Views",
                directorProfileViews: "3 Director Profile Views",
                healthcheck: "yes",
                powerSearchForLeadGeneration: "no",
                exportLeads: "no",
                estimatedBusinessValuations: "no",
                competitorAnalysis: "no",
                companyReports: "no",
                monthlyFreeCreditReport: "no",
                saveCustomSearches: "no",
                apiIntegration: "no",
                whiteLabeling: "no",
                accountManager: "no"
            }
        },
        pomandaPlus5k: {
            productId: "salesYearly",
            productTitle: "Pomanda Plus 5k",
            productName: "Sales",
            purchaseMode: "subscription",
            price: {
                value: 100,
                period: "year"
            },
            btn: {
                text: "Upgrade Now",
                disabled: null,
            },
            periodTextNextToPrice: 'per month',
            textNextToPrice: (annualValue) => `(£ ${annualValue} Billed Annually)`,
        }
    }
}