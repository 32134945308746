import { call, put, takeLatest, all, select, delay } from "redux-saga/effects";
import { reset } from 'redux-form'
import {
    GET_COMPANY_PROFILE_REQUEST, GET_COMPANY_HOUSE_FILINGS_REQUEST,
    GET_COMPANY_HOUSE_DOCUMENT_REQUEST, GET_DIRECTOR_STATUS_LIST,
    GET_DIRECTOR_SHOW_MORE, GET_SHAREHOLDER_SHOW_MORE,
    GET_SHAREHOLDER_TYPE_LIST, GET_INDUSTRY_MULTIPLE_DATA,
    CALL_CREDIT_REPORT_API, CHECK_PDF_CREATION_STATUS,
    SUBMIT_INVESTMENT_INTEREST_REQUEST, SAVE_INDUSTRY_MIX_CHANGES,
    RESET_INDUSTRY_MIX_CHANGES,
    DOWNLOAD_FINANCIALS,
    GET_COMPANY_CHARGES_REQUEST,
    GET_COMPANY_VALUATION_REQUEST,
    SET_COMPANY_VALUATION_USER_EDIT,
    TOGGLE_BUY_CREDIT_REPORT_MODAL,
    GET_COMPANY_VALUATION_REPORT_REQUEST,
    UPDATE_VALUATION_REPORT_CREDIT_SUCCESS,
    GET_COMPANY_PSC_LIST_REQUEST,
    GET_COMPANY_HOUSE_SH01_DATA,
    GET_CONTACT_DETAILS_REQUEST,
    GET_CONTACT_DETAILS_CUSTOM_SEARCH_REQUEST,
    REVEAL_CONTACT_DETAILS_REQUEST,
    GET_REVEALED_CONTACT_DETAILS_REQUEST,
    GET_CONTACT_DETAILS_BULK_SEARCH_REQUEST,
    GET_CONTACT_DETAILS_BULK_STATUS_CHECK_REQUEST,
    GET_CONTACT_DETAILS_BULK_SEARCH_RESULT_DATA_REQUEST,
    DELETE_SPECIFIC_BULK_SEARCH_CONTACT_REQUEST,
    GET_CONTACT_DETAILS_BULK_REVEAL_REQUEST,
    GET_BULK_EXPORT_DOWNLOAD_CSV_REQUEST,
} from "../redux/actions/actionConstants";
import {
    getCompanyProfileFailed, getCompanyProfileSuccess,
    getCompanyHouseFilingsFailed, getCompanyHouseFilingsSuccess,
    getCompanyHouseDocumentFailed, getDirectorStatusListFailed,
    getDirectorStatusListSuccess, getDirectorShowMoreSuccess,
    getDirectorShowMoreFailed, getShareholderTypeListFailed,
    getShareholderTypeListSuccess, getShareholderShowMoreSuccess,
    getShareholderShowMoreFailed, getIndustryMultipleDataFailed,
    getIndustryMultipleDataSuccess, toggleCRDownloadLoadingModal,
    submitInvestmentInterestFailed, toggleSubmitInvestmentInterestModal,
    saveIndustryMixChangesFailed, setIndustryMixLoader,
    setIndustryMixUpdateModal, setIndustryMixUpdateSuccess,
    resetIndustryMixChangesFailed,
    getCompanyChargesSuccess,
    getCompanyValutionDataSuccess,
    getCompanyValutionDataFailed,
    getCompanyValutionUpdatedSuccess,
    resetIndustryMixChangesSuccess,
    setBuyCrModalData,
    updateValuationReportCreditSuccess,
    getCompanyValutionUpdatedFailed,
    resetIndustryMixUpdateSuccess,
    setDailyFreeExpireModalStatus,
    getCompanyPscListFailed,
    getCompanyPscListSuccess,
    getCompanyHouseSH01DataSuccess,
    getContactDetailsFailed,
    setContactDetailsSuccess,
    setContactDetailsCustomSearchSuccess,
    getContactDetailsCustomSearchFailed,
    getRevealedContactDetailsFailed,
    setRevealedContactDetailsSuccess,
    setContactDetailsLoading,
    setCustomSearchLoading,
    setContactDetailsBulkSuccess,
    getContactDetailsBulkFailed,
    setContactDetailsBulkStatusCheckSuccess,
    getContactDetailsBulkStatusCheckFailed,
    setContactDetailsBulkSearchResultDataSuccess,
    getContactDetailsBulkSearchResultDataFailed,
    setContactDetailsBulkRevealSuccess,
    getContactDetailsBulkRevealFailed,
    getBulkExportDownloadCsvFailed,
    getBulkExportDownloadCsvSuccess
} from "../redux/actions/companyAction";
import {
    getCompanyProfileApiCall, getCompanyHouseFilingsApiCall,
    getCompanyHouseDocumentApiCall, getDirectorStatusList,
    getDirectorShowMoreAPI, getShareholderTypeListApi,
    getShareholderShowMoreAPI, getIndustryMultipleApiCall,
    callCreditReportApi, checkPdfCreationApiCall,
    submitInterestApiCall, saveIndustryMixChangesAPI,
    resetIndustryMixChangesAPI,
    downloadFinancialsExcel,
    getSimilarCompaniesApiCall,
    getCompanyChargesApi,
    getCompanyValuationApi,
    setCompanyValuationDataApi,
    updateValuationReportCreditApi,
    getCompanyPscListApiCall,
    getCompanyHouseSH01ApiCall,
    getContactDetailsApiCall,
    revealContactDetailsApiCall,
    getRevealedContactDetailsApiCall,
    getContactDetailsBulkSearchApiCall,
    getContactDetailsBulkStatusCheckApiCall,
    getContactDetailsBulkSearchResultDataApiCall,
    deleteSpecificBulkSearchResultApiCall,
    getContactDetailsBulkRevealResultDataApiCall,
    getBulkExportDownloadCsvApiCall
} from '../api/companyApi';
import { checkFreeSearchAnonymousForProduct } from "../redux/actions/subscriptionAction";
import localStorageApi from '../api/localStorageApi';
import { setDailySearchInfo, getUserProfileSuccess, updateUserInfo, setContactCreditCounts } from "../redux/actions/userAccountAction";
import {
    SR_DAILY_SEARCH_LIMIT, DOWNLOAD_REPORT,
    DOWNLOAD_REPORT_READY_MSG, DOWNLOAD_REPORT_ERROR_MSG,
    DOWNLOAD_REPORT_ERROR,
    SR_CREDIT_REPORT_PKG_PURCHASE,
    SR_CREDIT_REPORT
} from '../common/subscriptionConfigConst'
import { toggleSubcriptionScreen } from '../redux/actions/subscriptionAction'
import {
    toggleCommonMsgModal, sendActivityTracking,
    setConfirmationModal,
    setEnquireModalToggleStatus,
    setLoading,
    // setChoosePackageModalData
} from "../redux/actions/commonAction";
import download from "downloadjs"
import successModalConstants, { NO_CONTACT_FOUND, NO_CUSTOM_RESULT_FOUND } from '../common/messageModalConstants';
import { PROSPER_USER_TYPE, isBrowser } from '../common/config'
import { ET_BUY_CREDIT_REPORT_MODAL_CLOSE, ET_BUY_CREDIT_REPORT_MODAL_OPEN, ET_CONTACT_FETCH_SUCCESS, ET_CONTACT_REVEAL_SUCCESS } from "../common/trackingConstants";
import { toggleSignupModal } from "../redux/actions/loginSignupAction";
import { TOGGLE_SIGNUP_MODAL_ON_SUCCESS } from "../common/loginSignupSuccessConstants";
// import { COMPANY_PROFILE, ET_CHOOSE_PACKAGE_MODAL_CLOSE, ET_CHOOSE_PACKAGE_MODAL_OPEN } from "../common/trackingConstants";
const downloadModalDataObjFrmRedux = (state) => state.subscription.downloadModalDataObj

function* getCompanyProfileSaga(action) {
    try {
        let { companyId, companyName } = action.payload;
        let token = isBrowser && localStorageApi.getUserAuthToken();
        if (!token) {
            const anonymousFreeSearchResp = isBrowser && (yield call(localStorageApi.checkFreeSearchForAnonymous, "company", companyId));
            if(anonymousFreeSearchResp.anonymousFreeSearchExpired) {
                yield all([
                    put(getCompanyProfileSuccess({
                        companyId
                    })),
                    put(checkFreeSearchAnonymousForProduct("company", companyId))
                ]);
                return;
            }
        }
        const response = yield call(getCompanyProfileApiCall, companyId, companyName);
        if(response?.status === 404) throw response;
        if (response.error) {
            if (response.error === 'API_USAGE_LIMIT_EXHAUSTED') {
                yield put(setDailyFreeExpireModalStatus(true));
                yield put(setDailySearchInfo(true))
                // don't remove choose package     
                // yield toggleChoosePackageModal(true, companyId);
            }
            yield put(getCompanyProfileFailed(response.message));
        } else {
            // if (!token || token === "" || token === null) {
            //     yield all([
            //         put(getCompanyProfileSuccess(response.companyProfile)),
            //         put(checkFreeSearchAnonymousForProduct("company", companyId))
            //     ]);
            // } else {
                if (response.isDailyFreeSearchExpired) {
                    yield put(setDailyFreeExpireModalStatus(true))
                    // don't remove choose package     
                    //     // yield toggleChoosePackageModal(true, companyId);
                    // } else {
                    // yield toggleChoosePackageModal(false, companyId);
                }
                yield all([
                    put(setDailySearchInfo(response.isDailyFreeSearchExpired)),
                    put(getCompanyProfileSuccess(response.companyProfile)),
                    put(getUserProfileSuccess(response?.userInfo?.userProfile, response?.userInfo?.isPomandaUser, 
                        response?.userInfo?.userOrderHistory,
                        response?.userInfo?.userCardDetails,
                        response?.userInfo?.valuationReports))
                ]);
                yield call(localStorageApi.updateFreeSearchCountObj, "company", companyId);

            // }
        }
    } catch (error) {
        console.log(error, 'error in calling company profile');
        if(error.status === 404){
            yield put(getCompanyProfileFailed(error.message));
        }
    }
}

function* getCompanyHouseFilingsSaga(action) {
    try {
        const response = yield call(getCompanyHouseFilingsApiCall, action.payload.companyObj);
        if (response.error) {
            yield put(getCompanyHouseFilingsFailed(response.message));
        } else {
            yield put(getCompanyHouseFilingsSuccess(response.data))
        }
    } catch (error) {
        console.log(error);
        yield put(getCompanyHouseFilingsFailed(error.message));
    }
}

function* getCompanyHouseDocumentSaga(action) {
    try {
        const response = yield call(getCompanyHouseDocumentApiCall, action.payload.docId);
        if (response.error) {
            yield put(getCompanyHouseDocumentFailed(response.message));
        } else {
            window.open(response.url, "_self");
        }
    } catch (error) {
        console.log(error);
        yield put(getCompanyHouseFilingsFailed(error.message));
    }
}

export function* watchGetCompanyProfileRequest() {
    yield takeLatest(GET_COMPANY_PROFILE_REQUEST, getCompanyProfileSaga);
}


export function* watchGetCompanyHouseFilingsRequest() {
    yield takeLatest(GET_COMPANY_HOUSE_FILINGS_REQUEST, getCompanyHouseFilingsSaga);
}

export function* watchGetCompanyHouseDocumentRequest() {
    yield takeLatest(GET_COMPANY_HOUSE_DOCUMENT_REQUEST, getCompanyHouseDocumentSaga);
}

function* getDirectorStatusListSaga(action) {
    try {
        const response = yield call(getDirectorStatusList, action.payload.companyId);
        if (response.error) {
            yield put(getDirectorStatusListFailed(response.message));
        } else {
            yield put(getDirectorStatusListSuccess(response.directorStatusList))
        }
    } catch (error) {
        console.log(error);
        yield put(getDirectorStatusListFailed(error.message));
    }
}
export function* watchGetDirectorStatusListRequest() {
    yield takeLatest(GET_DIRECTOR_STATUS_LIST, getDirectorStatusListSaga);
}

function* getDirectorShowMoreSaga(action) {
    try {
        const response = yield call(getDirectorShowMoreAPI, action.payload);
        if (response.error) {
            yield put(getDirectorShowMoreFailed(response.message));
        } else {
            yield put(getDirectorShowMoreSuccess(response))
        }
    } catch (error) {
        console.log(error);
        yield put(getDirectorShowMoreFailed(error.message));
    }
}
export function* watchGetDirectorShowMoreSaga() {
    yield takeLatest(GET_DIRECTOR_SHOW_MORE, getDirectorShowMoreSaga);
}


function* getShareholderTypeListSaga(action) {
    try {
        const response = yield call(getShareholderTypeListApi, action.payload.companyId);
        if (response.error) {
            yield put(getShareholderTypeListFailed(response.message));
        } else {
            yield put(getShareholderTypeListSuccess(response.shTypeList))
        }
    } catch (error) {
        console.log(error);
        yield put(getShareholderTypeListFailed(error.message));
    }
}
export function* watchGetShareholderTypeListSaga() {
    yield takeLatest(GET_SHAREHOLDER_TYPE_LIST, getShareholderTypeListSaga);
}

function* getShareholderShowMoreSaga(action) {
    try {
        const response = yield call(getShareholderShowMoreAPI, action.payload);
        if (response.error) {
            yield put(getShareholderShowMoreFailed(response.message));
        } else {
            yield put(getShareholderShowMoreSuccess(response))
        }
    } catch (error) {
        console.log(error);
        yield put(getShareholderShowMoreFailed(error.message));
    }
}
export function* watchGetShareholderShowMoreSaga() {
    yield takeLatest(GET_SHAREHOLDER_SHOW_MORE, getShareholderShowMoreSaga);
}

function* getIndustryMultipleSaga(action) {
    try {
        const response = yield call(getIndustryMultipleApiCall, action.payload);
        if (response.error) {
            yield put(getIndustryMultipleDataFailed(response.message));
        } else {
            yield put(getIndustryMultipleDataSuccess(response.industryMultiple));
        }
    } catch (error) {
        console.log(error);
        yield put(getIndustryMultipleDataFailed(error.message));
    }
}

export function* watchGetIndustryMultipleSaga() {
    yield takeLatest(GET_INDUSTRY_MULTIPLE_DATA, getIndustryMultipleSaga);
}
export function* callCreditReportApiSaga(action) {
    let resp = yield call(callCreditReportApi, action.payload.crObj)
    if (!resp.error) {
        yield all([
            put(getUserProfileSuccess(resp.userObj, resp.isPomandaUser, resp.userOrderHistory)),
            put(toggleSubcriptionScreen({
                subscriptionToggleStatus: false,
                serviceRequested: "",
            })),
            put(toggleCRDownloadLoadingModal({
                loaderImg: false,
                heading: DOWNLOAD_REPORT,
                subHeading: DOWNLOAD_REPORT_READY_MSG,
                toggleStatus: true,
                showContinueBtn: false,
                userCrDownloadMappingId: resp.userCrDownloadMappingId
            }))
        ]);
        // alert("Please check your email")
    } else {
        yield put(toggleCRDownloadLoadingModal({
            loaderImg: false,
            heading: DOWNLOAD_REPORT_ERROR,
            subHeading: DOWNLOAD_REPORT_ERROR_MSG,
            toggleStatus: true,
            showContinueBtn: false,
        }));
    }
}
export function* watchCallCreditReportSaga() {
    yield takeLatest(CALL_CREDIT_REPORT_API, callCreditReportApiSaga)
}

function* checkPdfCreationStatusSaga(action) {
    let i = 0;
    while (i <= 5) {
        let pdfCreationResp = yield call(checkPdfCreationApiCall, action.payload.cohId);
        if (pdfCreationResp.isPdfError) {
            yield put(toggleCRDownloadLoadingModal({
                loaderImg: false,
                heading: DOWNLOAD_REPORT_ERROR,
                subHeading: DOWNLOAD_REPORT_ERROR_MSG,
                toggleStatus: true,
                showContinueBtn: false
            }));
            break;
        }
        if (!pdfCreationResp.error && pdfCreationResp.isCrPdfGenerated) {
            let downloadModalDataObj = yield select(downloadModalDataObjFrmRedux);
            if (downloadModalDataObj.toggleStatus) {
                yield put(toggleCRDownloadLoadingModal({
                    loaderImg: false,
                    heading: "",
                    subHeading: "",
                    toggleStatus: false,
                    showContinueBtn: false
                }));
            }
            yield delay(1000);
            yield put(toggleCRDownloadLoadingModal({
                loaderImg: false,
                heading: DOWNLOAD_REPORT,
                subHeading: DOWNLOAD_REPORT_READY_MSG,
                toggleStatus: true,
                showContinueBtn: false,
                userCrDownloadMappingId: pdfCreationResp.userCrDownloadMappingId
            }));
            break;
        } else {
            yield delay(5000);
            i++;
        }
    }
}

export function* watchCheckPdfCreationStatus() {
    yield takeLatest(CHECK_PDF_CREATION_STATUS, checkPdfCreationStatusSaga);
}

function* submitInvestmentInterestRequestSaga(action) {
    try {
        yield put(sendActivityTracking(action.payload.trackingInfo));
        const apiResponse = yield call(submitInterestApiCall, action.payload.userContactDetails);
        let commonMsgModalInfoObj = {
            toggleStatus: true,
            heading: successModalConstants.CP_SI_SUCCESS.header,
            subHeading: successModalConstants.CP_SI_SUCCESS.subHeader,
            btnText: successModalConstants.CP_SI_SUCCESS.btnText,
            form: "submitInterestForm"
        };
        switch (action.payload.modalType) {
            case "enquire": 
                commonMsgModalInfoObj = {
                    image: {
                        source: "/assets/svgs/thumbs_up_icon.svg",
                        width: "39",
                        height: "40",
                        alt: "thumbs_up_icon"
                    },
                    toggleStatus: true,
                    heading: successModalConstants.ENQUIRE_NOW_SUCCESS_DATA.header,
                    subHeading: successModalConstants.ENQUIRE_NOW_SUCCESS_DATA.subHeading,
                    btnText: successModalConstants.ENQUIRE_NOW_SUCCESS_DATA.btnText,
                    form: "enquireForm",
                    modalClassName: "enquire-success-modal",
                    showCloseBtn: true
                };
                break;
            case "requestDemo": 
                commonMsgModalInfoObj = {
                    image: {
                        source: "/assets/svgs/thumbs_up_icon.svg",
                        width: "39",
                        height: "40",
                        alt: "thumbs_up_icon"
                    },
                    toggleStatus: true,
                    heading: successModalConstants.REQUEST_DEMO_SUCCESS.header,
                    subHeading: successModalConstants.REQUEST_DEMO_SUCCESS.subHeader,
                    btnText: successModalConstants.REQUEST_DEMO_SUCCESS.btnText,
                    modalClassName: "enquire-success-modal",
                    showCloseBtn: true
                };
                break;
            case "dataCleansingEnquire": 
                commonMsgModalInfoObj = {
                    image: {
                        source: "/assets/svgs/thumbs_up_icon.svg",
                        width: "39",
                        height: "40",
                        alt: "thumbs_up_icon"
                    },
                    toggleStatus: true,
                    heading: successModalConstants.DATA_CLEANSING_ENQUIRE_NOW_SUCCESS_DATA.header,
                    subHeading: successModalConstants.DATA_CLEANSING_ENQUIRE_NOW_SUCCESS_DATA.subHeading,
                    btnText: successModalConstants.DATA_CLEANSING_ENQUIRE_NOW_SUCCESS_DATA.btnText,
                    form: "enquireForm",
                    modalClassName: "enquire-success-modal",
                    showCloseBtn: true
                };
                break;
            default: 
                commonMsgModalInfoObj = commonMsgModalInfoObj
        }
        if (!apiResponse.error) {
            yield all([
                put(toggleSubmitInvestmentInterestModal({ toggleStatus: false })),
                put(setEnquireModalToggleStatus(false)),
                put(toggleCommonMsgModal(commonMsgModalInfoObj)),
                put(reset(commonMsgModalInfoObj.form))
            ])

        } else {
            yield put(submitInvestmentInterestFailed("Details could not be submitted. Please try again later."));
        }
    } catch (error) {
        console.error('inside submitInvestmentInterestRequestSaga', error);
        yield put(submitInvestmentInterestFailed("Details could not be submitted. Please try again later."))
    }
}

export function* watchSubmitInvestmentInterestRequest() {
    yield takeLatest(SUBMIT_INVESTMENT_INTEREST_REQUEST, submitInvestmentInterestRequestSaga);
}

function* saveIndustryMixChangesSaga(action) {
    try {
        const response = yield call(saveIndustryMixChangesAPI, action.payload.industryMixObj);

        if (!response.error) {
            const { companyId, name, industryMixArr, turnover, userId, type } = action.payload.industryMixObj;
            const companyProfileActionObj = {
                payload: {
                    companyId: companyId,
                    companyName: name,
                }
            }
            type === "valuation" ?
                yield put(getCompanyValutionDataSuccess(response.valuationCalData))
                : type === "competitorAnalysis" ? 
                    null : yield call(getCompanyProfileSaga, companyProfileActionObj)
                ;
            yield all([
                put(setIndustryMixLoader({ show: false, message: "" })),
                put(setIndustryMixUpdateModal(false)),
                put(setIndustryMixUpdateSuccess(true)),
            ]);
        } else {
            yield all([
                put(saveIndustryMixChangesFailed(response.message)),
                put(setIndustryMixLoader({ show: false, message: "" })),
                put(setIndustryMixUpdateModal(true)),
            ]);
        }
    } catch (error) {
        console.log(error);
        yield put(saveIndustryMixChangesFailed(error));
    }
}

export function* watchSaveIndustryMixChangesSaga() {
    yield takeLatest(SAVE_INDUSTRY_MIX_CHANGES, saveIndustryMixChangesSaga);
}

function* resetIndustryMixChangesSaga(action) {
    try {
        const response = yield call(resetIndustryMixChangesAPI, action.payload);
        if (!response.error) {
            const valuationObj = {
                payload: {
                    valuationParam: {
                        companyId: action.payload.companyId,
                        companyName: action.payload.name,
                        type: action.payload.type
                    }
                }
            }
            action.payload.type === "valuation" ?
                yield call(getCompanyValuationSaga, valuationObj)
                : action.payload.type === "competitorAnalysis" ? null : yield call(getCompanyProfileSaga, action);
            action.payload.resetType === "resetFinancialValuation" ?
                yield put(resetIndustryMixChangesSuccess({ status: true, message: response.message }))
                : "";
            yield put(setConfirmationModal(false));
            yield put(resetIndustryMixUpdateSuccess(true))
            yield put(setIndustryMixLoader({ show: false, message: "" }))
            yield put(setIndustryMixUpdateModal(false));
        } else {
            yield all([
                put(resetIndustryMixChangesFailed(response.message)),
                put(setIndustryMixUpdateModal(false)),
                put(setConfirmationModal(false)),
                put(setIndustryMixLoader({ show: false, message: "" }))
            ]);
        }
    } catch (error) {
        console.log(error);
        yield put(resetIndustryMixChangesFailed(error));
        yield put(setIndustryMixLoader({ show: false, message: "" }));
    }
}

export function* watchResetIndustryMixChangesSaga() {
    yield takeLatest(RESET_INDUSTRY_MIX_CHANGES, resetIndustryMixChangesSaga);
}

function* downloadFinancialsSaga(action) {
    try {
        yield call(downloadFinancialsExcel, action.payload.financials,
            action.payload.companyId, action.payload.companyName);
    } catch (error) {
        console.error('inside downloadFinancialsSaga', error);
    }
}
export function* watchDownloadFinancialsSaga() {
    yield takeLatest(DOWNLOAD_FINANCIALS, downloadFinancialsSaga);
}

// don't remove choose package     
// function* toggleChoosePackageModal(toggleStatus, companyId) {
//     yield all([
//         put(setChoosePackageModalData({
//             toggleStatus,
//             showHeading: true,
//             serviceReq: SR_DAILY_SEARCH_LIMIT,
//             eventPage: COMPANY_PROFILE,
//             showCloseBtn: false
//         })),
//         put(sendActivityTracking({
//             eventPage: COMPANY_PROFILE,
//             eventType: toggleStatus ? ET_CHOOSE_PACKAGE_MODAL_OPEN : ET_CHOOSE_PACKAGE_MODAL_CLOSE,
//             attribute1: companyId,
//             attribute2: SR_DAILY_SEARCH_LIMIT
//         }))
//     ])
// }

function* getCompanyChargesSaga(action) {
    try {
        const response = yield call(getCompanyChargesApi, action.payload.chargesParam);
        if (response.error) {
            // yield put(getDirectorStatusListFailed(response.message));
        } else {
            yield put(getCompanyChargesSuccess(response.companyCharges))
        }
    } catch (error) {
        console.log(error);
        yield put(getDirectorStatusListFailed(error.message));
    }
}

export function* watchGetCompanyChargesRequest() {
    yield takeLatest(GET_COMPANY_CHARGES_REQUEST, getCompanyChargesSaga);
}

function* getCompanyValuationSaga(action) {
    try {
        const response = yield call(getCompanyValuationApi, action.payload.valuationParam);
        const href = isBrowser && window.location.href
        let redirectPath = href.replace("/valuation","")
        if (!response.error) {
            if (response.companyValuationData) {
                yield put(getCompanyValutionDataSuccess(response.companyValuationData))
                yield put(setIndustryMixLoader({ show: false, message: "" }))
            } else{
                window.open(redirectPath,"_self")
                yield put(setIndustryMixLoader({ show: false, message: "" }))
            }
        }
    } catch (error) {
        window.open(redirectPath,"_self")
        yield put(setIndustryMixLoader({ show: false, message: "" }))
        yield put(getCompanyValutionDataFailed(error.message));
    }
}

export function* watchGetCompanyValuationRequest() {
    yield takeLatest(GET_COMPANY_VALUATION_REQUEST, getCompanyValuationSaga);
}

function* setUserEditedCompanyValuationSaga(action) {
    try {
        const response = yield call(setCompanyValuationDataApi, action.payload.userValuationParams);
        yield put(setIndustryMixLoader({ show: false, message: "" }))
        if (response.error) {
        } else {
            yield put(getCompanyValutionUpdatedSuccess(response.result))
        }
    } catch (error) {
        yield put(getCompanyValutionUpdatedFailed(error.message));
    }
}

export function* watchGetCompanyValuationUserRequest() {
    yield takeLatest(SET_COMPANY_VALUATION_USER_EDIT, setUserEditedCompanyValuationSaga);
}

function* toggleBuyCRModalSaga(action) {
    yield put(sendActivityTracking({
        eventPage: action.payload.eventPage,
        eventType: action.payload.toggleStatus ? ET_BUY_CREDIT_REPORT_MODAL_OPEN : ET_BUY_CREDIT_REPORT_MODAL_CLOSE,
        attribute1: action.payload.companyId
    }))
    if (action.payload.toggleStatus) {
        let token = isBrowser && (yield call(localStorageApi.getUserAuthToken));
        if (token && token !== "") {
            const userInfo = state => state.userProfile.userInfo;
            const userObj = yield select(userInfo);
            if (userObj.subscription && userObj.subscription.productName === "PomandaPlus"
                && userObj.subscription.active === "yes") {
                if (userObj.subscription.freeCR > 0) {
                    action.payload = {
                        ...action.payload,
                        ...yield* getObjForBuyCrModal("freeCR")
                    }
                } else if ((userObj?.subscription?.freeCR === "UN_LIMITED")) {
                    action.payload = {
                        ...action.payload,
                        ...yield* getObjForBuyCrModal("unlimitedCR")
                    }
                } else if (userObj.reportPkgSubscription && userObj.reportPkgSubscription.creditCount > 0) {
                    action.payload = {
                        ...action.payload,
                        ...yield* getObjForBuyCrModal("oneCredit")
                    }
                } else if (userObj.userType === PROSPER_USER_TYPE) {
                    action.payload = {
                        ...action.payload,
                        ...yield* getObjForBuyCrModal(PROSPER_USER_TYPE)
                    }
                } else {
                    action.payload = {
                        ...action.payload,
                        ...yield* getObjForBuyCrModal("percent30OffReport")
                    }
                }
            } else if (userObj.reportPkgSubscription && userObj.reportPkgSubscription.creditCount > 0) {
                action.payload = {
                    ...action.payload,
                    ...yield* getObjForBuyCrModal("oneCredit")
                }
            } else if (userObj.userType === PROSPER_USER_TYPE) {
                action.payload = {
                    ...action.payload,
                    ...yield* getObjForBuyCrModal(PROSPER_USER_TYPE)
                }
            } else {
                action.payload = {
                    ...action.payload,
                    ...yield* getObjForBuyCrModal("oneTimePurchase")
                }
            }
        } else {
            action.payload = {
                ...action.payload,
                ...yield* getObjForBuyCrModal("oneTimePurchase")
            }
        }
        yield put(setBuyCrModalData(action.payload));
    }

}

export function* watchToggleBuyCRModalSaga() {
    yield takeLatest(TOGGLE_BUY_CREDIT_REPORT_MODAL, toggleBuyCRModalSaga);
}

export function* getObjForBuyCrModal(productName) {
    switch (productName) {
        case "oneTimePurchase":
            return {
                btnText: "purchase single report £9.99",
                productName: "oneTimePurchase",
                productId: "oneTimePurchaseCreditReport",
                serviceRequested: SR_CREDIT_REPORT,
                toggleStatus: true,
                productPeriod: "one_time"
            }
        case "oneCredit":
            return {
                btnText: "purchase report (1 credit)",
                productName: "oneCredit",
                productId: "oneCredit",
                serviceRequested: SR_CREDIT_REPORT_PKG_PURCHASE,
                toggleStatus: true
            }
        case "freeCR":
            return {
                btnText: "purchase report (free)",
                productName: "freeCR",
                productId: "pomandaPlusFreeReport",
                serviceRequested: SR_CREDIT_REPORT,
                toggleStatus: true
            }
        case "unlimitedCR":
            return {
                btnText: "purchase report",
                productName: "unlimitedCR",
                productId: "unlimitedCR",
                serviceRequested: SR_CREDIT_REPORT,
                toggleStatus: true
            }
        case "percent30OffReport":
            return {
                btnText: "purchase single report £6.99",
                productName: "percent30OffReport",
                productId: "percent30OffReport",
                serviceRequested: SR_CREDIT_REPORT,
                toggleStatus: true,
                productPeriod: "one_time"
            }
        case PROSPER_USER_TYPE: 
            return {
                btnText: "Get Credits",
                productName: "prosperGetCredits",
                productId: "prosperGetCredits"
            }
        default:
            return {
                btnText: "purchase single report £9.99",
                productName: "oneTimePurchase",
                productId: "oneTimePurchaseCreditReport",
                serviceRequested: SR_CREDIT_REPORT,
                toggleStatus: true,
                productPeriod: "one_time"
            }
    }
}

function* getCompanyPscListSaga(action){
    try{
        const response = yield call(getCompanyPscListApiCall, action.payload.companyId);
        if(!response.error){
            yield put(getCompanyPscListSuccess(response.companyPscData))
        } else {
            yield put(getCompanyPscListFailed(response.message))
        }
    } catch(error) {
        yield put(getCompanyPscListFailed(error.message))
    }
}
export function* watchGetCompanyPscListSaga(){
    yield takeLatest(GET_COMPANY_PSC_LIST_REQUEST, getCompanyPscListSaga);
}

function* getCompanyHouseSH01Saga(action) {
    try {
        yield put(setLoading({ status: true, timeout: 100000 }))
        const response = yield call(getCompanyHouseSH01ApiCall, action.payload.companyId, action.payload.companyName);
        if (!response.error) {
            const commonMsgModalInfoObj = {
                toggleStatus: true,
                heading: "",
                subHeading: response.message,
                btnText: 'OK'
            };
            if (!response?.data) {
                yield !response?.data && put(toggleCommonMsgModal(commonMsgModalInfoObj))
            } else {
                yield put(getCompanyHouseSH01DataSuccess(response.data))
            }
        } else {
            yield put(toggleCommonMsgModal({
                toggleStatus: true,
                heading: "Oops!",
                subHeading: response?.message,
                btnText: 'OK'
            }))
        }
        yield put(setLoading({ status: false }))
    } catch (error) {
        yield put(toggleCommonMsgModal({
            toggleStatus: true,
            heading: "Oops!",
            subHeading: response?.message,
            btnText: 'OK'
        }))
        yield put(setLoading({ status: false }))
        console.log('CompanyHouseSH01Data request failed!');
    }
}

export function* watchGetCompanyHouseSH01Saga() {
    yield takeLatest(GET_COMPANY_HOUSE_SH01_DATA, getCompanyHouseSH01Saga)
}
function* getContactDetailsSaga(action) {
    try{
        const response = yield call(getContactDetailsApiCall, action.payload.contactDetailsParams);
        if(!response.error){
            const { directorsProfile = [], othersProfile = [], specificProfile = [] } = response.contactDetails
            const { companyName = '', eventPage = '' } = action.payload.contactDetailsParams
            if(specificProfile?.length) {
                put(setContactDetailsLoading({status: false}))
            }
            yield all([
                put(setContactDetailsLoading(false)),
                put(sendActivityTracking({
                    eventPage ,
                    eventType: ET_CONTACT_FETCH_SUCCESS,
                    attribute1: companyName,
                    attribute2: `No.of Directors - ${directorsProfile.length}`,
                    attribute3: `No.of Others - ${othersProfile.length}`,
                })),
                put(setContactDetailsSuccess(response.contactDetails))
            ]);
        } else {
            yield all([
                put(setContactDetailsLoading(false)),
                put(getContactDetailsFailed(response.message))
            ])
        }
    } catch(error) {
        yield all([
            put(setContactDetailsLoading(false)),
            put(getContactDetailsFailed(error.message))
        ])
    }
}

export function* watchGetContactDetailsSage() {
    yield takeLatest(GET_CONTACT_DETAILS_REQUEST, getContactDetailsSaga);
}

function* getContactDetailsBulkSearchSaga(action) {
    try{
        const response = yield call(getContactDetailsBulkSearchApiCall, action.payload.contactBulkSearchParams);
        if(!response.error){
            yield put(setContactDetailsBulkSuccess(response.bulkExportSearchedObj
            ))
        } else {
            yield put(getContactDetailsBulkFailed(response.message))
        }
    } catch(error) {
        yield put(getContactDetailsBulkFailed(error.message))
    }
}

export function* watchGetContactDetailsBulkSearchSaga() {
    yield takeLatest(GET_CONTACT_DETAILS_BULK_SEARCH_REQUEST, getContactDetailsBulkSearchSaga);
}

function* getContactDetailsBulkStatusCheckSaga(action) {
    try{
        const response = yield call(getContactDetailsBulkStatusCheckApiCall, action.payload.contactBulkStatusCheckParams);
        if(!response.error){
            yield put(setContactDetailsBulkStatusCheckSuccess(response.result))
            const currentContactCreditCount = yield select(state => state.userInfo?.contactDetailsCreditPkgSubscription?.contactCreditCount);
            if (response?.contactCreditCount !== undefined && response.contactCreditCount !== currentContactCreditCount) {
                yield put(setContactCreditCounts(response.contactCreditCount));
            }
        } else {
            yield put(getContactDetailsBulkStatusCheckFailed(response.error))
        }
    } catch(error) {
        yield put(getContactDetailsBulkFailed(error.message))
    }
}

export function* watchGetContactDetailsBulkStatusCheckSaga() {
    yield takeLatest(GET_CONTACT_DETAILS_BULK_STATUS_CHECK_REQUEST, getContactDetailsBulkStatusCheckSaga);
}

function* getContactDetailsBulkSearchResultDataSaga(action) {
    try{
        const response = yield call(getContactDetailsBulkSearchResultDataApiCall, action.payload.bulkSearchResultParams);
        if(!response.error){
            yield put(setContactDetailsBulkSearchResultDataSuccess(response.contactDetails))
        } else {
            yield put(getContactDetailsBulkSearchResultDataFailed(response.message))
        }
    } catch(error) {
        yield put(getContactDetailsBulkSearchResultDataFailed(error.message))
    }
}

export function* watchGetContactDetailsBulkSearchResultDataSaga() {
    yield takeLatest(GET_CONTACT_DETAILS_BULK_SEARCH_RESULT_DATA_REQUEST, getContactDetailsBulkSearchResultDataSaga);
}

function* getContactDetailsBulkRevealResultDataSaga(action) {
    try{
        const response = yield call(getContactDetailsBulkRevealResultDataApiCall, action.payload.contactBulkRevealParams);
        if(!response.error){
            yield put(setContactDetailsBulkRevealSuccess(response))
        } else {
            yield put(getContactDetailsBulkRevealFailed(response.message))
        }
    } catch(error) {
        yield put(getContactDetailsBulkRevealFailed(error.message))
    }
}

export function* watchGetContactDetailsBulkRevealResultDataSaga() {
    yield takeLatest(GET_CONTACT_DETAILS_BULK_REVEAL_REQUEST, getContactDetailsBulkRevealResultDataSaga);
}

function* deleteSpecificBulkSearchResultSaga(action) {
    try{
        const response = yield call(deleteSpecificBulkSearchResultApiCall, action.payload.bulkSearchResultParams);
        if(!response.error){
            yield put(setContactDetailsBulkSearchResultDataSuccess(response.contactDetails))
        } else {
            yield put(getContactDetailsBulkSearchResultDataFailed(response.error))
        }
    } catch(error) {
        yield put(getContactDetailsBulkSearchResultDataFailed(error.message))
    }
}

export function* watchDeleteSpecificBulkSearchResultSaga() {
    yield takeLatest(DELETE_SPECIFIC_BULK_SEARCH_CONTACT_REQUEST, deleteSpecificBulkSearchResultSaga);
}

function* getContactDetailsCustomSearchSage(action) {
    try{
        const response = yield call(getContactDetailsApiCall, action.payload.customSearchParams);
        if(!response.error){
            yield all([
                put(setContactDetailsCustomSearchSuccess(response.contactDetails)),
                put(setCustomSearchLoading(false))
            ])
            
        } else {
            yield all([
                put(getContactDetailsCustomSearchFailed(response.message)),
                put(setCustomSearchLoading(false))
            ])
        }
    } catch(error) {
        yield put(getContactDetailsCustomSearchFailed(error.message))
    }
}

export function* watchGetContactDetailsCustomSearchSage() {
    yield takeLatest(GET_CONTACT_DETAILS_CUSTOM_SEARCH_REQUEST, getContactDetailsCustomSearchSage);
}
function* revealContactDetailsSaga(action) {
    try{
        const response = yield call(revealContactDetailsApiCall, action.payload.obj);
        if(!response.error){
            const { revealedDetails = {}, userObj = {} } = response
            const { id } = revealedDetails
            yield all([
                put(setRevealedContactDetailsSuccess(revealedDetails)),
                put(updateUserInfo(userObj)),
                put(sendActivityTracking({
                    eventPage: action.payload.obj.eventPage,
                    eventType: ET_CONTACT_REVEAL_SUCCESS,
                    attribute1: userObj._id,
                    attribute2: revealedDetails.name,
                    attribute3: revealedDetails.company,
                    attribute4: revealedDetails.profileType,
                })),
                put(setContactDetailsLoading({[id]: false}))
            ]);
        } else {
            yield put(getRevealedContactDetailsFailed(error.message))
        }
    } catch(error) {
        yield put(setContactDetailsLoading({}))
        yield put(getContactDetailsFailed(error.message))
    }
}

export function* watchRevealContactDetailsSaga() {
    yield takeLatest(REVEAL_CONTACT_DETAILS_REQUEST, revealContactDetailsSaga);
}

function* getRevealedContactDetailsSaga(action) {
    try{
        const response = yield call(getRevealedContactDetailsApiCall, action.payload.obj);
        if(!response.error){
            yield put(setRevealedContactDetailsSuccess(response.revealedDetails))
        } 
    } catch(error) {
        yield put(getRevealedContactDetailsFailed(error.message))
    }
}

export function* watchRevealedContactDetailsSaga() {
    yield takeLatest(GET_REVEALED_CONTACT_DETAILS_REQUEST, getRevealedContactDetailsSaga);
}

function* getBulkExportDownloadCsvSaga(action) {
    try{
        const response = yield call(getBulkExportDownloadCsvApiCall, action.payload.exportObj);
        if(!response.error){
            yield call(download, response.data, "BulkExportData.csv", "text/csv");
            yield put(getBulkExportDownloadCsvSuccess(response.revealedDetails))
        } 
    } catch(error) {
        yield put(getBulkExportDownloadCsvFailed(error.message))
    }
}

export function* watchBulkExportDownloadCsvSaga() {
    yield takeLatest(GET_BULK_EXPORT_DOWNLOAD_CSV_REQUEST, getBulkExportDownloadCsvSaga);
}

